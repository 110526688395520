import React, { useState, useMemo } from 'react';
import { Dimensions, View, StyleSheet, Text } from 'react-native';
import { Input, Button } from 'react-native-elements';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_VERIFICATION_TASK } from '../../lib/mutations';
import { OpportunityStackParams } from '../../types';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import { UploadResponse } from '../media/UploadAssetScreen';
import { MediaTypeValue } from 'expo-media-library';
import { ITaskVerification } from '../OpportunityDetail';
import { MiniGallery } from '../../components/Media/MiniGallery';
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store/types';
import { LocationObject } from 'expo-location';
import MapView, { Marker } from 'react-native-maps';

const styles = StyleSheet.create({
    digitalBody: {
        flex: 1,
        padding: 20,
    },
    mainButton: {
        marginTop: 10,
    },
    helperText: {
        fontSize: 14,
        fontFamily: 'Lato',
        marginBottom: 10,
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        fontFamily: 'Lato',
    },
    updateText: {
        marginLeft: 10,
        marginTop: 5,
        fontFamily: 'Lato',
        fontSize: 12,
        color: '#5e5e5e',
    },
    imageHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 8,
        height: 48,
        padding: 8,
    },
    selectActions: {
        display: 'flex',
        flexDirection: 'row',
    },
    linkContainer: {
        marginTop: 20,
    },
    map: {
        width: Dimensions.get('window').width - 50,
        height: Dimensions.get('window').width,
        marginBottom:24,
    },
    textContainer: {},
});

export interface DigitalVerifyProps {
    opportunityId: string;
    taskVerification: ITaskVerification;
    navigation: StackNavigationProp<OpportunityStackParams, 'ManualDigital'>;
    uploadResponse?: UploadResponse;
    triggerRefetch?: any;
}
const GeoComponent = (props: {taskVerification: ITaskVerification}) => {
    const { taskVerification} = props;
    const { task } = taskVerification;
    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);

    if (task.geo) {
        let initialRegion = {
            latitude: 47.6,
            longitude: -122.3,
            latitudeDelta: 0.1,
            longitudeDelta: 0.1,
        };
        if (loc) {
            initialRegion = {
                latitude: loc.coords.latitude,
                longitude: loc.coords.longitude,
                latitudeDelta: 0.1,
                longitudeDelta: 0.1,
            };
        }
        return (<MapView style={styles.map} initialRegion={initialRegion}>
                    <Marker coordinate={initialRegion} />
                </MapView>);
    }else {
        return null;
    }
}

const DigitalVerify = (props: DigitalVerifyProps) => {
    const { taskVerification, uploadResponse, triggerRefetch } = props;
    const { task } = taskVerification;
    const navigation = props.navigation;
    let cameraPermsEnabled = false;
    let cameraPermsExist = false;
    if (uploadResponse) {
        cameraPermsExist = true;
        cameraPermsEnabled = uploadResponse.hasCameraPermission && uploadResponse.hasCameraRollPermission;
    }
    const [link, setLink] = useState(taskVerification.link);
    const [text, setText] = useState(taskVerification.text);

    enum TaskState {
        Accepted,
        Rejected,
        Waiting,
        Submitted,
        Started,
    }

    const curTaskState = useMemo(() => {
        if (taskVerification.approved) {
            return TaskState.Accepted;
        } else if (taskVerification.rejected) {
            return TaskState.Rejected;
        } else if (taskVerification.submitted) {
            return TaskState.Submitted;
        } else if (taskVerification.started) {
            return TaskState.Started;
        } else {
            return TaskState.Waiting;
        }
    }, [taskVerification]);

    const [updateVerification, { loading: updateLoading, error: updateError, data: updateData }] =
        useMutation(UPDATE_VERIFICATION_TASK);

    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);

    const can_upload = useMemo(() => {
        if (taskVerification.rejected) {
            return true;
        }
        if (taskVerification.approved) {
            return false;
        }

        return !taskVerification.started;
    }, [taskVerification]);

    const _updateLink = (text: any) => {
        setLink(text);
    };
    const _updateText = (text: any) => {
        setText(text);
    };

    const submitVerificationUpdate = async () => {
        return updateVerification({
            variables: {
                taskVerificationId: taskVerification.id,
                link,
                text,
                latitude: !!loc?.coords ? loc.coords.latitude : 1.0,
                longitude: !!loc?.coords ? loc.coords.longitude : 1.0,
            },
        })
            .then(() => {
                triggerRefetch();
            })
            .catch((e) => console.trace(e));
    };

    const openUpload = () => {
        const mediaType: MediaTypeValue[] = task.video ? ['photo', 'video'] : ['photo'];
        navigation.navigate('Upload', {
            mediaType,
            backScreenName: 'ManualDigital',
            mutation: {
                kind: 'AssetMultipleMutation',
                //when images is true allow to add multiple assets
                max: task.images ? null : 1,
                name: 'UPDATE_VERIFICATION_TASK',
                field: 'assets',
                variables: {
                    link,
                    text,
                    latitude: !!loc?.coords ? loc.coords.latitude : 1.0,
                    longitude: !!loc?.coords ? loc.coords.longitude : 1.0,
                    taskVerificationId: taskVerification.id,
                },
                bucket: 'TaskVerification',
            },
        });
    };

    const showImageList = taskVerification.assets && taskVerification.assets.length > 0;
    const askForImages = (task.image || task.images) && (!cameraPermsExist || (cameraPermsExist && cameraPermsEnabled));

    if (updateError) {
        return (
            <View style={styles.digitalBody}>
                <Text style={styles.errorText}>{updateError ? updateError.message : null}</Text>
            </View>
        );
    }

    if (!cameraPermsEnabled && cameraPermsExist) {
        return (
            
            <View style={styles.digitalBody}>
                <Text>Please allow permissions to upload photos in order to verify tasks.</Text>
                <Button style={styles.mainButton} title={'Continue'} onPress={openUpload} />
            </View>
        );
    }

    return (
        <View style={styles.digitalBody}>
            {askForImages && !showImageList && (
                <>
                    <Text style={styles.helperText}>
                        Choose image below to provide a screenshot or photo proof
                    </Text>
                    <Button title={'Choose Image'} onPress={openUpload} disabled={!can_upload} />
                </>
            )}
            {showImageList && (<>
            <Text style={styles.helperText}>Click over the image to set a caption</Text>
            <AwesomeButtonThemed
                type="primary"
                height={30}
                style={{ marginRight: 8, marginTop: 16, alignSelf: 'flex-end' }}
                progress={false}
                disabled={!can_upload}
                onPress={() => {
                    const mediaType: MediaTypeValue[] = task.video ? ['photo', 'video'] : ['photo'];
                    navigation.navigate('Gallery', {
                        editable: can_upload,
                        mediaType: mediaType,
                        query: {
                            name: 'GET_TASK_VERIFICATION',
                            variables: { taskVerificationId: taskVerification.id },
                            subPath: ['taskVerification', 'assets'],
                        },
                        backScreenName: 'ManualDigital',
                        mutation: {
                            kind: 'AssetMultipleMutation',
                            name: 'UPDATE_VERIFICATION_TASK',
                            variables: {
                                taskVerificationId: taskVerification.id,
                                link,
                                text,
                                latitude: !!loc?.coords ? loc.coords.latitude : 1.0,
                                longitude: !!loc?.coords ? loc.coords.longitude : 1.0,
                            },
                            field: 'assets',
                            max: task.images ? undefined : 1,
                            bucket: 'TaskVerification',
                        },
                    });
                }}
            >{`Gallery (${taskVerification.assets.length})`}</AwesomeButtonThemed>
            <MiniGallery
                assets={taskVerification.assets}
                editable={true}
                onSelect={(item, index) =>
                    navigation.navigate('AssetPager', {
                        initialPage: index,
                        editable: can_upload,
                        query: {
                            name: 'GET_TASK_VERIFICATION',
                            subPath: ['taskVerification', 'assets'],
                            variables: { taskVerificationId: taskVerification.id },
                        },
                    })
                }
            />
            </>)}
            {task.link && (
                <View style={styles.linkContainer}>
                    <Input
                        placeholder={'Enter Link'}
                        value={link}
                        multiline
                        numberOfLines={4}
                        onChangeText={(text) => _updateLink(text)}
                        disabled={!can_upload}
                    />
                </View>
            )}
            <View style={styles.textContainer}>
                <Input
                    placeholder={'Comment (Optional)'}
                    value={text}
                    onChangeText={(text) => _updateText(text)}
                    disabled={!can_upload}
                />
            </View>
            <GeoComponent taskVerification={taskVerification}/>
            {can_upload && (
                <View>
                    <Button title={'Save'} onPress={submitVerificationUpdate} disabled={!can_upload} />
                </View>
            )}
        </View>
    );
};

export { DigitalVerify };
