import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          TabFeed: {
            screens: {
              TabFeedScreen: "Feed",
            },
          },
          TabRewards: {
            screens: {
              TabRewardsScreen: "Wallet",
            },
          },
        },
      },
      NotFound: "*",
    },
  },
};
