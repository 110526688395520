import React from 'react';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { Divider, Button } from 'react-native-elements';
import { StackScreenProps } from '@react-navigation/stack';
import { Text, View } from '../../components/Themed';
import { SettingsStackParams } from '../../types';
import i18n from '../../config/i18n';

const pkg = require('../../package.json');
type InfoScreenRouteProp = StackScreenProps<SettingsStackParams, 'Settings'>;
const Info = ({ navigation }: InfoScreenRouteProp) => {
    const styles = StyleSheet.create({
        header: {
            marginTop: -18,
            textAlign: 'center',
            color: '#5e5e5e',
            fontFamily: 'Lato-Heavy',
            fontSize: 18,
            marginBottom: 10,
        },
        version: {
            fontFamily: 'Lato',
            fontSize: 18,
            marginTop: 10,
        },
        content: {
            minHeight: '100%',
            padding: 10,
        },
    });

    return (
        <View style={styles.content}>
            <Text style={styles.version}>{i18n.t('nutriSourceAppFor')}{Platform.OS}</Text>
            <Text style={styles.version}>{i18n.t('version')}  {pkg.version}</Text>
            <Text style={styles.version}>{i18n.t('copyright2021')}GoVi</Text>
            <Divider style={{ marginTop: 20, backgroundColor: 'grey' }} />
            <Button title={`${i18n.t('viewTermsOfService')}`} onPress={() => navigation.navigate('TOS')} />
            <Divider style={{ marginTop: 20, backgroundColor: 'grey' }} />
            <Button title={`${i18n.t('viewPrivacyPolicy')}`} onPress={() => navigation.navigate('PrivacyPolicy')} />
        </View>
    );
};

export { Info };
