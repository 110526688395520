import { AnyAction } from 'redux';
import {
    SET_ERROR,
    CLEAR_ERROR,
    SET_TOKEN,
    CLEAR_TOKEN,
    SET_CONFIRM_LOGIN_LOADING,
    SET_LOGIN_LOADING,
    SET_LOGIN_STATUS,
    SET_REGISTER_LOADING,
    SET_REGISTRATION_COMPLETE,
    SET_PENDING_CONFIRM,
    Consumer,
    UNCONFIRMED,
    SET_FIREBASE_CONTEXT,
    SET_LOCATION,
    Shop, ErrorCode,
} from './types';


export function ConsumerReducer(
    state: Consumer = {
        user: null,
        error: '',
        error_code: ErrorCode.None,
        token: null,
        firebaseContext: null,
        loginLoading: false,
        loginStatus: UNCONFIRMED,
        confirmLoginLoading: false,
        registerLoading: false,
        registrationComplete: false,
        pendingConfirm: false,
        location: null,
    },
    action: AnyAction
): Consumer {
    switch (action.type) {
        case SET_ERROR:
            if(typeof action.payload === "string"){
                return {
                    ...state,
                    error: action.payload,
                    error_code: ErrorCode.Unknown,
                };
            }else{
                return {
                    ...state,
                    error: action.payload.message,
                    error_code: action.payload.code,
                };
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: '',
                error_code: ErrorCode.None,
            };
        case SET_CONFIRM_LOGIN_LOADING:
            return {
                ...state,
                confirmLoginLoading: action.payload,
            };
        case SET_LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.payload,
            };
        case SET_FIREBASE_CONTEXT:
            return {
                ...state,
                firebaseContext: action.payload,
            };
        case SET_LOGIN_STATUS:
            return {
                ...state,
                loginStatus: action.payload,
            };
        case SET_REGISTER_LOADING:
            return {
                ...state,
                registerLoading: action.payload,
            };
        case SET_REGISTRATION_COMPLETE:
            return {
                ...state,
                registrationComplete: action.payload,
            };
        case SET_PENDING_CONFIRM:
            return {
                ...state,
                pendingConfirm: action.payload,
            };
        case SET_TOKEN:
            return {
                ...state,
                registerLoading: false,
                registrationComplete: true,
                token: action.payload,
            };
        case CLEAR_TOKEN:
            return {
                ...state,
                token: null,
            };
        case SET_LOCATION:
            return {
                ...state,
                location: action.payload,
            };
        default:
            return state;
    }
}

//todo: define shop actions
export function ShopReducer(
    state: Shop = {
        id: '',
        name: '',
    },
    action: AnyAction
): Shop {
    switch (action.type) {
        default:
            return state;
    }
}
