import { Image, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Avatar } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import moment from 'moment';
import React from 'react';
import { Post, Tier } from '../../../store/types';

const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

    },
    left: {
        display: 'flex',
        flexDirection: 'column',
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
});

function tierColor(tier: Tier): string {
    switch (tier) {
        case 'Gold':
            return '#C98910';
        case 'Bronze':
            return '#965A38';
        case 'Silver':
            return '#A8A8A8';
        case 'Platinum':
            return '#E5E4E2';
        default:
            return '#000';
    }

}

export function PostHeader(props: { post: Post, containerStyle?: StyleProp<ViewStyle> }) {
    const { post, containerStyle } = props;
    return (
        <View style={[styles.root, containerStyle]}>
            <View style={styles.left}>
                <Text style={{ fontWeight: 'bold', color: 'grey' }}>{post.poster}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar containerStyle={{ marginTop: 4, marginBottom: 4 }} size={'small'} rounded
                            source={{ uri: post.type == 'BusinessPost' ? post.businessImage : post.profileImage }}/>
                    <View style={{ display: 'flex', flexDirection: 'row', marginLeft: 8}}>
                        { post.type == 'BusinessPost' ? <></> : <Ionicons color={tierColor(post.userTier)} name={'star'}
                                  size={16}/>}<Text>{' '}</Text><Text>{post.userRank}</Text></View>
                </View>
            </View>
            <View style={styles.right}>
                <Text style={{ color: 'gray' }}>{moment(post.createdAt).fromNow()}</Text>
            </View>
        </View>
    );
}
