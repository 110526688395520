import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	Image,
	Linking,
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	View,
} from 'react-native';
import { Button } from 'react-native-elements';
import { useQuery, useMutation } from '@apollo/client';
import { USER_PROFILE } from '../lib/backend/settings/profile/queries';
import * as ImagePicker from 'expo-image-picker';
import * as IntentLauncher from 'expo-intent-launcher';
import Constants from 'expo-constants';
import { MaterialIcons } from '@expo/vector-icons';
import { UPDATE_USER_PROFILE } from '../lib/backend/settings/profile/mutation';
import i18n from '../config/i18n';

const DEFAULT_PROFILE_PICTURE = require('../assets/images/user.png');

function hasUpdates(obj: { [key: string]: boolean }) {
	let hu = false;
	for (let key of Object.keys(obj)) {
		hu = hu || obj[key];
	}
	return hu;
}

export default function Profile() {
	const { loading, error, data: backendData } = useQuery(USER_PROFILE);

	const [updateProfile, { loading: updateProfileLoading, error: updateProfileError }] = useMutation(
		UPDATE_USER_PROFILE
	);
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phone, setPhone] = useState('');
	const [profilePicture, setProfilePicture] = useState('');
	const [password, setPassword] = useState('');
	const [updates, setUpdates] = useState<{ [key: string]: boolean }>({});
	const pkg = Constants.manifest.releaseChannel ? Constants.manifest.android.package : 'host.exp.exponent';
	useEffect(() => {
		if (backendData && backendData.user) {
			setEmail(backendData.user.email);
			setFirstName(backendData.user.firstName);
			setMiddleName(backendData.user.middleName);
			setLastName(backendData.user.lastName);
			setPhone(backendData.user.phone);
			setProfilePicture(backendData.user.profilePicture);
		}
	}, [backendData]);

	//detect if there is a change in the form
	useEffect(() => {
		if (backendData && backendData.user) {
			if (email && email.trim() != '' && email.trim() != backendData.user.email) {
				setUpdates((ob) => ({ ...ob, email: true }));
			} else {
				setUpdates((ob) => ({ ...ob, email: false }));
			}
			if (firstName && firstName.trim() != '' && firstName.trim() != backendData.user.firstName) {
				setUpdates((ob) => ({ ...ob, firstName: true }));
			} else {
				setUpdates((ob) => ({ ...ob, firstName: false }));
			}
			if (middleName && middleName.trim() != '' && middleName.trim() != backendData.user.middleName) {
				setUpdates((ob) => ({ ...ob, middleName: true }));
			} else {
				setUpdates((ob) => ({ ...ob, middleName: false }));
			}
			if (lastName && lastName.trim() != '' && lastName.trim() != backendData.user.lastName) {
				setUpdates((ob) => ({ ...ob, lastName: true }));
			} else {
				setUpdates((ob) => ({ ...ob, lastName: false }));
			}
			if (phone && phone.trim() != '' && phone.trim() != backendData.user.phone) {
				setUpdates((ob) => ({ ...ob, phone: true }));
			} else {
				setUpdates((ob) => ({ ...ob, phone: false }));
			}
			if (
				profilePicture &&
				profilePicture.trim() != '' &&
				profilePicture.trim() != backendData.user.profilePicture
			) {
				setUpdates((ob) => ({ ...ob, profilePicture: true }));
			} else {
				setUpdates((ob) => ({ ...ob, profilePicture: false }));
			}
			if (password && password.trim() != '') {
				setUpdates((ob) => ({ ...ob, password: true }));
			} else {
				setUpdates((ob) => ({ ...ob, password: false }));
			}
		}
	}, [email, firstName, middleName, lastName, phone, profilePicture, password, backendData]);

	//ask for image picker permissions

	const pickGalleryImage = async () => {
		let pick = true;
		if (Platform.OS !== 'web') {
			const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
			//const { status } = await ImagePicker.requestCameraPermissionsAsync();
			if (status !== 'granted') {
				pick = false;
				Alert.alert(
					'Permissions Error',
					'Access to your media library enables you to upload pictures and earn awesome rewards for doing so.',
					[
						{ text: 'Cancel', style: 'cancel' },
						{
							text: 'Settings',
							onPress: () => {
								// ios
								if (Platform.OS === 'ios') {
									return Linking.openURL('app-settings:');
								} else {
									// android
									return IntentLauncher.startActivityAsync(
										IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
										{ data: 'package:' + pkg }
									);
								}
							},
						},
					]
				);
			}
		}
		if (pick) {
			try {
				let result = await ImagePicker.launchImageLibraryAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.All,
					allowsEditing: true,
					aspect: [4, 3],
					quality: 1,
					base64: true,
				});
				if (!result.cancelled) {
					if (result.uri) {
						setProfilePicture(result.uri);
					}
				}
			} catch (e) {
				console.error(e);
			}
		}
	};

	const pickCameraImage = async () => {
		let pick = true;
		if (Platform.OS !== 'web') {
			const { status } = await ImagePicker.requestCameraPermissionsAsync();
			if (status !== 'granted') {
				pick = false;
				Alert.alert(
					'Permissions Error',
					'Access to your camera and microphone enables you to upload pictures and earn awesome rewards for doing so.',
					[
						{ text: 'Cancel', style: 'cancel' },
						{
							text: 'Settings',
							onPress: () => {
								// ios
								if (Platform.OS === 'ios') {
									return Linking.openURL('app-settings:');
								} else {
									// android
									return IntentLauncher.startActivityAsync(
										IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
										{ data: 'package:' + pkg }
									);
								}
							},
						},
					]
				);
			}
		}
		if (pick) {
			let result = await ImagePicker.launchCameraAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsEditing: true,
				aspect: [4, 3],
				quality: 1,
				base64: true,
			});
			if (!result.cancelled) {
				if (result.uri) {
					setProfilePicture(result.uri);
				}
			}
		}
	};

	const makeUpdate = () => {
		let updateObject: { [key: string]: string } = {};
		if (updates['email']) {
			updateObject['email'] = email;
		}
		if (updates['firstName']) {
			updateObject['firstName'] = firstName;
		}

		if (updates['middleName']) {
			updateObject['middleName'] = middleName;
		}
		if (updates['lastName']) {
			updateObject['lastName'] = lastName;
		}
		if (updates['phone']) {
			updateObject['phone'] = phone;
		}
		if (updates['profilePicture']) {
			updateObject['profilePicture'] = profilePicture;
		}
		return updateObject;
	};

	const editable: boolean = backendData != null && backendData.user != null && !updateProfileLoading;
	if (updateProfileError) {
		// TODO: make the container DRY
		<SafeAreaView style={styles.container}>
			<View style={styles.subContainer}>
				<Text style={styles.error}>{i18n.t('anErrorOccuredWhileUpdatingYourProfile')}</Text>
			</View>
		</SafeAreaView>;
	}

	if (error) {
		<SafeAreaView style={styles.container}>
			<View style={styles.subContainer}>
				<Text style={styles.error}>{i18n.t('anErrorOccuredWhileLoadingProfile')}</Text>
			</View>
		</SafeAreaView>;
	}

	if (loading) {
		return (
			<View style={styles.subContainer}>
				<ActivityIndicator size={'large'} />
			</View>
		);
	}

	return (
		<SafeAreaView style={styles.container}>
			<ScrollView style={styles.scrollView} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ padding: 20, marginBottom: 20, flex: 3, height: 300 }}>
					<Image
						style={{ width: '100%', height: '100%', borderWidth: 1, borderColor: 'black' }}
						source={profilePicture ? { uri: profilePicture } : DEFAULT_PROFILE_PICTURE}
					></Image>
				</View>
				<View style={styles.profilePhotoActionsContainer}>
					<MaterialIcons.Button
						name="photo-camera"
						size={24}
						color="white"
						onPress={async () => pickCameraImage()}
						backgroundColor="black"
					>
						{i18n.t('camera')}
					</MaterialIcons.Button>
					<View style={{ width: 20 }} />
					<MaterialIcons.Button
						name="insert-photo"
						onPress={async () => pickGalleryImage()}
						size={24}
						color="white"
						backgroundColor="black"
					>
						{i18n.t('gallery')}
					</MaterialIcons.Button>
				</View>

				<TextInputWithLabel
					label={'Email'}
					editable={editable}
					value={email || ''}
					onChangeText={(txt: string) => setEmail(txt)}
					textContentType={'emailAddress'}
					autoCompleteType={'email'}
				/>
				<TextInputWithLabel
					value={firstName || ''}
					editable={editable}
					label={'First Name'}
					onChangeText={(txt: string) => setFirstName(txt)}
					textContentType={'name'}
				/>
				<TextInputWithLabel
					value={middleName || ''}
					editable={editable}
					label={'Middle Name'}
					onChangeText={(txt: string) => setMiddleName(txt)}
					textContentType={'middleName'}
				/>
				<TextInputWithLabel
					value={lastName || ''}
					editable={editable}
					label={'Last Name'}
					onChangeText={(txt: string) => setLastName(txt)}
					textContentType={'name'}
				/>
				<TextInputWithLabel
					value={phone || ''}
					editable={editable}
					label={'Phone'}
					onChangeText={(txt: string) => setPhone(txt)}
					textContentType={'telephoneNumber'}
				/>
				{/* <TextInputWithLabel value={password || ""}
                                        editable={editable}
                                        label={"Password"}
                                        onChangeText={(txt: string) => setPassword(txt)}
                                        secureTextEntry={true}/>*/}
			</ScrollView>
			{hasUpdates(updates) ? (
				<View>
					{updateProfileLoading ? (
						<View
							style={{
								height: '100%',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<ActivityIndicator size={'large'} />
						</View>
					) : (
						<Button
							buttonStyle={styles.saveBtn}
							title="Save"
							onPress={async () => {
								await updateProfile({ variables: makeUpdate() });
								setPassword('');
							}}
						/>
					)}
				</View>
			) : null}
		</SafeAreaView>
	);
}

const TextInputWithLabel = (props: { [key: string]: any }) => {
	let label = props.label;
	let style = props.style;

	let newProps = Object.assign({}, props, { label: undefined, style: undefined });
	return (
		<View style={{ flexDirection: 'column', padding: 20 }}>
			<Text>{label}</Text>
			<TextInput {...newProps} style={[styles.textInput, style]} />
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
		flex: 1,
	},
	error: {
		fontFamily: 'Lato-Medium',
		fontSize: 14,
		color: 'red',
	},
	subContainer: {
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	scrollView: {},
	profilePhotoActionsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		paddingLeft: 20,
		paddingRight: 20,
	},
	preview: {
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	textInput: { height: 40, borderColor: 'gray', borderWidth: 1, padding: 8 },
	saveBtn: {
		backgroundColor: '#0d72b9',
	},
});
