import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName, Alert, Linking } from 'react-native';
import NotFoundScreen from '../screens/NotFoundScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import { Login } from '../screens/Login';
import { SignUp } from '../screens/SignUp';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore, SET_LOCATION } from '../store/types';
import * as Location from 'expo-location';
import * as IntentLauncher from 'expo-intent-launcher';
import Constants from 'expo-constants';
import { setToken } from '../store/api';
import { OnboardingScreen } from '../screens/Onboarding';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
    const navigationRef = React.useRef(null);
    const dispatch = useDispatch();
    const [firstTime, setFirstTime] = React.useState(true);
    const pkg = Constants.manifest.releaseChannel ? Constants.manifest.android.package : 'host.exp.exponent';

    let token = useSelector<RootStore>((state) => state.consumer.token);

    const getLocation = () => {
        Location.watchPositionAsync(
            {
                accuracy: Location.LocationAccuracy.High,
                timeInterval: 1000 * 60,
                //distanceInterval:,
            },
            (location) => {
                dispatch({
                    type: SET_LOCATION,
                    payload: location,
                });
            }
        );
    };

    const _requestPerms = async () => {
        const { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            Alert.alert('Location Reminder', 'Please consider enabling location to find reward near you', [
                {
                    text: 'OK',
                    onPress: () => {
                        // ios
                        if (Platform.OS === 'ios') {
                            return Linking.openURL('app-settings:');
                        } else {
                            // android
                            return IntentLauncher.startActivityAsync(
                                IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
                                { data: 'package:' + pkg }
                            );
                        }
                    },
                },
            ]);
            setFirstTime(false);
            return;
        }

        getLocation();
        setFirstTime(false);
    };

    React.useEffect(() => {
        (async () => {
            token = await AsyncStorage.getItem('@token');
            if (!token) {
                return;
            }
            // @ts-ignore Fix this string thing
            dispatch(setToken(token));
            if (!firstTime) {
                return;
            }
            let { status: existingStatus } = await Location.getPermissionsAsync();

            if (existingStatus !== 'granted') {
                Alert.alert(
                    'Share Your Location',
                    'Your location is required to provide you with nearby locations at which you can earn and spend rewards. The app will not function as intended without access to your location.',
                    [
                        {
                            text: 'Cancel',
                            onPress: () => {
                                setFirstTime(false);
                            },
                        },
                        { text: 'OK', onPress: () => _requestPerms() },
                    ]
                );
                return;
            } else {
                getLocation(); // Keep on trucking
            }
        })();
    }, [token]);

    return (
        <NavigationContainer
            ref={navigationRef}
            linking={LinkingConfiguration}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <RootNavigator token={token} />
        </NavigationContainer>
    );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator({ token }: any) {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            {!token ? (
                <>
                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="SignUp" component={SignUp} />
                </>
            ) : (
                <>
                    <Stack.Screen name="Onboarding" component={OnboardingScreen} />
                    <Stack.Screen name="BottomTabs" component={BottomTabNavigator} />
                    <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
                </>
            )}
        </Stack.Navigator>
    );
}
