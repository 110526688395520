import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { ActivityIndicator, FlatList, Pressable, RefreshControl, StyleSheet } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';
import { IPlace } from '../store/types';
import { RootStore } from '../store/types';
import { StackNavigationProp } from '@react-navigation/stack';
import Error from '../components/Error';
import { Text, TextInput, View } from '../components/Themed';
import { LocationObject } from 'expo-location';
import * as Queries from '../lib/queries';
import { get } from 'lodash';
import { Empty } from '../components/Empty';
import i18n from '../config/i18n';
import {
    CURRENT_SHOP_ID, // @ts-ignore
} from '@env';

interface Query {
    name: string;
    variables: any;
    subPath: string[];
}

type ShopListProps = {
    navigation: StackNavigationProp<any>;
    query: Query;
    subtitle?: string;
};

/**
 *
 * @param d - distance in meters
 */
export function distanceFormat(d: number): string {
    if (d / 1609.344 > 1) {
        return `${(d / 1609.344).toFixed(1)} mi`;
    }
    if (d / 0.9144 > 1) {
        return `${(d / 0.9144).toFixed(1)} yd`;
    }

    return `${(d / 0.3048).toFixed(1)} ft`;
}

const ShopList = (props: ShopListProps) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [refreshingPull, setRefreshingPull] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    // @ts-ignore
    const { data, loading, refetch, error, fetchMore } = useQuery(Queries[props.query.name], {
        notifyOnNetworkStatusChange: true,
        variables: { ...props.query.variables, first: 10 },
    });

    const places: IPlace[] = useMemo(() => {
        let r = get(data, props.query.subPath);
        if (Array.isArray(r)) {
            if (searchTerm) {
                return r.filter((p: IPlace) => p.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
            }
            return r;
        } else {
            return [];
        }
    }, [data, props.query.variables, searchTerm]);

    const onRefresh = async () => {
        setRefreshingPull(true);
        await refetch();
        setRefreshingPull(false);
    };
    if (loading && firstLoad) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    }
    if (error || !data || !get(data, props.query.subPath)) {
        return <Error />;
    }

    if (get(data, props.query.subPath).length == 0) {
        return <Empty message={'No Locations'} />;
    }

    function renderItem(el: { item: IPlace }) {
        return (
            <Pressable
                key={el.item.id}
                onPress={() => props.navigation.navigate('ShopDetail', { shop: el.item })}
            >
                <View style={{ paddingHorizontal: 30 }}>
                    <Text style={{ fontWeight: 'bold', paddingVertical: 5 }}>{el.item.name}</Text>
                    <Text style={{ paddingVertical: 5 }}>
                        {el.item.street1} {el.item.street2}
                    </Text>
                    <Text style={{ paddingVertical: 5 }}>
                        {el.item.city}, {el.item.state} {el.item.postalCode}
                    </Text>
                    {el.item.distance > 0 && (
                        <Text style={{ paddingVertical: 5 }}>{distanceFormat(el.item.distance)}</Text>
                    )}
                    <View
                        style={{
                            borderBottomColor: 'grey',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            paddingTop: 10,
                        }}
                    />
                </View>
            </Pressable>
        );
    }

    return (
        <FlatList
            refreshControl={<RefreshControl refreshing={refreshingPull} onRefresh={onRefresh} />}
            data={places}
            renderItem={renderItem}
            extraData={loading}
            removeClippedSubviews={false}
            onEndReachedThreshold={0.1}
            onEndReached={async () => {
                if (places.length > 0) {
                    // The fetchMore method is used to load new data and add it
                    // to the original query we used to populate the list
                    if (fetchMore) {
                        if (firstLoad) setFirstLoad(false);
                        try {
                            await fetchMore({
                                variables: {
                                    first: 10,
                                    after: places[places.length - 1].id,
                                },
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }}
            ListHeaderComponent={
                props.subtitle ? (
                    <View style={{ paddingVertical: 8, paddingHorizontal: 16, backgroundColor: 'white' }}>
                        <Text style={{ alignSelf: 'center', fontSize: 16, color: 'rgb(32, 137, 220)' }}>
                            {props.subtitle}
                        </Text>
                    </View>
                ) : (
                    <TextInput
                        style={{ padding: 10 }}
                        onChangeText={setSearchTerm}
                        value={searchTerm}
                        placeholder={i18n.t('searchPlaceholder')}
                    />
                )
            }
            stickyHeaderIndices={props.subtitle ? [0] : []}
            ListFooterComponent={loading ? <ActivityIndicator /> : <View style={{ height: 20 }} />}
            keyExtractor={(item: IPlace) => item.id}
        />
    );
};

export interface ShopsScreenParams {
    mainQuery?: Query;
    favoriteQuery?: Query;
    subtitle?: string;
}

interface ShopsScreenProps {
    route: { params: ShopsScreenParams };
    navigation: StackNavigationProp<any, any>;
}

const ShopsScreen = (props: ShopsScreenProps) => {
    const { navigation } = props;
    const [index, setIndex] = useState(0);
    const [routes] = useState([
        { key: 'main', title: 'All Shops' },
        { key: 'favorite', title: 'Favorites' },
    ]);
    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);
    //@ts-ignore
    const { mainQuery, favoriteQuery } = !props.route.params ? {} : props.route.params;

    if (!mainQuery) {
        let renderScene = SceneMap({
            main: () => (
                <ShopList
                    navigation={navigation}
                    query={{
                        name: 'PLACES',
                        variables: {
                            shop_id: CURRENT_SHOP_ID,
                            location: {
                                lat: loc ? loc.coords.latitude : 31.51073,
                                long: loc ? loc.coords.longitude : -96.4247,
                            },
                        },
                        subPath: ['places'],
                    }}
                />
            ),
            favorite: () => (
                <ShopList
                    navigation={navigation}
                    query={{
                        name: 'FAVORITE_PLACES',
                        variables: {
                            location: {
                                lat: loc ? loc.coords.latitude : 31.51073,
                                long: loc ? loc.coords.longitude : -96.4247,
                            },
                        },
                        subPath: ['favoritePlaces'],
                    }}
                />
            ),
        });
        return (
            <TabView navigationState={{ index, routes }} renderScene={renderScene} onIndexChange={setIndex} />
        );
    } else {
        if (!favoriteQuery) {
            return (
                <ShopList subtitle={props.route.params.subtitle} navigation={navigation} query={mainQuery} />
            );
        } else if (favoriteQuery) {
            let renderScene = SceneMap({
                main: () => (
                    <ShopList
                        subtitle={props.route.params.subtitle}
                        navigation={navigation}
                        query={mainQuery}
                    />
                ),
                favorite: () => (
                    <ShopList
                        subtitle={props.route.params.subtitle}
                        navigation={navigation}
                        query={favoriteQuery}
                    />
                ),
            });
            return (
                <TabView
                    navigationState={{ index, routes }}
                    renderScene={renderScene}
                    onIndexChange={setIndex}
                />
            );
        } else if (favoriteQuery) {
            let renderScene = SceneMap({
                main: () => (
                    <ShopList
                        subtitle={props.route.params.subtitle}
                        navigation={navigation}
                        query={mainQuery}
                    />
                ),
                favorite: () => (
                    <ShopList
                        subtitle={props.route.params.subtitle}
                        navigation={navigation}
                        query={favoriteQuery}
                    />
                ),
            });
            return (
                <TabView
                    navigationState={{ index, routes }}
                    renderScene={renderScene}
                    onIndexChange={setIndex}
                />
            );
        }
    }
    return null;
};

const styles = StyleSheet.create({
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export { ShopsScreen };
