import React from 'react';
import { StyleSheet, ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { HistoryCard } from '../../components/history/HistoryCard';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsStackParams } from '../../types';
import { useQuery } from '@apollo/client';
import { IReward, IWallet } from '../../store/types';
import { GET_WALLET } from '../../lib/queries';
import i18n from '../../config/i18n';

const rewardTypes: ('coupons' | 'vouchers')[] = ['coupons', 'vouchers'];

const styles = StyleSheet.create({
    root: {
        padding: 12,
        minHeight: '100%',
    },
    posts: {
        marginTop: 12,
    },
});

const History = (props: any) => {
    const { data, loading, error, refetch } = useQuery<{ wallet: IWallet }, null>(GET_WALLET, {
        pollInterval: 1000,
    });
    if (error) {
        return (
            <>
                <Text>Error loading transaction history</Text>
            </>
        );
    }
    if (loading) {
        return (
            <>
                <Text>loading</Text>
            </>
        );
    }

    if (data) {
        const { wallet } = data;
        return (
            <View style={styles.root}>
                <ScrollView style={styles.posts}>
                    {rewardTypes.map((rewardType) => {
                        // @ts-ignore
                        const rewards: IReward[] = wallet[rewardType];
                        return (
                            <React.Fragment key={rewardType}>
                                <View>
                                    {rewards
                                        .filter((reward) => reward.redeemedAt)
                                        .map((reward) => (
                                            <HistoryCard key={reward.id} reward={reward} navigation={props.navigation} />
                                        ))}
                                </View>
                            </React.Fragment>
                        );
                    })}
                </ScrollView>
            </View>
        );
    }
    return (
        <View>
            <Text>No History</Text>
        </View>
    );
};

export { History };
