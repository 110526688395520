import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Card } from 'react-native-elements';
import moment from 'moment';
import { Text, View, StyleSheet, RefreshControl, FlatList, Pressable, ActivityIndicator } from 'react-native';
import { GET_NOTIFICATIONS } from '../lib/backend/settings/notifications/queries';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsStackParams } from '../types';
import sortBy from 'lodash/sortBy';
import { GET_OPPORTUNITY_BY_TASK } from '../lib/queries';
function notificationTypeToTitle(activity: string) {
    switch (activity) {
        case 'Warning':
            return 'Warning';
        case 'General':
            return 'General';
        case 'NewFollower':
            return 'New Follower';
        case 'Activity':
            return 'Activity';
        case 'TaskRejection':
            return 'Task Rejected';
        case 'TaskApproval':
            return 'Task Approved';
        case 'VoucherRedemption':
            return 'Voucher Redeemed';
        case 'CouponRedemption':
            return 'Coupon Redeemed';
        case 'StoreCreditRedemption':
            return 'Store Credit Redeemed';
    }
}

function getEntityType(type: string): string | undefined {
    switch (type) {
        case 'VoucherRedemption':
            return 'vouchers';
        case 'CouponRedemption':
            return 'coupons';
        case 'StoreCreditRedemption':
            return 'storeCredits';
        default:
            return undefined;
    }
}

interface INotification {
    id: string;
    type: string;
    entityId: string;
    message: string;
    updatedAt: string;
}

type NotificationsScreenRouteProp = StackScreenProps<SettingsStackParams, 'Notifications'>;

export default function Notifications({ navigation }: NotificationsScreenRouteProp) {
    const [refreshingPull, setRefreshingPull] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const { data, loading, refetch, fetchMore } = useQuery<
        { notifications: INotification[] },
        { first: number }
    >(GET_NOTIFICATIONS, {
        variables: {
            first: 10,
        },
    });
    const [getTaskInfo, { data: taskData, loading: taskLoading, error: taskError }] =
        useLazyQuery(GET_OPPORTUNITY_BY_TASK);
    if (!!taskData && !taskLoading && !taskError) {
        //@ts-ignore
        navigation && navigation.navigate('OpportunityDetail', {
                opportunity: taskData.taskVerification.task.opportunity,
                //@ts-ignore
                completedClaims: [],
            });
    }

    const notifications = data?.notifications ? sortBy(data.notifications, ['updatedAt']).reverse() : [];

    const onRefresh = async () => {
        setRefreshingPull(true);
        await refetch();
        setRefreshingPull(false);
    };
    const renderItem = (el: { item: INotification }) => {
        const entityType: string | undefined = getEntityType(el.item.type);
        return (
            <Pressable
                key={el.item.id}
                onPress={() => {
                    if (entityType) {
                        navigation &&
                            navigation.navigate('RewardDetails', {
                                reward: { id: el.item.entityId },
                                rewardType: entityType,
                            });
                    } else {
                        if (el.item.type === 'TaskApproval' || el.item.type === 'TaskRejection') {
                            getTaskInfo({ variables: { taskId: el.item.entityId } });
                        }
                    }
                }}
            >
                <Card
                    containerStyle={styles.cardContainer}
                    wrapperStyle={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Text style={styles.notificationTitle}>{notificationTypeToTitle(el.item.type)}</Text>
                    <Text>{el.item.message}</Text>
                    <Text style={styles.notificationDate}>{moment(el.item.updatedAt).fromNow()}</Text>
                </Card>
            </Pressable>
        );
    };
    if (firstLoad && loading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    } else {
        return (
            <FlatList
                refreshControl={<RefreshControl refreshing={refreshingPull} onRefresh={onRefresh} />}
                data={notifications}
                renderItem={renderItem}
                initialNumToRender={10}
                windowSize={5}
                extraData={loading}
                maxToRenderPerBatch={2}
                removeClippedSubviews={false}
                onEndReachedThreshold={0.1}
                onEndReached={async () => {
                    if (notifications && notifications.length > 0) {
                        // The fetchMore method is used to load new data and add it
                        // to the original query we used to populate the list
                        if (fetchMore) {
                            if (firstLoad) setFirstLoad(false);
                            try {
                                await fetchMore({
                                    variables: {
                                        first: 10,
                                        after: notifications[notifications.length - 1].id,
                                    },
                                });
                            } catch (e) {
                                console.error(e);
                            }
                        }
                    }
                }}
                ListFooterComponent={!loading ? <View style={{ height: 20 }} /> : <ActivityIndicator />}
                //onEndReachedThreshold={0.5}
                keyExtractor={(item: INotification) => item.id}
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    cardContainer: {
        borderRadius: 5,
        elevation: 5,
        marginVertical: 5,
        marginHorizontal: 10,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: {
            height: 1,
            width: 0,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,
    },
    notificationDate: {
        color: 'gray',
        fontSize: 12,
    },
    notificationTitle: {
        fontWeight: 'bold',
    },
    list: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
