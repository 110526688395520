import React, { useMemo, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import cloudinary from 'cloudinary-core';
import { SHOPS, STORE_CREDIT_BY_ID } from '../../lib/queries';
import { useMutation, useQuery } from '@apollo/client';
import { APPROVE_REDEMPTION, CANCEL_REDEMPTION, CREATE_REDEMPTION } from '../../lib/mutations';
import { ErrorText } from '../StyledText';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ICoordinates, IRedemption, IStoreCredit } from '../../store/types';
import Error from '../Error';
import { formatExpirationDate, makeManualActions, redemptionOrderByDateDesc } from './utils';
import RedemptionQRCode from './RedemptionQRCode';
import { RedemptionModal } from './RedemptionModal';
import { IRewardType } from '../../types';
import i18n from '../../config/i18n';
// @ts-ignore
const cl = new cloudinary.Cloudinary({ cloud_name: 'govi', secure: true });

const styles = StyleSheet.create({
    bizBackground: {
        height: 140,
        marginBottom: 5,
        marginTop: 15,
    },
    bizLogo: {
        height: 50,
        marginLeft: 20,
        marginTop: 20,
        width: 50,
    },
    btnContainer: {
        padding: 10,
    },
    buttonOutlineStyle: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    btnPending: {
        backgroundColor: '#fadf92',
    },
    btnSolid: {
        backgroundColor: '#8A84D7',
    },
    disclaimer: {
        color: 'white',
        marginTop: 10,
        padding: 20,
        textAlign: 'left',
    },
    disclaimerHeader: {
        color: 'white',
        marginTop: 10,
        textAlign: 'center',
    },
    goBack: {},
    headerContainer: {
        flexDirection: 'row',
    },
    hidden: {
        display: 'none',
    },
    idHeader: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    locationText: {
        backgroundColor: '#f6c6c5',
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    idText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    instructions: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        padding: 20,
    },
    loading: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    manualApprove: {
        color: 'gray',
        fontFamily: 'Arimo_400Regular',
        fontSize: 16,
        textAlign: 'center',
    },
    modal: {
        backgroundColor: 'white',
        height: 300,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 100,
    },
    modalBtn: {
        backgroundColor: '#8A84D7',
    },
    modalBtnCancel: {
        backgroundColor: '#8A84D7',
    },
    modalBtnContainer: {},
    modalHeader: {
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        marginTop: 20,
        textAlign: 'center',
    },
    overlay: {
        color: '#8A84D7',
        fontFamily: 'Arimo_400Regular',
        fontSize: 45,
        marginTop: -60,
        textAlign: 'center',
        transform: [{ rotate: '15deg' }, { translateX: 10 }, { translateY: 10 }],
    },
    qrWrapper: {
        alignItems: 'center',
        flex: 1,
    },
    redeemedTitle: {
        color: '#3e2657',
        fontFamily: 'Arimo_700Bold',
        fontSize: 30,
        marginTop: 40,
        textAlign: 'center',
        textShadowColor: 'black',
        textShadowOffset: { width: 1.0, height: 1.0 },
    },
    rejectedHeadline: {
        backgroundColor: '#f6c6c5',
        color: 'red',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        paddingTop: 10,
        textAlign: 'center',
    },
    rejectedText: {
        backgroundColor: '#f6c6c5',
        color: 'black',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    root: {
        backgroundColor: '#5a5e5c',
    },
    shopAddress: {
        color: 'white',
    },
    shopInfo: {
        marginLeft: 20,
        marginTop: 10,
    },
    shopName: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
    },
    title: {
        color: 'white',
        fontFamily: 'Arimo_700Bold',
        fontSize: 30,
        marginTop: 35,
        textAlign: 'center',
        textShadowColor: 'black',
        textShadowOffset: { width: 3, height: 4.0 },
        textShadowRadius: 4,
    },
    tosBody: {
        flex: 1,
        padding: 20,
    },
});

const StoreCreditDetail = (props: { storeCreditID: string }) => {
    const { storeCreditID } = props;
    let title = '';
    let expires = '';
    let overlay = '';
    const [modalVisible, setModalVisible] = useState(false);

    const {
        data: rewardData,
        loading: rewardLoading,
        error: rewardError,
        refetch: refetchStoreCredit,
    } = useQuery<{ storeCredit: IStoreCredit }, { storeCreditID: string }>(STORE_CREDIT_BY_ID, {
        pollInterval: 5000,
        variables: {
            storeCreditID,
        },
    });

    const is_expired: boolean = useMemo(() => {
        if (rewardData && rewardData.storeCredit && rewardData.storeCredit.expires) {
            return new Date(rewardData.storeCredit.expires) <= new Date();
        }
        return false;
    }, [rewardData]);

    //return the last redemption that is not canceled, rejected, only if there is not approved redemption
    const pendingRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.storeCredit) {
            const coupon = rewardData.storeCredit;
            if (coupon.redemptions.filter((r) => r.approved).length != 0) {
                return null;
            } else {
                return coupon.redemptions
                    .filter((r) => !(r.canceled || r.rejected))
                    .sort(redemptionOrderByDateDesc)[0];
            }
        } else {
            return null;
        }
    }, [rewardData]);

    const approvedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.storeCredit) {
            const coupon = rewardData.storeCredit;
            const approved = coupon.redemptions.filter((r) => r.approved);
            if (approved.length != 0) {
                return approved.concat().sort(redemptionOrderByDateDesc)[0];
            }
        }
        return null;
    }, [rewardData]);
    const is_approved = useMemo(() => {
        if (approvedRedemption) {
            return true;
        }
        if (rewardData && rewardData.storeCredit && rewardData.storeCredit.redeemed) {
            return rewardData.storeCredit.redeemed;
        }
        return false;
    }, [approvedRedemption, rewardData]);
    const is_pending = useMemo(() => {
        if (is_expired) return false;
        if (is_approved) return false;
        if (pendingRedemption) {
            return true;
        }
        return false;
    }, [pendingRedemption, rewardData, is_approved, is_expired]);
    const rejectedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.storeCredit && rewardData.storeCredit.redemptions.length != 0) {
            const last = rewardData.storeCredit.redemptions.concat().sort(redemptionOrderByDateDesc)[0];
            if (last.rejected) {
                return last;
            }
        }
        return null;
    }, [rewardData]);
    const is_rejected = useMemo(() => {
        return !!rejectedRedemption;
    }, [rejectedRedemption]);
    const can_make_redemption = useMemo(() => {
        return !is_expired && !is_approved && !is_pending;
    }, [is_approved, is_expired, is_pending]);

    const { showActionSheetWithOptions } = useActionSheet();
    const { data: shopsData, loading: shopsLoading, error: shopsError } = useQuery(SHOPS);
    const [createRedemption, { loading: createRedemptionLoading, error: createRedemptionError }] = useMutation<
        { createRedemption: IRedemption },
        {
            rewardId: string;
            rewardType: IRewardType;
            locationId?: string;
        }
    >(CREATE_REDEMPTION, { onCompleted: () => refetchStoreCredit() });
    const [
        approveRedemption,
        { loading: approveRedemptionLoading, error: approveRedemptionError },
    ] = useMutation<
        { approveRedemption: IRedemption },
        {
            redemptionId: string;
            data?: {
                coordinates?: ICoordinates;
                locationId?: string;
            };
        }
    >(APPROVE_REDEMPTION, { onCompleted: () => refetchStoreCredit() });
    const [cancelRedemption, { loading: cancelRedemptionLoading, error: cancelRedemptionError }] = useMutation<
        { cancelRedemption: IRedemption },
        {
            redemptionId: string;
        }
    >(CANCEL_REDEMPTION, { onCompleted: () => refetchStoreCredit() });

    if (rewardError) {
        console.error(rewardError);
        return <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>;
    }

    if (rewardLoading) {
        return <ActivityIndicator color="#7a4ca9" />;
    }
    const storeCredit = rewardData && rewardData.storeCredit;

    const redeemAction = () => {
        if (storeCredit && can_make_redemption) {
            createRedemption({
                variables: {
                    rewardId: storeCredit.id,
                    rewardType: 'StoreCredit',
                },
            }).catch((e) => console.error(e));
        }
    };

    const confirmRedemption = () => {
        if (pendingRedemption && !is_expired) {
            approveRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };

    const cancelRedemptionAction = () => {
        if (pendingRedemption) {
            cancelRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };
    const manualActions = makeManualActions(
        {
            confirmRedemption,
            cancelRedemption: cancelRedemptionAction,
        },
        showActionSheetWithOptions
    );

    const _titleStyle = () => {
        if (storeCredit && (storeCredit.redeemed || storeCredit.pendingApproval)) {
            return styles.redeemedTitle;
        } else {
            return styles.title;
        }
    };

    let redeemTitle = 'Redeem';

    if (storeCredit) {
        if (storeCredit.expires) {
            if (is_expired) {
                expires = 'Expired';
            } else {
                expires = 'Expires ' + formatExpirationDate(storeCredit.expires);
            }
        }
        if (is_approved) {
            redeemTitle = 'Redeemed';
            overlay = 'Redeemed';
            expires = 'Redeemed on ' + formatExpirationDate(storeCredit.redeemedAt);
        } else if (is_pending) {
            redeemTitle = 'Open';
            overlay = 'Pending';
        } else if (is_rejected) {
            redeemTitle = 'Re-Attempt Redemption';
        }
    }
    const loading =
        createRedemptionLoading || cancelRedemptionLoading || approveRedemptionLoading || rewardLoading;

    if (loading) {
        redeemTitle = 'Loading';
    }

    const _getBackground = (src: String) => {
        if (storeCredit) {
            if (!src) {
                return '';
            }
            const imageId = src.substr(src.indexOf('upload') + 7);
            const brightness = storeCredit.redeemed || storeCredit.pendingApproval ? '-80' : '-30';
            const tag = cl.url(imageId, {
                transformation: [{ effect: 'brightness:' + brightness }, { height: 300, crop: 'fill' }],
            });

            return tag.toString();
        }
        return null;
    };

    const _getAddress = (id: any) => {
        if (shops && typeof shops[id] !== 'undefined') {
            return shops[id].address;
        }
        return {
            street1: '',
            city: '',
            state: '',
            postalCode: '',
        };
    };

    const getRedeemBtnStyle = () => {
        if (is_pending) {
            return styles.btnPending;
        }
        return styles.btnSolid;
    };

    if (shopsLoading) {
        return (
            <View style={styles.root}>
                <ActivityIndicator color="#7a4ca9" />
            </View>
        );
    }

    if (shopsError) {
        console.error(shopsError);
        return (
            <View style={styles.root}>
                <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>
            </View>
        );
    }

    const shops = shopsData.businessLocations;

    function cancelSelectRedemptionLocation() {
        setModalVisible(false);
    }

    function redeemWithLocation(rid: string) {
        if (storeCredit) {
            setModalVisible(false);
            createRedemption({
                variables: {
                    rewardType: 'Voucher',
                    rewardId: storeCredit.id,
                    locationId: rid,
                },
            }).catch((e) => console.error(e));
        }
    }

    if (rewardLoading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    } else {
        if (storeCredit) {
            return (
                <ScrollView>
                    <RedemptionModal
                        locations={
                            storeCredit.prototype && storeCredit.prototype.redemptionLocations
                                ? storeCredit.prototype.redemptionLocations
                                : []
                        }
                        visible={modalVisible}
                        onCancel={cancelSelectRedemptionLocation}
                        onRedeem={redeemWithLocation}
                    />
                    <View style={styles.root}>
                        {storeCredit.shop ? (
                            <View style={styles.headerContainer}>
                                <Image style={styles.bizLogo} source={{ uri: storeCredit.shop.logo }} />
                                <View style={styles.shopInfo}>
                                    <Text style={styles.shopName}>{storeCredit.shop.name}</Text>
                                    <Text style={styles.shopAddress}>
                                        {_getAddress(storeCredit.shop.id).street1}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.shopAddress,
                                            !_getAddress(storeCredit.shop.id).street2 && styles.hidden,
                                        ]}
                                    >
                                        {_getAddress(storeCredit.shop.id).street2}
                                    </Text>
                                    <Text style={styles.shopAddress}>
                                        {_getAddress(storeCredit.shop.id).city}
                                    </Text>
                                    <Text style={styles.shopAddress}>
                                        {_getAddress(storeCredit.shop.id).state}{' '}
                                        {_getAddress(storeCredit.shop.id).postalCode}
                                    </Text>
                                </View>
                            </View>
                        ) : null}
                        {storeCredit.shop && storeCredit.shop.primaryImage ? (
                            <ImageBackground
                                style={styles.bizBackground}
                                key={storeCredit.id}
                                source={{
                                    uri: _getBackground(storeCredit.shop.primaryImage),
                                }}
                            >
                                <Text style={_titleStyle()}>{title}</Text>
                                <Text style={styles.overlay}>{overlay}</Text>
                            </ImageBackground>
                        ) : null}
                        <Text style={styles.instructions}>
                            {storeCredit.description}
                            {expires} {i18n.t('rewardShowStaff')}
                        </Text>
                        {storeCredit.rejected && (
                            <>
                                <Text style={styles.rejectedHeadline}>{i18n.t('redemptionRejected')}</Text>
                                <Text style={styles.rejectedText}>
                                    {i18n.t('redemptionRejectReason')}
                                    {rejectedRedemption ? rejectedRedemption.rejectionReason : ''}
                                </Text>
                            </>
                        )}
                        {pendingRedemption && (
                            <RedemptionQRCode
                                loading={loading}
                                error={createRedemptionError}
                                data={{ redemptions: [pendingRedemption] }}
                            />
                        )}

                        <Button
                            containerStyle={styles.btnContainer}
                            buttonStyle={getRedeemBtnStyle()}
                            disabled={loading || is_pending || is_approved || is_expired}
                            title={redeemTitle}
                            onPress={() => redeemAction()}
                        />
                        <Button
                            containerStyle={styles.btnContainer}
                            disabled={!is_pending || loading}
                            title={'Manual Actions'}
                            onPress={() => manualActions()}
                        />
                        <Text style={styles.idHeader}>{i18n.t('transactionId')}</Text>
                        <Text style={styles.idText}>{storeCredit.id}</Text>
                        {approvedRedemption && approvedRedemption.redemptionLocation && (
                            <View style={styles.locationText}>
                                <Text style={styles.shopName}>
                                    {approvedRedemption.redemptionLocation.name}
                                </Text>
                                <Text style={styles.shopAddress}>
                                    {approvedRedemption.redemptionLocation.street1}
                                </Text>
                                <Text
                                    style={[
                                        styles.shopAddress,
                                        !approvedRedemption.redemptionLocation.street2 && styles.hidden,
                                    ]}
                                >
                                    {approvedRedemption.redemptionLocation.street2}
                                </Text>
                                <Text style={styles.shopAddress}>
                                    {approvedRedemption.redemptionLocation.city}
                                </Text>
                                <Text style={styles.shopAddress}>
                                    {approvedRedemption.redemptionLocation.state}{' '}
                                </Text>
                                <Text style={styles.shopAddress}>
                                    {approvedRedemption.redemptionLocation.postalCode}
                                </Text>
                            </View>
                        )}
                        {storeCredit.restriction && (
                            <Text style={styles.disclaimerHeader}>
                                {i18n.t('storecredit')}
                                {i18n.t('tc')}
                            </Text>
                        )}
                        <Text style={styles.disclaimer}>{storeCredit.restriction || ''}</Text>
                    </View>
                </ScrollView>
            );
        } else {
            return <Error />;
        }
    }
};

StoreCreditDetail.navigationOptions = {
    title: 'Rewards',
    headerTintColor: '#212322',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { StoreCreditDetail };
