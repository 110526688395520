import React from 'react';
import { Button, StyleSheet } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { View, Text } from '../../components/Themed';
import { START_TASK_MUTATION } from '../../lib/mutations';
import { GET_OPPORTUNITY_CLAIM } from '../../lib/queries';
import i18n from '../../config/i18n';

const ManualInPerson = (props: any) => {
    const { route } = props;
    const { opportunity, task, claim } = route.params;
    let verification = null;

    const [startTaskMutation] = useMutation(START_TASK_MUTATION);
    const {
        loading: loadingClaim,
        error: claimError,
        data: claimsData,
        refetch,
    } = useQuery(GET_OPPORTUNITY_CLAIM, {
        variables: {
            opportunityId: opportunity.id,
        },
    });

    const requestVerification = () => {
        startTaskMutation({
            variables: {
                opportunityId: opportunity.id,
                taskId: task.id,
            },
        });
    };

    return (
        <>
            <View style={{ ...styles.verificationInstructions, ...styles.manualInPerson }}>
                <Text>{i18n.t('inPersonVerificationRequest')}</Text>
                <Text style={styles.verificationInstructionsText}>
                    {i18n.t('onSite')}
                </Text>
            </View>
            <Button title="Request In-Person Verification" onPress={requestVerification} />
        </>
    );
};

export { ManualInPerson };

const styles = StyleSheet.create({
    verificationInstructions: {
        display: 'flex',
        paddingLeft: 10,
        paddingRight: 10,
        flexDirection: 'column',
        width: 300,
    },
    verificationInstructionsText: {
        fontSize: 12,
    },
    manualInPerson: {
        backgroundColor: '#FBE08E',
        color: '#8B572A',
    },
});
