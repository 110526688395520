import gql from "graphql-tag";

//get current user profile
export const GET_NOTIFICATIONS = gql`
query GetNotifications($first: Int!, $after: String){
  notifications(where:{}, first:$first, after: $after){
    id, 
    type,
    message,
    image,
    entityId,
    read,
    createdAt,
    updatedAt
  }
}`;


export const UNREAD_NOTIFICATIONS_COUNT = gql`
query UnreadNotificationsCount{
  notificationsConnection(where:{read:false}){
    aggregate{
      count
    }
  }
}`;
