import React from 'react';
import { Dimensions, ImageBackground, TouchableHighlight, View, Text } from 'react-native';
import { IAsset, MediaTypeValue } from "../../types";
import { Ionicons } from "@expo/vector-icons";

const { width, height } = Dimensions.get('window');

interface GalleryTile {
    item: IAsset,
    index: number;
    showCaption?: boolean;
    selected?: boolean;

    onPress: (item: IAsset, index: number) => void
}

export function GalleryTile(props: GalleryTile) {
    const { item, index, onPress } = props;
    let uri = "";
    const showCaption = props.showCaption != undefined && props.showCaption != null ? props.showCaption : false;
    const newHeight = parseInt(((width / 3 - 1) * (height / width) * 0.67).toString(), 10);
    const selected = props.selected != undefined && props.selected != null ? props.selected : false;
    if (item.mediaType == MediaTypeValue.Video) {
        const parts = item.uri.split("/");
        const file = parts[parts.length - 1].replace(".mov", ".gif");
        uri = `https://res.cloudinary.com/govi/video/upload/c_scale,h_${newHeight}/e_loop/dl_200,vs_40/${file}`;
    } else {
        uri = item.uri;
    }
    const renderSelectedComponent = () => {
        return <View style={{ display: "flex", alignItems: "flex-end" }}>
            <Ionicons name="ios-checkmark-circle-outline" size={24} color="#00008B"/>
        </View>
    };
    return (
        <TouchableHighlight
            style={{ marginBottom: 2, marginRight: 2 }}
            underlayColor='transparent'
            onPress={() => onPress(item, index)}>
            <View style={{ position: 'relative' }}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ImageBackground
                        style={{ width: (width / 3 - 1), height: newHeight }}
                        source={{ uri: uri }}>
                        {selected ? renderSelectedComponent() : null}
                    </ImageBackground>
                </View>
                {showCaption ? <View>
                    <Text style={{
                        paddingHorizontal: 4,
                        flex: 1,
                        width: (width / 3 - 1)
                    }}>{item.caption && item.caption.slice(0, 100)} {item.caption && item.caption.length > 100 ? '...' : ''}</Text>
                </View> : null}
            </View>
        </TouchableHighlight>
    )
}

