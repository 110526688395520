import { ActivityComment, ITask, IRedemptionLocation } from './store/types';
import { UploadAssetScreenParams, UploadResponse } from './screens/media/UploadAssetScreen';
import { AssetScrollScreenParams } from './screens/media/AssetScrollScreen';
import { GalleryScreenParams } from './screens/media/GalleryScreen';
import { GalleryEditorScreenParams } from './screens/media/GalleryEditorScreen';
import { ITaskVerification } from './screens/OpportunityDetail';

export type RootStackParamList = {
    Login: undefined;
    SignUp: undefined;
    BottomTabs: undefined;
    NotFound: undefined;
    Onboarding: undefined;
};

export type BottomTabParamList = {
    Home: undefined;
    Opportunities: {
        filter: string;
    };
    Shops: undefined;
    Earn: undefined;
    WalletTabs: undefined;
    Wallet: undefined;
    Settings: undefined;
    WalletHistory: undefined;
};

export interface IReward {
    id: string;
    skuImage?: string;
    skuImageBronze?: string;
    skuImageSilver?: string;
    skuImageGold?: string;
    skuImagePlatinum?: string;
    expires?: Date;
    amount?: number;
    type: IRewardType;
    claimPerCustomerLimit?: number;
    expiration?: Date;
    variable?: boolean;
    low: number;
    high: number;
    voucherTiers: string[];
    tiers: number[];
    percentage?: boolean;
    minPurchase?: number;
    redemptionLocations?: IRedemptionLocation[];
}

export type IRewardType =
    | 'Voucher'
    | 'StoreCredit'
    | 'CouponFixed'
    | 'CouponPercentage'
    | 'CouponCustom'
    | 'Cash'
    | 'GoviCash';

export interface IOpportunityClaim {
    id: string;
    rewardId: string;
    submitted: boolean;
    rejected: boolean;
    verifications: ITaskVerification[];
    completed: boolean;
    purchaseAmount: number;
    submittedAt?: Date;
    opportunity: IOpportunity;
}
export interface IOpportunity {
    name: string;
    id: string;
    image: string;
    endDate: string;
    startDate: string;
    reward: IReward;
    detailedDescription?: string;
    oneLineDescription: string;
    instanceLimit?: number;
    claimed?: number;
    perpetual?: boolean;
    gift: boolean;
    tasks: ITask[];
}

export type TabFeedParamList = {
    TabFeedScreen: undefined;
};

export type TabRewardsParamList = {
    Wallet: undefined;
    WalletTabs: undefined;
    RewardDetails: undefined;
    RewardNotification: undefined;
};

export type ShopsStackParams = {
    Shops: undefined;
    ShopDetail: undefined;
    OpportunityDetail: { opportunity: IOpportunity };
    Verification: { task: any; claim: any; opportunity: any };
    ManualDigital: { route: any; navigation: any };
    ManualInPerson: { route: any; navigation: any };
};

export type SettingsStackParams = {
    Settings: undefined;
    Profile: undefined;
    Info: undefined;
    Notifications: undefined;
    PrivacyPolicy: undefined;
    TOS: undefined;
    History: undefined;
    Onboarding: undefined;
    RewardDetails: {
        reward: { id: string };
        rewardType: string;
    };
    VerificationDetails: {
        task: { id: string };
        claim: any;
        opportunity: any;
    };
    OpportunityDetail: {
        opportunity: IOpportunity;
    };
};

export type OpportunityStackParams = {
    Opportunities: undefined;
    Opportunity: undefined;
    OpportunityDetail: {
        opportunityId: string;
    };
    Verification: { task: any; claim: any; opportunity: any };
    ManualDigital: {
        opportunity: IOpportunity;
        taskId: string;
        task: ITask;
        claim: any;
        uploadResponse?: UploadResponse;
    };
    ManualInPerson: { route: any; navigation: any };
    Upload: UploadAssetScreenParams;
    AssetPager: AssetScrollScreenParams;
    Gallery: GalleryScreenParams;
    GalleryEditor: GalleryEditorScreenParams;
    Survey: any;
};

export type ActivityFeedStackParams = {
    ActivityFeed: { name: String };
    Feeds: { name: String };
    Notifications: { name: String };
    Screen: { name: String };
    Comments: { comments: ActivityComment[] };
};

export type GalleryStackParams = {
    Gallery: undefined;
};

export enum MediaTypeValue {
    Audio = 'Audio',
    Photo = 'Photo',
    Video = 'Video',
    Unknown = 'Unknown',
}

export interface IAsset {
    id: string;
    uri: string;
    mediaType: MediaTypeValue;
    mediaSubtypes: string[];
    width: number;
    height: number;
    duration: number;
    exif?: string;
    caption?: string;
    createdAt: Date;
    updatedAt: Date;
}
