import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState, createContext } from 'react';
import AppLoading from 'expo-app-loading';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import {
    useFonts,
    JosefinSans_400Regular,
    JosefinSans_600SemiBold,
    JosefinSans_700Bold,
} from '@expo-google-fonts/josefin-sans';
import { FjallaOne_400Regular } from '@expo-google-fonts/fjalla-one';
import { Arimo_400Regular, Arimo_700Bold } from '@expo-google-fonts/arimo';
import { EncodeSans_400Regular } from '@expo-google-fonts/encode-sans';
import * as Font from 'expo-font';
import useCachedResources from './hooks/useCachedResources';
import ProfileContext from './components/ProfileContext';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { Provider, useDispatch } from 'react-redux';
import { generateStore } from './store';
import { Asset } from 'expo-asset';
import { Image, Text, TextInput } from 'react-native';
import { reloadToken } from './store/api';
import { ActionSheetProvider, connectActionSheet } from '@expo/react-native-action-sheet';
import * as firebase from 'firebase';
import { SET_FIREBASE_CONTEXT } from './store/types';
import {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID, // @ts-ignore
} from '@env';

interface TextWithDefaultProps extends Text {
    defaultProps?: { allowFontScaling?: boolean };
}
interface TextInputWithDefaultProps extends TextInput {
    defaultProps?: { allowFontScaling?: boolean };
}
(Text as unknown as TextWithDefaultProps).defaultProps =
    (Text as unknown as TextWithDefaultProps).defaultProps || {};
(Text as unknown as TextWithDefaultProps).defaultProps!.allowFontScaling = false;
(TextInput as unknown as TextInputWithDefaultProps).defaultProps =
    (TextInput as unknown as TextInputWithDefaultProps).defaultProps || {};
(TextInput as unknown as TextInputWithDefaultProps).defaultProps!.allowFontScaling = false;

function cacheImages(images: any[]) {
    return images.map((image) => {
        if (typeof image === 'string') {
            return Image.prefetch(image);
        } else {
            return Asset.fromModule(image).downloadAsync();
        }
    });
}

const firebaseConfig = {
    apiKey: FIREBASE_API_KEY || '', // Not sure we need this! we should not be using a key here
    authDomain: FIREBASE_AUTH_DOMAIN || 'prod-main.firebaseapp.com',
    databaseURL: FIREBASE_DATABASE_URL || 'https://prod-main.firebaseio.com/',
    projectId: FIREBASE_PROJECT_ID || 'prod-main',
    storageBucket: FIREBASE_STORAGE_BUCKET || 'prod-main.appspot.com',
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID || '816190843708',
};

async function loadAssetsAsync() {
    try {
        await firebase.initializeApp(firebaseConfig);
        await Font.loadAsync({
            Lato: require('./assets/fonts/LatoLatin-Regular.ttf'),
            'Lato-Light': require('./assets/fonts/Lato-Light.ttf'),
            'Lato-Heavy': require('./assets/fonts/Lato-Heavy.ttf'),
            'Lato-Bold': require('./assets/fonts/Lato-Bold.ttf'),
            'Lato-Semibold': require('./assets/fonts/Lato-Semibold.ttf'),
            'Lato-Medium': require('./assets/fonts/Lato-Medium.ttf'),
        });
    } catch (e) {
        console.error(e);
    }

    const imageAssets: Promise<Boolean | Asset>[] = cacheImages([
        'https://res.cloudinary.com/govi/image/upload/v1613457672/biz/Screen_Shot_2021-02-15_at_11.40.46_PM_uuistl.png',
        require('./assets/images/loginbg.jpg'),
        require('./assets/images/coupon.png'),
        require('./assets/images/voucher.png'),
        require('./assets/images/storecredit.png'),
        require('./assets/images/transaction.png'),
        require('./assets/images/boosttoken.png'),
        require('./assets/images/govicash.png'),
        require('./assets/images/platinum.png'),
        require('./assets/images/gold.png'),
        require('./assets/images/silver.png'),
        require('./assets/images/bronze.png'),
        require('./assets/images/user.png'),
    ]);
    try {
        await Promise.all(imageAssets);
    } catch (e) {
        console.error(e);
    }
}

function App({ store }: any) {
    const isLoadingComplete = useCachedResources();
    const [isReady, setIsReady] = useState(false);
    const dispatch = useDispatch();
    const FirebaseContext: any = createContext(firebase);
    const { consumer: consumerState } = store.getState();

    useEffect(() => {
        dispatch({
            type: SET_FIREBASE_CONTEXT,
            payload: FirebaseContext,
        });

        //try to reload token
        if (consumerState.token) {
            dispatch(reloadToken(firebase));
        }
    });

    const colorScheme = useColorScheme();
    let [fontsLoaded] = useFonts({
        JosefinSans_400Regular,
        JosefinSans_600SemiBold,
        Arimo_400Regular,
        JosefinSans_700Bold,
        Arimo_700Bold,
        FjallaOne_400Regular,
        EncodeSans_400Regular,
    });
    if (!isLoadingComplete || !fontsLoaded || !isReady || !FirebaseContext) {
        return (
            <AppLoading startAsync={loadAssetsAsync} onFinish={() => setIsReady(true)} onError={console.warn} />
        );
    } else {
        return (
            <ActionSheetProvider>
                <SafeAreaProvider>
                    {/** @ts-ignore, if FirebaseContext is null, we capture that above */}
                    <FirebaseContext.Provider value={firebase}>
                        <Navigation colorScheme={colorScheme} />
                        {typeof consumerState.token !== 'undefined' && <ProfileContext />}
                        <StatusBar />
                        {/** @ts-ignore, if FirebaseContext is null, we capture that above */}
                    </FirebaseContext.Provider>
                </SafeAreaProvider>
            </ActionSheetProvider>
        );
    }
}

const HOCApp = () => {
    const { store, client } = generateStore();

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                {/** @ts-ignore  https://github.com/apollographql/apollo-client/issues/2042#issuecomment-509041949 */}
                <ApolloHooksProvider client={client}>
                    <App store={store} />
                </ApolloHooksProvider>
            </ApolloProvider>
        </Provider>
    );
};

const ConnectedApp = connectActionSheet(HOCApp);

export { ConnectedApp as default };
