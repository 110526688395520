import moment from 'moment';
import { IRedemption } from '../../store/types';
import {
    CONSUMER_SERVER, // @ts-ignore
} from '@env';

export const redemptionUrl = (redemptionId: string) => {
    return `${CONSUMER_SERVER}/redeem/${redemptionId}`;
};

export function redemptionOrderByDateDesc(a: IRedemption, b: IRedemption): -1 | 0 | 1 {
    const a_createdAt = new Date(a.createdAt);
    const b_createdAt = new Date(b.createdAt);
    if (a_createdAt > b_createdAt) {
        return -1;
    } else if (a_createdAt > b_createdAt) {
        return 1;
    } else {
        return 0;
    }
}

export const makeManualActions =
    (
        props: { confirmRedemption?: () => void; cancelRedemption?: () => void },
        showActionSheetWithOptions: any
    ) =>
    () =>
        showActionSheetWithOptions(
            {
                options: ['Close', 'Confirm Redemption', 'Cancel Redemption'],
                destructiveButtonIndex: 2,
                cancelButtonIndex: 0,
                title: 'Redeem Actions',
                message: 'Use reward on this purchase? Once used, this reward cannot be redeemed again',
            },
            (buttonIndex: number) => {
                if (buttonIndex === 0) {
                    // cancel action
                } else if (buttonIndex === 1) {
                    if (props.confirmRedemption) {
                        return props.confirmRedemption();
                    }
                } else if (buttonIndex === 2) {
                    if (props.cancelRedemption) {
                        return props.cancelRedemption();
                    }
                }
            }
        );

export function formatExpirationDate(expDate: any) {
    return moment(Date.parse(expDate)).calendar();
}

export function getRankedSku(reward: any, user: any) {
    if (!user) {
        if (reward.prototype && reward.prototype.skuImage) {
            return reward.prototype.skuImage;
        }
        return '';
    }

    const rankedReward = reward.prototype['skuImage' + user.rank];
    if (rankedReward) {
        return rankedReward;
    } else {
        return reward.prototype.skuImage;
    }
}
