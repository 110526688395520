import * as React from 'react';
import * as Device from 'expo-device';
import { Alert, View, Text, Platform } from 'react-native';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import Wallet from '../screens/Wallet';
import Profile from '../screens/Profile';
import Feed from '../screens/Feed';
import Notifications from '../screens/Notifications';
import { ShopsScreen } from '../screens/ShopsScreen';
import { ShopDetail } from '../screens/ShopDetail';
import { useMutation } from '@apollo/client';
import OpportunityTabs from './OpportunityTabs';
import MainTabs from './MainTabs';
import WalletTabs from './WalletTabs';
import {
    ActivityFeedStackParams,
    ShopsStackParams,
    BottomTabParamList,
    OpportunityStackParams,
    SettingsStackParams,
    TabRewardsParamList,
} from '../types';
import { RewardDetail } from '../screens/RewardDetail';
import { Info } from '../screens/about/Info';
import { PrivacyPolicy } from '../screens/about/PrivacyPolicy';
import { History } from '../screens/about/History';
import { TOS } from '../screens/about/TOS';
import Settings from '../screens/Settings';
import { OpportunityDetail } from '../screens/OpportunityDetail';
import { TaskVerificationDetail } from '../screens/TaskVerificationDetail';
import { ManualInPerson, ManualDigital } from '../screens/verifications/';
import { Comments } from '../screens/CommentsScreen';
import RewardNotification from '../screens/rewards/RewardNotification';
import { UploadAssetScreen } from '../screens/media/UploadAssetScreen';
import { AssetScrollScreen } from '../screens/media/AssetScrollScreen';
import { GalleryScreen } from '../screens/media/GalleryScreen';
import { GalleryEditorScreen } from '../screens/media/GalleryEditorScreen';
import { Survey } from '../components/Survey/Survey';
import * as ExpoNotifications from 'expo-notifications';
import { UPDATE_PUSH_TOKEN } from '../lib/mutations';
// @ts-ignore
import { CURRENT_SHOP_ID } from '@env';
import { useQuery, useApolloClient } from '@apollo/client';
import { GET_NOTIFICATIONS } from '../lib/backend/settings/notifications/queries';
import { NOTIFICATION_CREATED } from '../lib/subs';
import * as Permissions from 'expo-permissions';
import { OppLocationsScreen } from '../screens/OppLocationsScreen';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
    const colorScheme = useColorScheme();
    const [updatePushToken, { error }] = useMutation(UPDATE_PUSH_TOKEN, { onCompleted: () => {} });
    const [notificationsChecked, setNotificationsChecked] = React.useState(false);
    const registerForPushNotificationsAsync = async () => {
        //  ... our current version of Expo blows this up
        if (Device.isDevice) {
            try {
                const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
                let finalStatus = existingStatus;
                if (existingStatus !== 'granted') {
                    const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
                    finalStatus = status;
                }

                if (finalStatus !== 'granted') {
                    Alert.alert('You will not be alerted of new rewards'); // TODO: Change this to something more designed
                    return;
                }

                // TODO: support token refresh
                const token = (await ExpoNotifications.getExpoPushTokenAsync()).data;
                updatePushToken({
                    variables: {
                        token,
                        machineId: Device.modelId,
                        machineName: Device.deviceName,
                        shopId: CURRENT_SHOP_ID,
                        metadata: '', // TODO: grab other pertinent data
                    },
                });
            } catch (err) {
                console.error(err);
            }
            if (Platform.OS === 'android') {
                ExpoNotifications.setNotificationChannelAsync('default', {
                    name: 'default',
                    importance: ExpoNotifications.AndroidImportance.MAX,
                    vibrationPattern: [0, 250, 250, 250],
                    lightColor: '#8A84D7',
                });
            }
            ExpoNotifications.setNotificationHandler({
                handleNotification: async () => ({
                    shouldShowAlert: true,
                    shouldPlaySound: false,
                    shouldSetBadge: true,
                }),
            });
        } else {
            console.warn('Must use physical device for Push Notifications');
        }
    };
    const client = useApolloClient();
    const subscription = client.subscribe({
        query: NOTIFICATION_CREATED,
    });

    const { refetch } = useQuery(GET_NOTIFICATIONS, {
        variables: {
            first: 10,
        },
    });

    React.useEffect(() => {
        if (!notificationsChecked) {
            registerForPushNotificationsAsync();
            setNotificationsChecked(true);
        }
    });

    subscription.subscribe(() => {
        try {
            refetch();
        } catch (err) {
            console.trace(err);
        }
    });

    if (error) {
        <View>
            <Text>{error}</Text>
        </View>;
    }

    return (
        <BottomTab.Navigator
            initialRouteName="Home"
            tabBarOptions={{ activeTintColor: Colors[colorScheme].tint, 
                style:{
                    backgroundColor: Colors[colorScheme].background,
                },
            }}
        >
            <BottomTab.Screen
                name="Home"
                component={ActivityFeedTabNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="ios-home-sharp" color={color} />,
                }}
            />
            <BottomTab.Screen
                name="Earn"
                component={OpportunitiesTabNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="ios-gift" color={color} />,
                }}
            />
            <BottomTab.Screen
                name="Shops"
                component={ShopsTabNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIconFA name="shopping-bag" color={color} />,
                }}
            />
            <BottomTab.Screen
                name="Wallet"
                component={TabWalletNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="ios-wallet" color={color} />,
                }}
            />

            <BottomTab.Screen
                name="Settings"
                component={SettingsTabNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="ios-settings-sharp" color={color} />,
                }}
            />
        </BottomTab.Navigator>
    );
}

const ActivityFeedStack = createStackNavigator<ActivityFeedStackParams>();

function ActivityFeedTabNavigator() {
    return (
        <ActivityFeedStack.Navigator>
            <ActivityFeedStack.Screen name="Feeds" component={MainTabs} options={{ headerTitle: 'Feed' }} />
            <ActivityFeedStack.Screen
                name="ActivityFeed"
                component={Feed}
                options={{ headerTitle: 'Main Feed' }}
            />
            <ActivityFeedStack.Screen
                name="Notifications"
                component={Notifications}
                options={{ headerTitle: 'Notifications' }}
            />
            <ActivityFeedStack.Screen
                name="Comments"
                component={Comments}
                options={{ headerTitle: 'Comments' }}
            />
            <SettingsStack.Screen
                name="RewardDetails"
                component={RewardDetail}
                options={{ headerTitle: 'Detail' }}
            />
            <SettingsStack.Screen
                name="VerificationDetails"
                component={TaskVerificationDetail}
                options={{ headerTitle: 'Task' }}
            />
            <SettingsStack.Screen
                name="OpportunityDetail"
                component={OpportunityDetail}
                options={{ headerTitle: 'Opportunity' }}
            />
        </ActivityFeedStack.Navigator>
    );
}

const OpportunityStack = createStackNavigator<OpportunityStackParams>();

function OpportunitiesTabNavigator() {
    return (
        <OpportunityStack.Navigator>
            <OpportunityStack.Screen
                name="Opportunities"
                component={OpportunityTabs}
                options={{ headerTitle: 'Opportunity Feed' }}
            />
            <OpportunityStack.Screen
                name="OpportunityDetail"
                component={OpportunityDetail}
                options={{ headerTitle: 'Details' }}
            />
            <OpportunityStack.Screen
                name="Verification"
                component={TaskVerificationDetail}
                options={{ headerTitle: 'Task' }}
            />
            <OpportunityStack.Screen
                name="ManualInPerson"
                component={ManualInPerson}
                options={{ headerTitle: 'Verification' }}
            />
            <OpportunityStack.Screen
                name="ManualDigital"
                component={ManualDigital}
                options={{ headerTitle: 'Verification' }}
            />
            <OpportunityStack.Screen name="Survey" component={Survey} options={{ headerTitle: 'Survey' }} />
            <OpportunityStack.Screen
                name="Upload"
                component={UploadAssetScreen}
                options={{ headerTitle: 'Upload' }}
            />
            <OpportunityStack.Screen
                name="AssetPager"
                component={AssetScrollScreen}
                options={{ headerTitle: 'Assets' }}
            />
            <OpportunityStack.Screen
                name="Gallery"
                component={GalleryScreen}
                options={{ headerTitle: 'Gallery' }}
            />
            <OpportunityStack.Screen
                name="GalleryEditor"
                component={GalleryEditorScreen}
                options={{ headerTitle: 'Gallery Editor' }}
            />
            <ShopsStack.Screen
                options={{ title: 'Reward Redemption Location' }}
                name="Shops"
                component={OppLocationsScreen}
            />
            <ShopsStack.Screen name="ShopDetail" component={ShopDetail} options={_getDetailOpts} />
        </OpportunityStack.Navigator>
    );
}

function TabBarIcon(props: { name: any; color: string }) {
    return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

function TabBarIconFA(props: { name: any; color: string }) {
    return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

const WalletStack = createStackNavigator<TabRewardsParamList>();

function TabWalletNavigator() {
    return (
        <WalletStack.Navigator>
            <WalletStack.Screen name="WalletTabs" component={WalletTabs} options={{ headerTitle: 'Wallet' }} />

            <WalletStack.Screen name="Wallet" component={Wallet} options={{ headerTitle: 'Wallet' }} />

            <WalletStack.Screen
                name="RewardDetails"
                component={RewardDetail}
                options={{ headerTitle: 'Detail' }}
            />

            <WalletStack.Screen
                name="RewardNotification"
                component={RewardNotification}
                options={{ headerTitle: 'Congratulations' }}
            />
        </WalletStack.Navigator>
    );
}

const SettingsStack = createStackNavigator<SettingsStackParams>();

function SettingsTabNavigator() {
    return (
        <SettingsStack.Navigator>
            <SettingsStack.Screen name="Settings" component={Settings} options={{ headerTitle: 'Settings' }} />
            <SettingsStack.Screen options={{ title: 'Profile' }} name="Profile" component={Profile} />
            <SettingsStack.Screen
                options={{ title: 'Notifications' }}
                name="Notifications"
                component={Notifications}
            />
            <SettingsStack.Screen options={{ title: 'Info' }} name="Info" component={Info} />
            <SettingsStack.Screen
                options={{ title: 'Privacy Policy' }}
                name="PrivacyPolicy"
                component={PrivacyPolicy}
            />
            <SettingsStack.Screen options={{ title: 'History' }} name="History" component={History} />
            
            <SettingsStack.Screen options={{ title: 'Terms of Service' }} name="TOS" component={TOS} />
            <SettingsStack.Screen
                name="RewardDetails"
                component={RewardDetail}
                options={{ headerTitle: 'Detail' }}
            />
        </SettingsStack.Navigator>
    );
}

const ShopsStack = createStackNavigator<ShopsStackParams>();

const _getDetailOpts = (params: any) => {
    const { route } = params;
    return { title: route.params.shop.name };
};

function ShopsTabNavigator() {
    const _getShopDetailRouteOptions = (params: any) => {
        const route = params.route;
        if (route && route.params) {
            return { title: route.params.shop.name };
        }
        return { title: 'ShopDetail' };
    };
    return (
        <ShopsStack.Navigator>
            <ShopsStack.Screen options={{ title: 'Shops' }} name="Shops" component={ShopsScreen} />
            <ShopsStack.Screen name="ShopDetail" component={ShopDetail} options={_getDetailOpts} />
            <ShopsStack.Screen
                name="OpportunityDetail"
                component={OpportunityDetail}
                options={{ headerTitle: 'Details' }}
            />
            <ShopsStack.Screen
                name="Verification"
                component={TaskVerificationDetail}
                options={{ headerTitle: 'Task' }}
            />
            <ShopsStack.Screen
                name="ManualInPerson"
                component={ManualInPerson}
                options={{ headerTitle: 'Verification' }}
            />
            <ShopsStack.Screen
                name="ManualDigital"
                component={ManualDigital}
                options={{ headerTitle: 'Verification' }}
            />
        </ShopsStack.Navigator>
    );
}
