import { FlatList } from 'react-native';
import * as React from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { ActivityFeedStackParams } from '../types';
import { CommentBubble } from '../components/PostCard/components/CommentBubble/CommentBubble';

type ActivityFeedRouteProp = StackScreenProps<ActivityFeedStackParams, 'Comments'>;

export function Comments({ route }: ActivityFeedRouteProp) {
    const { comments } = route.params;

    function renderItem(el: any) {
        return <CommentBubble comment={el.item} />;
    }

    return (
        <FlatList nestedScrollEnabled data={comments} renderItem={renderItem} keyExtractor={(el) => el.id} />
    );
}
