import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { hydration } from './hydrate';
import { ConsumerReducer, ShopReducer } from './reducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    ApolloClient,
    Operation,
    HttpLink,
    ApolloLink,
    split,
    concat,
    Observable,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwt_decode from 'jwt-decode';
import { clearToken, RENEW_TOKEN, setToken } from './api';
// @ts-ignore
import { GRAPHQL_HTTP_BACKEND, ENVIRONMENT, GRAPHQL_WEBSOCKET_BACKEND } from '@env';
import { createUploadLink } from 'apollo-upload-client';

const { manifest } = Constants;

function genStoreAsync() {
    const request = async (operation: Operation) => {
        const token = await AsyncStorage.getItem('@token');
        operation.setContext({
            headers: {
                govi_app_device_os_build_id: Device.osBuildId,
                govi_app_device_os_name: Device.osName,
                govi_app_device_name: Device.deviceName,
                authorization: token,
            },
        });
    };

    const requestLink = new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle: any;
                Promise.resolve(operation)
                    .then((oper) => request(oper))
                    .then(() => {
                        const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        };
                        handle = forward(operation).subscribe(subscriber);
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    if (handle) handle.unsubscribe();
                };
            })
    );

    //todo: Don't delete this, there is a weird bug that load the incorrect http link on local environment
    if (manifest.debuggerHost) {
        console.log(manifest.debuggerHost, 'debuggerHost');
    }
    const httpLink = (function () {
        if (manifest.packagerOpts) {
            if (manifest.packagerOpts.dev && manifest.packagerOpts.hostType == 'lan' && manifest.debuggerHost) {
                return createUploadLink({
                    uri: GRAPHQL_HTTP_BACKEND || `http://${manifest.debuggerHost.split(':')[0]}:4000/graphql`,
                });
            }
        }
        if (Device.brand == null) {
            if (ENVIRONMENT == 'LOCAL') {
                return createUploadLink({
                    uri: `http://localhost:4000/graphql`,
                });
            }
        }
        return createUploadLink({ uri: GRAPHQL_HTTP_BACKEND || 'https://api.goviapp.com/graphql' });
    })();
    const errorLink = onError(({response, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({extensions, message, locations, path }) => {
                if(extensions){
                   const {code} = extensions;
                   if(code){
                       if(code== "CLAIM_LIMIT_PER_USER_PER_OPPORTUNITY_REACHED" || code== "CLAIM_LIMIT_PER_OPPORTUNITY_REACHED"){
                           return
                       }
                       if(code == "UNAUTHENTICATED"){
                        store.dispatch(clearToken());
                           return
                       }
                   }
                }
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

            });
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
        }
    });

    const authorizedLink = concat(requestLink, httpLink);

    const errorReadyAuthLink = concat(errorLink, authorizedLink);

    const wsLink = new WebSocketLink({
        uri: (function () {
            if (manifest.packagerOpts) {
                if (
                    manifest.packagerOpts.dev &&
                    manifest.packagerOpts.hostType == 'lan' &&
                    manifest.debuggerHost
                ) {
                    return (
                        GRAPHQL_WEBSOCKET_BACKEND || `ws://${manifest.debuggerHost.split(':')[0]}:4000/graphql`
                    );
                }
            }
            if (Device.brand == null) {
                if (ENVIRONMENT == 'LOCAL') {
                    return 'ws://localhost:4000/graphql';
                }
            }
            return GRAPHQL_WEBSOCKET_BACKEND || 'wss://api.goviapp.com/graphql';
        })(),
        options: {
            reconnect: true,
            connectionParams: async () => ({
                authToken: await AsyncStorage.getItem('@token'),
                govi_app_device_os_build_id: Device.osBuildId,
                govi_app_device_os_name: Device.osName,
                govi_app_device_name: Device.deviceName,
            }),
        },
    });
    const refreshLink = new TokenRefreshLink({
        isTokenValidOrUndefined: () => {
            let state = store.getState();
            if (state.consumer.token && state.consumer.token.trim() != '') {
                let decoded = null;
                try {
                    decoded = jwt_decode<{ [key: string]: any } | null>(state.consumer.token);
                }catch(_){
                    return false;
                }
                if (decoded) {
                    if (isNaN(decoded.exp)) {
                        return true;
                    } else {
                        let exp = new Date(decoded.exp * 1000);
                        return exp > new Date();
                    }
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        // @ts-ignore
        fetchAccessToken: async () => {
            let state = store.getState();

            function endpoint(): string {
                if (manifest.packagerOpts) {
                    if (
                        manifest.packagerOpts.dev &&
                        manifest.packagerOpts.hostType == 'lan' &&
                        manifest.debuggerHost
                    ) {
                        return (
                            GRAPHQL_HTTP_BACKEND || `http://${manifest.debuggerHost.split(':')[0]}:4000/graphql`
                        );
                    }
                }
                if (Device.brand == null) {
                    if (ENVIRONMENT == 'LOCAL') {
                        return `http://localhost:4000/graphql`;
                    }
                }
                return GRAPHQL_HTTP_BACKEND || 'https://api.goviapp.com/graphql';
            }

            let renewClient: ApolloClient<NormalizedCacheObject>;
            renewClient = new ApolloClient({
                link: new HttpLink({ uri: endpoint() }),
                cache: new InMemoryCache({}),
            });

            if (state.consumer.token && state.consumer.token.trim() != '') {
                let response = await renewClient.mutate<{ renewToken: string | null }, { token: string }>({
                    mutation: RENEW_TOKEN,
                    variables: {
                        token: state.consumer.token,
                    },
                });
                if (response.data && response.data.renewToken) {
                    return { accessToken: response.data.renewToken };
                } else {
                    throw new Error('Failed to renew token');
                }
            } else {
                throw new Error('Not logged');
            }
        },
        handleFetch: (accessToken: string) => store.dispatch(setToken(accessToken)),
        handleResponse: (operation, accessTokenField) => (response: any) => {
            return { data: { access_token: response.accessToken } };
        },
        handleError: (err: Error) => {
            console.trace(err);
            store.dispatch(clearToken());
        },
    });

    const link = split(
        ({ query }) => {
            let md = getMainDefinition(query);
            if (md.kind == 'OperationDefinition') {
                return md.operation === 'subscription';
            } else {
                return false;
            }
        },
        wsLink,
        errorReadyAuthLink
    );

    let client: ApolloClient<NormalizedCacheObject>;
    client = new ApolloClient({
        link: ApolloLink.from([refreshLink, link]),
        cache: new InMemoryCache({
            typePolicies: {
                Profile: {
                    fields: {
                        opportunities: {
                            keyArgs: false,
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                    },
                },
                Query: {
                    fields: {
                        opportunities: {
                            // Don't cache separate results based on
                            // any of this field's arguments.
                            keyArgs: false,
                            // Concatenate the incoming list shops with
                            // the existing list shops.
                            merge(existing = [], incoming = []) {
                                let x = [...existing];
                                for (let el of incoming) {
                                    let insert = true;
                                    for (let el2 of existing) {
                                        if (el.__ref == el2.__ref) {
                                            insert = false;
                                        }
                                    }
                                    if (insert) {
                                        x.push(el);
                                    }
                                }
                                return x;
                            },
                        },
                        notifications: {
                            // Don't cache separate results based on
                            // any of this field's arguments.
                            keyArgs: false,
                            // Concatenate the incoming list shops with
                            // the existing list shops.
                            merge(existing = [], incoming = []) {
                                let x = [...existing];
                                for (let el of incoming) {
                                    let insert = true;
                                    for (let el2 of existing) {
                                        if (el.__ref == el2.__ref) {
                                            insert = false;
                                        }
                                    }
                                    if (insert) {
                                        x.push(el);
                                    }
                                }
                                return x;
                            },
                        },
                        places: {
                            // Concatenate the incoming list shops with
                            // the existing list shops.
                            keyArgs: false,
                            merge(existing = [], incoming = []) {
                                let ref_set = new Set();
                                let x = [...existing];

                                for (let el of existing) {
                                    ref_set.add(el.__ref);
                                }
                                for (let el of incoming) {
                                    if (!ref_set.has(el.__ref)) {
                                        x.push(el);
                                    }
                                }
                                return x;
                            },
                        },
                        favoritePlaces: {
                            // Concatenate the incoming list shops with
                            // the existing list shops.
                            keyArgs: false,
                            merge(existing = [], incoming = []) {
                                let x = [...existing];
                                for (let el of incoming) {
                                    let insert = true;
                                    for (let el2 of existing) {
                                        if (el.__ref == el2.__ref) {
                                            insert = false;
                                        }
                                    }
                                    if (insert) {
                                        x.push(el);
                                    }
                                }
                                return x;
                            },
                        },
                    },
                },
            },
        }),
    });
    
    const store = createStore(
        combineReducers({ consumer: ConsumerReducer, shop: ShopReducer }),
        hydration,
        applyMiddleware(thunk.withExtraArgument(client))
    );

    return {
        store,
        client,
    };
}

export const generateStore = genStoreAsync;
