import gql from 'graphql-tag';

export const START_TASK_MUTATION = gql`
    mutation(
        $taskId: ID!
        $opportunityId: ID!
        $surveyResponses: [SurveyResponseCreateInput]
        $purchaseAmount: Float
        $text: String
    ) {
        startTask(
            taskId: $taskId
            text: $text
            opportunityId: $opportunityId
            surveyResponses: $surveyResponses
            purchaseAmount: $purchaseAmount
        )
    }
`;
