const tintColorLight = '#8A84D7';
const tintColorDark = '#8A84D7';

export default {
    light: {
        text: '#000',
        background: '#fff',
        tint: tintColorLight,
        tabIconDefault: '#ccc',
        tabIconSelected: tintColorLight,
    },
    dark: {
        text: '#fffffc',
        background: '#36393f',
        secondary: '#7a4ca9',
        tint: tintColorDark,
        tabIconDefault: '#b9bbbc',
        tabIconSelected: tintColorDark,
    },
};