import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import AllOpportunities from '../screens/AllOpportunities';
import SavedOpportunities from '../screens/SavedOpportunities';
import CompletedOpportunities from '../screens/CompletedOpportunities';

export default function OpportunityTabs() {
    const OppTabs = createMaterialTopTabNavigator();

    return (
        <OppTabs.Navigator
            tabBarOptions={{
                activeTintColor: '#8A84D7',
                pressColor: '#8A84D7',
            }}
        >
            <OppTabs.Screen name="Active" component={AllOpportunities} />
            <OppTabs.Screen name="Favorites" component={SavedOpportunities} />
            <OppTabs.Screen name="Completed" component={CompletedOpportunities} />
        </OppTabs.Navigator>
    );
}
