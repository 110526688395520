import React, { useState, useEffect } from 'react';
import { Card } from 'react-native-elements';
import Hyperlink from 'react-native-hyperlink';
import { ActivityComment, Post } from '../../../store/types';
import { StyleSheet, View, Text, StyleProp, ViewStyle, Image, Dimensions } from 'react-native';
import { PostHeader } from './PostHeader';

const styles = StyleSheet.create({
    middle: { marginTop: 4, marginBottom: 8 },
    media: {
        height: 128,
        width: '100%',
    },
});

export const BusinessPost = (props: {
    post: Post;
    containerStyle?: StyleProp<ViewStyle>;
    OnOpenComments: (cs: ActivityComment[]) => void;
}) => {
    const { post, containerStyle, OnOpenComments } = props;
    const [imgHeight, setImgHeight] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    useEffect(() => {
        if (post.images && post.images.length > 0) {
            Image.getSize(
                post.images[0],
                (width, height) => {
                    const windowDim = Dimensions.get('window');
                    let w = 0;
                    let h = 0;
                    const ratio = width / height;
                    if (ratio < 1) {
                        // height larger than width
                        if (height > windowDim.height / 3) {
                            h = windowDim.height / 3;
                        } else {
                            h = height;
                        }
                        w = ratio * h;
                    } else {
                        // width larger than height
                        if (width > windowDim.width - 100) {
                            w = windowDim.width - 100;
                        } else {
                            w = width;
                        }
                        h = w / ratio;
                    }
                    setImgHeight(h);
                    setImgWidth(w);
                },
                () => {
                    // error getting image size
                }
            );
        }
    }, [imgHeight]);
    return (
        <Card containerStyle={containerStyle}>
            <PostHeader post={post} />
            <View style={styles.middle} onLayout={() => {}}>
                {post.images && post.images.length > 0 && (
                    <Image style={{ height: imgHeight, width: imgWidth }} source={{ uri: post.images[0] }} />
                )}
                <Hyperlink linkDefault={true}>
                    <Text>{post.body}</Text>
                </Hyperlink>
            </View>
            <Card.Divider />
        </Card>
    );
};
