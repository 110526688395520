import * as React from 'react';
import { GalleryEditor } from '../../components/Media/GalleryEditor';
import { AssetMutation, AssetQuery } from '../../components/Media/backend';
import { UploadResponse } from './UploadAssetScreen';
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import * as MediaLibrary from 'expo-media-library';
import { useEffect } from 'react';

export interface GalleryEditorScreenParams {
    query: AssetQuery<any>;
    mutation: AssetMutation<any>;
    uploadResponse?: UploadResponse;
    backScreenName: string;
    mediaType?: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue;
}

export interface GalleryEditorScreenProps {
    route: { params: GalleryEditorScreenParams };
    navigation: StackNavigationProp<any, any>;
}

export function GalleryEditorScreen(props: GalleryEditorScreenProps) {
    const { navigation } = props;
    const { params } = props.route;
    useEffect(() => {
        if (params.uploadResponse) {
            if (params.uploadResponse.kind == 'UploadResponseError') {
                navigation.navigate(params.backScreenName, {
                    params: {
                        uploadResponse: params.uploadResponse,
                    },
                    merge: true,
                });
            }
        }
    });

    return (
        <GalleryEditor
            {...params}
            onAdd={() => {
                navigation.navigate('Upload', {
                    mediaType: params.mediaType,
                    backScreenName: 'GalleryEditor',
                    mutation: params.mutation,
                });
            }}
            onReplace={(items) => {
                let variables =
                    params.mutation.variables != null && params.mutation.variables != undefined
                        ? params.mutation.variables
                        : {};
                variables[params.mutation.field] =
                    variables[params.mutation.field] != null && variables[params.mutation.field] != undefined
                        ? variables[params.mutation.field]
                        : {};
                variables[params.mutation.field]['delete'] =
                    variables[params.mutation.field]['delete'] != null &&
                    variables[params.mutation.field]['delete'] != undefined
                        ? variables[params.mutation.field]['delete'].concat(items)
                        : items;
                navigation.navigate('Upload', {
                    mediaType: params.mediaType,
                    backScreenName: 'GalleryEditor',
                    mutation: { ...params.mutation, variables: variables },
                });
            }}
        />
    );
}
