import * as React from 'react';
import {
    ActivityIndicator,
    Image,
    ImageBackground,
    ScrollView,
    StyleSheet
} from 'react-native';
import { Text, View } from '../components/Themed';
import { distanceFormat } from "./ShopsScreen";
import { IPlace } from "../store/types";
import { useMutation, useQuery } from "@apollo/client";
import { IOpportunity } from "../types";
import {
    OPPORTUNITIES_BY_TASK_LOCATION,
    OPPORTUNITIES_BY_REDEMTION_LOCATION,
    IS_TASK_LOCATION_IN_FAVORITES, IS_REDEMPTION_LOCATION_IN_FAVORITES
} from "../lib/queries";
import { OpportunityCard } from "../components/opportunities/OpportunityCard";
import Error from "../components/Error";
//@ts-ignore
import { OPPORTUNITY_CARD_LOGO } from '@env';
import { Button } from "react-native-elements";
import { FAVORITE_PLACES } from '../lib/queries';
import {
    SAVE_REDEMPTION_LOCATION,
    SAVE_TASK_LOCATION,
    UNSAVE_REDEMPTION_LOCATION,
    UNSAVE_TASK_LOCATION
} from "../lib/backend/oportunity_tab/queries";
import { useMemo } from "react";
import i18n from '../config/i18n'


let company_background =
    'https://res.cloudinary.com/govi/image/upload/v1613457672/biz/Screen_Shot_2021-02-15_at_11.40.46_PM_uuistl.png';
let company_logo = OPPORTUNITY_CARD_LOGO;

const ShopDetail = ({ route, navigation }: any) => {
    const shop: IPlace = route.params.shop;
    const { data, loading, error } = useQuery<{ opportunities: IOpportunity[] }, { locationId: string }>(
        shop.__typename == "TaskLocation" ? OPPORTUNITIES_BY_TASK_LOCATION : OPPORTUNITIES_BY_REDEMTION_LOCATION,
        {
            variables: { locationId: shop.id }
        });
    const [saveShop, { loading: saveShopLoading }] = useMutation(shop.__typename == "TaskLocation" ? SAVE_TASK_LOCATION : SAVE_REDEMPTION_LOCATION, {
        refetchQueries: [
            { query: FAVORITE_PLACES }
          ]
    });
    const [removeShop, { loading: removeShopLoading }] = useMutation(shop.__typename == "TaskLocation" ? UNSAVE_TASK_LOCATION : UNSAVE_REDEMPTION_LOCATION);
    const { data: inFavoritesData, loading: inFavoritesLoading, error: inFavoritesError } = useQuery<{ profile: { locations: { id: string }[] } }, { locationId: string }>(shop.__typename == "TaskLocation" ? IS_TASK_LOCATION_IN_FAVORITES : IS_REDEMPTION_LOCATION_IN_FAVORITES,
        {
            fetchPolicy:'no-cache',
            pollInterval: 1000,
            variables: {
                locationId: shop.id
            }
        });
    const locationOnBookmark = useMemo(() => {
        if (inFavoritesData) {
            return inFavoritesData.profile.locations.length == 1;
        }
        return false;
    }, [inFavoritesData]);

    const save = async (input_shop: IPlace) => {
        try {
            await saveShop({
                variables: {
                    locationId: input_shop.id,
                },
            });
        } catch (e) {
            console.error('Error[save opportunity to list]: ', e);
        }
    };
    const remove = async (input_shop: IPlace) => {
        await removeShop({
            variables: {
                locationId: input_shop.id,
            },
        });
    };
    return (
        <ScrollView style={{ height: '100%' }}>
            <ImageBackground source={{ uri: company_background }} style={styles.primaryImage}>
                <Image source={{ uri: company_logo }} style={styles.logo}/>
            </ImageBackground>
            <View style={{ padding: 5 }}>
                <Text style={styles.heading}>{i18n.t('address')}</Text>
                <Text>
                    {shop.street1} {shop.street2}
                </Text>
                <Text>
                    {shop.city}, {shop.state}
                </Text>
                {shop.distance > 0 && (
                    <Text style={{ paddingVertical: 5 }}>
                        {distanceFormat(shop.distance)}
                    </Text>
                )}
                <Text style={styles.heading}>{i18n.t('description')}</Text>
                <Text>{shop.description}</Text>
               
            </View>
            <View style={{ marginTop: 16, marginLeft: 8, marginRight: 8, marginBottom: 16 }}>
                <Button
                    disabled={saveShopLoading || removeShopLoading || inFavoritesLoading || !(!(inFavoritesError))}
                    onPress={async () => locationOnBookmark ? remove(shop) : save(shop)}
                    title={locationOnBookmark ? 'Remove from Favorites' : 'Add to Favorites'}
                    type="outline"
                />
            </View>
            <Text style={styles.rewardHeading}>{i18n.t('rewardsAtThisShop')}</Text>
            <View>{loading ? <ActivityIndicator size={'large'}/> : <>
                {error ? <Error/> : <>
                    {(data ? data.opportunities : []).map((el) => {
                        return <OpportunityCard key={el.id} cardStyle={{ margin: 8 }} parentNav={navigation}
                                                standalone={true} opportunity={el}/>
                    })}
                </>}
            </>}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    heading: {
        fontSize: 14,
        fontFamily: 'Arimo_700Bold',
        fontWeight: 'bold',
        marginVertical: 5,
    },
    rewardHeading: {
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Arimo_700Bold',
        fontWeight: 'bold',
        marginVertical: 5,
    },
    logo: {
        height: 100,
        width: 100,
    },
    primaryImage: {
        height: 150,
        width: '100%',
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export { ShopDetail };
