import React, { useMemo } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { Button, Card, Divider, Input, Text } from 'react-native-elements';
import { useForm, Controller } from 'react-hook-form';
import { TextInput } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { MultipleSelectPicker } from 'react-native-multi-select-picker';

enum TaskState {
    Accepted,
    Rejected,
    Waiting,
    Submitted,
    Started,
}
const sentiments = [
    {
        label: '🙁',
        value: 'very-dissatisfied',
    },
    { label: '😐', value: 'neutral' },
    {
        label: '😀',
        value: 'very-satisfied',
    },
];

const styles = StyleSheet.create({
    root: {
        padding: 5,
    },
    question: {
        margin: 5,
        padding: 5,
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
    sentimentButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    actionContainerStyle: {
        flexGrow: 1,
        padding: 10,
    },
    labelText: {
        color: '#5e5e5e',
        fontSize: 16,
    },
    labelResponse: {
        fontWeight: 'bold',
    },
    radioLabel: {
        color: '#5e5e5e',
        marginLeft: 10,
    },
    textInput: {},
});

const MultiChoicePicker = (props: any) => {
    const { options, onValueChange, selectedValue, curTaskState } = props;
    if ([TaskState.Waiting, TaskState.Rejected].includes(curTaskState)) {
        return (
            <Picker onValueChange={onValueChange} selectedValue={selectedValue}>
                {options.map((o: any) => (
                    <Picker.Item key={o} label={o} value={o} />
                ))}
            </Picker>
        );
    } else {
        //@ts-ignore
        return <TextInput disabled={true} value={selectedValue} />;
    }
};
const SentimentPicker = (props: any) => {
    const { onValueChange, selectedValue, curTaskState } = props;
    if ([TaskState.Waiting, TaskState.Rejected].includes(curTaskState)) {
        return (
            <Picker onValueChange={onValueChange} selectedValue={selectedValue}>
                {sentiments.map(({ label, value }) => (
                    <Picker.Item key={value} label={label} value={value} />
                ))}
            </Picker>
        );
    } else {
        //@ts-ignore
        return <TextInput disabled={true} value={selectedValue} />;
    }
};

const CheckboxPicker = (props: any) => {
    const { options, onValueChange, selectedValue, curTaskState } = props;
    if ([TaskState.Waiting, TaskState.Rejected].includes(curTaskState)) {
        const items = options.map((option: String, idx: Number) => ({
            label: option,
            value: idx,
        }));
        return (
            <MultipleSelectPicker
                onSelectionsChange={onValueChange}
                selectedItems={selectedValue}
                items={items}
            />
        );
    } else {
        //@ts-ignore
        return <TextInput disabled={true} value={selectedValue} />;
    }
};

const _getDefaultValues = (survey: any) => {
    let vals: any = {};
    survey.fields.forEach((field: any) => {
        if (field.type === 'MultiChoice') {
            vals[field.id] = field.options[0];
        }

        if (field.type === 'Sentiment') {
            vals[field.id] = sentiments[2].value;
        }
    });

    return vals;
};

const SurveyComponent = (props: any) => {
    const { survey, _submit, navigation, taskVerification } = props;
    const { handleSubmit, control, watch } = useForm({
        defaultValues: _getDefaultValues(survey),
        mode: 'onBlur',
    });

    const _getResponse = (fieldId: any) => {
        const fieldDef = survey.fields.find((f: any) => f.id === fieldId);
        const resObj = taskVerification.surveyResponses.find((r: any) => r.fieldId === fieldId);
        if (resObj) {
            let response = '';
            if (fieldDef.type === 'Checkbox') {
                // get indices from string
                const indices = resObj.response.split(',').map((i: String) => +i);
                response = fieldDef.options.filter((o: any, i: Number) => indices.includes(i)).join(', ');
            } else {
                response = resObj.response;
            }
            return response;
        } else {
            return null;
        }
    };
    const curTaskState = useMemo(() => {
        if (taskVerification.approved) {
            return TaskState.Accepted;
        } else if (taskVerification.rejected) {
            return TaskState.Rejected;
        } else if (taskVerification.submitted && taskVerification.surveyResponses.length !== 0) {
            return TaskState.Submitted;
        } else if (taskVerification.started && taskVerification.surveyResponses.length !== 0) {
            return TaskState.Started;
        } else {
            return TaskState.Waiting;
        }

        return TaskState.Waiting;
    }, [taskVerification]);

    if (curTaskState === TaskState.Submitted || curTaskState === TaskState.Started) {
        return (
            <ScrollView style={styles.root}>
                {survey.fields.map((field: any, idx: any) => (
                    <Card key={field.id} containerStyle={styles.question}>
                        <Text style={styles.labelText}>{`${idx + 1}. ${field.label}`}</Text>
                        <Divider style={styles.divider} />
                        <Text style={styles.labelResponse}>{_getResponse(field.id)}</Text>
                    </Card>
                ))}
                <View style={{ marginTop: 20, flex: 1, flexDirection: 'row' }}>
                    <Button
                        type="outline"
                        containerStyle={styles.actionContainerStyle}
                        title="Back"
                        onPress={() => {
                            navigation.goBack();
                        }}
                    />
                    <Button
                        containerStyle={styles.actionContainerStyle}
                        title="Submit"
                        disabled={![TaskState.Waiting, TaskState.Rejected].includes(curTaskState)}
                        onPress={handleSubmit((data) => {
                            _submit(data);
                        })}
                    />
                </View>
            </ScrollView>
        );
    }

    // @ts-ignore
    return (
        <ScrollView style={styles.root}>
            {survey.fields.map((field: any, idx: any) => (
                <Card key={field.id} containerStyle={styles.question}>
                    <Text style={styles.labelText}>{`${idx + 1}. ${field.label}`}</Text>
                    <Divider style={styles.divider} />
                    {field.type === 'MultiChoice' && (
                        //multiple choice stuff
                        <Controller
                            control={control}
                            name={field.id}
                            render={({ field: { onChange, value } }) => (
                                <MultiChoicePicker
                                    selectedValue={value}
                                    onValueChange={(value: any) => onChange(value)}
                                    options={field.options}
                                    curTaskState={curTaskState}
                                />
                            )}
                        />
                    )}
                    {field.type === 'Checkbox' && (
                        <Controller
                            control={control}
                            name={field.id}
                            render={({ field: { onChange, value } }) => (
                                <CheckboxPicker
                                    selectedValue={value}
                                    onValueChange={(values: Array<{ label: String; value: Number }>) => {
                                        onChange(values);
                                    }}
                                    options={field.options}
                                    curTaskState={curTaskState}
                                />
                            )}
                        />
                    )}
                    {field.type === 'TextInput' && (
                        <Controller
                            control={control}
                            name={field.id}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                    style={styles.textInput}
                                    placeholder={field.placeholder || ''}
                                    onBlur={onBlur}
                                    onChangeText={(value) => onChange(value)}
                                    value={value}
                                    disabled={![TaskState.Waiting, TaskState.Rejected].includes(curTaskState)}
                                    returnKeyType="done"
                                    blurOnSubmit={true}
                                />
                            )}
                        />
                    )}
                    {field.type === 'TextArea' && (
                        <Controller
                            control={control}
                            name={field.id}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                    style={styles.textInput}
                                    multiline
                                    placeholder={field.placeholder || ''}
                                    onBlur={onBlur}
                                    onChangeText={(value) => onChange(value)}
                                    value={value}
                                    //@ts-ignore
                                    disabled={![TaskState.Waiting, TaskState.Rejected].includes(curTaskState)}
                                    returnKeyType="done"
                                    blurOnSubmit={true}
                                />
                            )}
                        />
                    )}
                    {field.type === 'Sentiment' && (
                        // need to manually handle change fml
                        <Controller
                            control={control}
                            name={field.id}
                            render={({ field: { onChange, value } }) => (
                                <SentimentPicker
                                    selectedValue={value}
                                    curTaskState={curTaskState}
                                    onValueChange={(value: any) => onChange(value)}
                                />
                            )}
                        />
                    )}
                </Card>
            ))}
            <View style={{ marginTop: 20, flex: 1, flexDirection: 'row' }}>
                <Button
                    type="outline"
                    containerStyle={styles.actionContainerStyle}
                    title="Cancel"
                    onPress={() => {
                        navigation.goBack();
                    }}
                />
                <Button
                    containerStyle={styles.actionContainerStyle}
                    title="Submit"
                    disabled={![TaskState.Waiting, TaskState.Rejected].includes(curTaskState)}
                    onPress={handleSubmit((data) => {
                        _submit(data);
                    })}
                />
            </View>
        </ScrollView>
    );
};

export { SurveyComponent };
