import React, { useMemo, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import cloudinary from 'cloudinary-core';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { COUPON, SHOPS } from '../../lib/queries';
import { useMutation, useQuery } from '@apollo/client';
import { ErrorText } from '../StyledText';
import RedemptionLocation from './RedemptionLocation';
import DisplayRedemptionLocation from './components/DisplayRedemptionLocation';
import { formatExpirationDate, makeManualActions, redemptionOrderByDateDesc, getRankedSku } from './utils';
import { ICoordinates, ICoupon, IRedemption } from '../../store/types';
import Error from '../Error';
import RedemptionQRCode from './RedemptionQRCode';
import { RedemptionModal } from './RedemptionModal';
import RewardShopHeader from './RewardShopHeader';
import { APPROVE_REDEMPTION, CANCEL_REDEMPTION, CREATE_REDEMPTION } from '../../lib/mutations';
import { IRewardType } from '../../types';
import { useSelector } from 'react-redux';
import i18n from '../../config/i18n';
// @ts-ignore
const cl = new cloudinary.Cloudinary({ cloud_name: 'govi', secure: true });

const styles = StyleSheet.create({
    bizBackground: {
        height: 140,
    },
    transparentfifty: {
        opacity: 0.5,
    },
    headerContainer: {
        flexDirection: 'row',
    },
    title: {
        marginTop: 35,
        textShadowColor: 'black',
        textShadowRadius: 4,
        textShadowOffset: { width: 3, height: 4.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: 'white',
    },
    redeemedTitle: {
        marginTop: 90,
        textShadowColor: 'black',
        textShadowOffset: { width: 1.0, height: 1.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: '#CECECE',
    },
    shopInfo: {
        marginTop: 10,
        marginLeft: 20,
    },
    shopInfoDigital: {
        marginTop: 10,
        marginLeft: 20,
        marginBottom: 20,
    },
    shopName: {
        color: 'white',
        fontFamily: 'Arimo_700Bold',
        fontSize: 16,
        marginBottom: 5,
    },
    shopAddress: {
        color: 'white',
        marginBottom: 5,
        marginTop: 0,
    },
    bizLogo: {
        height: 50,
        marginLeft: 20,
        marginTop: 20,
        width: 50,
    },
    btnPending: {
        backgroundColor: '#fadf92',
    },
    btnSolid: {
        backgroundColor: '#8A84D7',
    },
    btnContainer: {
        padding: 10,
    },
    buttonOutlineStyle: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    disclaimer: {
        color: 'white',
        marginTop: 10,
        padding: 20,
        textAlign: 'left',
    },
    disclaimerHeader: {
        color: 'white',
        marginTop: 10,
        textAlign: 'center',
    },
    goBack: {},
    hidden: {
        display: 'none',
    },
    idHeader: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    idText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    instructions: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        padding: 20,
    },
    loading: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    overlay: {
        textAlign: 'center',
        marginTop: -120,
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 45,
        color: '#ffffff',
    },
    locationText: {
        backgroundColor: '#f6c6c5',
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    manualApprove: {
        color: 'gray',
        fontFamily: 'Arimo_400Regular',
        fontSize: 16,
        textAlign: 'center',
    },
    modal: {
        backgroundColor: 'white',
        height: 300,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 100,
    },
    modalBtn: {
        backgroundColor: '#8A84D7',
    },
    modalBtnCancel: {
        backgroundColor: '#8A84D7',
    },
    modalBtnContainer: {},
    modalHeader: {
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        marginTop: 20,
        textAlign: 'center',
    },
    qrWrapper: {
        alignItems: 'center',
        flex: 1,
    },
    rejectedHeadline: {
        backgroundColor: '#f6c6c5',
        color: 'red',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        paddingTop: 10,
        textAlign: 'center',
    },
    rejectedText: {
        backgroundColor: '#f6c6c5',
        color: 'black',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    root: {
        backgroundColor: '#5a5e5c',
    },
    sku: {
        height: 100,
        width: 200,
    },
    skuContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 100,
        margin: 10,
        justifyContent: 'space-around',
        textAlign: 'center',
    },
    tosBody: {
        flex: 1,
        padding: 20,
    },
    shopNameDigital: {
        fontFamily: 'Arimo_700Bold',
        fontSize: 20,
        color: 'white',
        marginTop: 22,
        marginBottom: 10,
    },
    expiredOverlay: {
        textAlign: 'center',
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 45,
        color: '#ffffff',
        transform: [{ translateY: 30 }, { rotate: '15deg' }],
    },
});

function getRewardType(c: ICoupon): IRewardType {
    switch (c.couponType) {
        case 'Custom':
            return 'CouponCustom';
        case 'Fixed':
            return 'CouponFixed';
        case 'Percentage':
            return 'CouponPercentage';
    }
}

const CouponDetail = (props: { couponId: string }) => {
    const { couponId } = props;
    let expires = '';
    let overlay = '';
    const [modalVisible, setModalVisible] = useState(false);
    const currentUser = useSelector((state: any) => {
        return state.consumer.user;
    });

    const { data: rewardData, loading: rewardLoading, error: rewardError, refetch: refetchCoupon } = useQuery<
        { coupon: ICoupon },
        { couponId: string }
    >(COUPON, {
        variables: {
            couponId,
        },
        pollInterval: 5000,
    });
    const is_expired: boolean = useMemo(() => {
        if (rewardData && rewardData.coupon && rewardData.coupon.expires) {
            return new Date(rewardData.coupon.expires) <= new Date();
        }
        return false;
    }, [rewardData]);
    //return the last redemption that is not canceled, rejected, only if there is not approved redemption
    const pendingRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.coupon) {
            const coupon = rewardData.coupon;
            if (coupon.redemptions.filter((r) => r.approved).length != 0) {
                return null;
            } else {
                return coupon.redemptions
                    .filter((r) => !(r.canceled || r.rejected))
                    .sort(redemptionOrderByDateDesc)[0];
            }
        } else {
            return null;
        }
    }, [rewardData]);

    const approvedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.coupon) {
            const coupon = rewardData.coupon;
            const approved = coupon.redemptions.filter((r) => r.approved);
            if (approved.length != 0) {
                return approved.concat().sort(redemptionOrderByDateDesc)[0];
            }
        }
        return null;
    }, [rewardData]);
    const is_approved = useMemo(() => {
        if (approvedRedemption) {
            return true;
        }
        if (rewardData && rewardData.coupon && rewardData.coupon.redeemed) {
            return rewardData.coupon.redeemed;
        }
        return false;
    }, [approvedRedemption, rewardData]);
    const rejectedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.coupon && rewardData.coupon.redemptions.length !== 0) {
            const last = rewardData.coupon.redemptions.concat().sort(redemptionOrderByDateDesc)[0];
            if (last.rejected) {
                return last;
            }
        }
        return null;
    }, [rewardData]);
    const is_rejected = useMemo(() => {
        return !!rejectedRedemption;
    }, [rejectedRedemption]);
    const is_pending = useMemo(() => {
        if (is_expired) return false;
        if (is_approved) return false;
        if (pendingRedemption) {
            return true;
        }
        return false;
    }, [pendingRedemption, rewardData, is_approved, is_expired]);
    const can_make_redemption = useMemo(() => {
        return !is_expired && !is_approved && !is_pending;
    }, [is_approved, is_expired, is_pending]);

    const { data: shopsData, loading: shopsLoading, error: shopsError } = useQuery(SHOPS);
    const [createRedemption, { loading: createRedemptionLoading, error: createRedemptionError }] = useMutation<
        { createRedemption: IRedemption },
        {
            rewardId: string;
            rewardType: IRewardType;
            locationId?: string;
        }
    >(CREATE_REDEMPTION, { onCompleted: () => refetchCoupon() });
    const [
        approveRedemption,
        { loading: approveRedemptionLoading, error: approveRedemptionError },
    ] = useMutation<
        { approveRedemption: IRedemption },
        {
            redemptionId: string;
            data?: {
                coordinates?: ICoordinates;
                locationId?: string;
            };
        }
    >(APPROVE_REDEMPTION, { onCompleted: () => refetchCoupon() });
    const [cancelRedemption, { loading: cancelRedemptionLoading, error: cancelRedemptionError }] = useMutation<
        { cancelRedemption: IRedemption },
        {
            redemptionId: string;
        }
    >(CANCEL_REDEMPTION, { onCompleted: () => refetchCoupon() });

    const { showActionSheetWithOptions } = useActionSheet();

    if (rewardError) {
        console.error(rewardError);
        return <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>;
    }

    if (rewardLoading) {
        return <ActivityIndicator color="#7a4ca9" />;
    }
    const coupon = rewardData && rewardData.coupon;
    const redeemAction = () => {
        if (coupon && can_make_redemption) {
            if (
                coupon.prototype &&
                coupon.prototype.redemptionLocations &&
                coupon.prototype.redemptionLocations.length > 0
            ) {
                setModalVisible(true);
            } else {
                createRedemption({
                    variables: {
                        rewardId: coupon.id,
                        rewardType: getRewardType(coupon),
                    },
                }).catch((e) => console.error(e));
            }
        }
    };

    const confirmRedemption = () => {
        if (pendingRedemption && !is_expired) {
            approveRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };

    const cancelRedemptionAction = () => {
        if (pendingRedemption) {
            cancelRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };

    const manualActions = makeManualActions(
        { cancelRedemption: cancelRedemptionAction, confirmRedemption },
        showActionSheetWithOptions
    );

    const _getCouponTitle = () => {
        if (coupon) {
            if (coupon.couponType === 'Percentage' && coupon.percentage != null) {
                return coupon.percentage + '% Off';
            }
            if (coupon.couponType === 'Fixed' && coupon.amount != null) {
                return '$' + coupon.amount + ' Off';
            }
            if (coupon.couponType === 'Custom' && coupon.description != null) {
                return coupon.description;
            }
            return 'No Value';
        } else {
            return '';
        }
    };

    const _titleStyle = () => {
        if (coupon && (coupon.redeemed || coupon.pendingApproval)) {
            return styles.redeemedTitle;
        } else if (is_expired) {
            return [styles.title, styles.transparentfifty];
        } else {
            return styles.title;
        }
    };
    let redeemTitle = 'Redeem';
    if (coupon) {
        if (coupon.expires) {
            if (is_expired) {
                expires = 'Expired ' + formatExpirationDate(coupon.expires) + '. ';
            } else {
                expires = 'Expires ' + formatExpirationDate(coupon.expires) + '. ';
            }
        }

        if (is_approved) {
            redeemTitle = 'Redeemed';
            overlay = 'REDEEMED';
            expires = 'Redeemed on ' + formatExpirationDate(coupon.redeemedAt);
        } else if (is_pending) {
            redeemTitle = 'Pending';
            overlay = 'Pending';
        } else if (is_rejected) {
            redeemTitle = 'Re-Attempt Redemption';
        }
    }
    const loading =
        createRedemptionLoading || cancelRedemptionLoading || approveRedemptionLoading || rewardLoading;
    if (loading) {
        redeemTitle = 'Loading';
    }

    const _getBackground = (src: String) => {
        if (coupon) {
            if (!src) {
                return '';
            }
            const imageId = src.substr(src.indexOf('upload') + 7);
            const brightness = coupon.redeemed || coupon.pendingApproval ? '-80' : '-30';
            const tag = cl.url(imageId, {
                transformation: [{ effect: 'brightness:' + brightness }, { height: 300, crop: 'fill' }],
            });

            return tag.toString();
        } else {
            return null;
        }
    };

    const getRedeemBtnStyle = () => {
        if (is_pending) {
            return styles.btnPending;
        }
        return styles.btnSolid;
    };

    if (shopsLoading) {
        return (
            <View style={styles.root}>
                <ActivityIndicator color="#7a4ca9" />
            </View>
        );
    }

    if (shopsError) {
        console.error(shopsError);
        return (
            <View style={styles.root}>
                <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>
            </View>
        );
    }

    function cancelSelectRedemptionLocation() {
        setModalVisible(false);
    }

    const redeemWithLocation = (rid: string) => {
        if (coupon) {
            setModalVisible(false);
            createRedemption({
                variables: {
                    rewardType: getRewardType(coupon),
                    rewardId: coupon.id,
                    locationId: rid,
                },
            }).catch((e) => console.error(e));
        }
    };

    const shops = shopsData.businessLocations;
    if (rewardLoading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    } else {
        if (coupon) {
            return (
                <ScrollView>
                    <View style={styles.root}>
                        <RedemptionModal
                            locations={
                                coupon.prototype && coupon.prototype.redemptionLocations
                                    ? coupon.prototype.redemptionLocations
                                    : []
                            }
                            visible={modalVisible}
                            onCancel={cancelSelectRedemptionLocation}
                            onRedeem={redeemWithLocation}
                        />
                        <RewardShopHeader reward={coupon} shops={shops} />
                        {coupon.shop && coupon.shop.primaryImage ? (
                            <ImageBackground
                                style={styles.bizBackground}
                                imageStyle={[is_expired && styles.transparentfifty]}
                                key={coupon.id}
                                source={{
                                    uri: _getBackground(coupon.shop.primaryImage),
                                }}
                            >
                                {is_expired && <Text style={styles.expiredOverlay}>EXPIRED</Text>}
                                <Text style={_titleStyle()}>{_getCouponTitle()}</Text>
                                <Text style={styles.overlay}>{overlay}</Text>
                            </ImageBackground>
                        ) : (
                            <></>
                        )}
                        <Text style={styles.instructions}>
                            {coupon.description} &nbsp;
                            {!!expires && '' + expires} {i18n.t('rewardShowStaff')}
                        </Text>
                        {is_rejected && (
                            <>
                                <Text style={styles.rejectedHeadline}>{i18n.t('redemptionRejected')}</Text>
                                <Text style={styles.rejectedText}>
                                    {i18n.t('redemptionRejectReason')}
                                    {rejectedRedemption ? rejectedRedemption.rejectionReason : ''}
                                </Text>
                            </>
                        )}
                        {coupon.redeemed &&
                            coupon.prototype &&
                            (coupon.prototype.skuImage || coupon.prototype.skuImageBronze) && (
                                <View style={styles.skuContainer}>
                                    <Image
                                        style={styles.sku}
                                        resizeMode={'contain'}
                                        source={{
                                            uri: getRankedSku(coupon, currentUser),
                                        }}
                                    />
                                </View>
                            )}

                        {pendingRedemption && (
                            <RedemptionQRCode
                                loading={loading}
                                error={createRedemptionError}
                                data={{ redemptions: [pendingRedemption] }}
                            />
                        )}

                        <Button
                            containerStyle={styles.btnContainer}
                            buttonStyle={getRedeemBtnStyle()}
                            disabled={loading || is_pending || is_approved || is_expired}
                            title={redeemTitle}
                            onPress={() => redeemAction()}
                        />
                        <Button
                            containerStyle={styles.btnContainer}
                            disabled={!is_pending || loading}
                            title={'Manual Actions'}
                            onPress={() => manualActions()}
                        />
                        <Text style={styles.idHeader}>{i18n.t('transactionId')}</Text>
                        <Text style={styles.idText}>{coupon.id}</Text>
                        {coupon.redemptionLocation && <RedemptionLocation reward={coupon}></RedemptionLocation>}
                        {approvedRedemption && approvedRedemption.redemptionLocation && (
                            <DisplayRedemptionLocation
                                approvedRedemption={approvedRedemption}
                            ></DisplayRedemptionLocation>
                        )}
                        {coupon.restriction && (
                            <Text style={styles.disclaimerHeader}>
                                {i18n.t('coupon')}
                                {i18n.t('tc')}
                            </Text>
                        )}
                        <Text style={styles.disclaimer}>{coupon.restriction || ''}</Text>
                    </View>
                </ScrollView>
            );
        } else {
            return <Error />;
        }
    }
};
CouponDetail.navigationOptions = {
    title: 'Rewards',
    headerTintColor: '#212322',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { CouponDetail };
