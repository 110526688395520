import gql from 'graphql-tag';

export const GET_TASK_VERIFICATION = gql`
    query($taskVerificationId: ID!) {
        taskVerification(where: { id: $taskVerificationId }) {
            id
            link
            lat
            long
            purchaseAmount
            task {
                id
                type
                repeats
                image
                images
                link
                geo
                minPurchase
                repeatLimit
                description
                instructions
                survey {
                    id
                    fields {
                        id
                        maxChars
                        label
                        type
                        options
                        placeholder
                    }
                }
                verificationType
            }
            text
            assets {
                id
                uri
                mediaType
                mediaSubtypes
                width
                height
                duration
                exif
                caption
                createdAt
                updatedAt
            }
            started
            submitted
            approved
            reviewed
            submittedAt
            startedAt
            approvedAt
            createdAt
            rejected
            rejectedReason
            surveyResponses {
                id
                fieldId
                response
            }
            surveyResponses {
                id
                fieldId
                response
            }
        }
    }
`;

export const GET_WALLET = gql`
    query {
        wallet(where: {}) {
            id
            goviCash
            updatedAt
            coupons {
                id
                expires
                rejected
                rejectedText
                description
                restriction
                pendingApproval
                redeemed
                redeemedAt
                amount
                percentage
                couponType
                currency
                createdAt
                shop {
                    name
                    id
                    primaryImage
                    logo
                }
            }
            vouchers {
                id
                description
                amount
                pendingApproval
                restriction
                rejected
                rejectedText
                currency
                redeemed
                redeemedAt
                createdAt
                shop {
                    name
                    id
                    primaryImage
                    logo
                }
                expires
                text
            }
            boostTokens {
                id
                redeemed
                redemptionDate
            }
            storeCredits {
                id
                expires
                description
                restriction
                pendingApproval
                rejected
                rejectedText
                redeemed
                redeemedAt
                currency
                amount
                createdAt
                shop {
                    name
                    id
                    primaryImage
                    logo
                }
            }
            cashTransactions {
                amount
                recipientId
                senderId
                recipientType
                senderType
                createdAt
            }
        }
    }
`;

export const CURRENT_OPPORTUNITIES = gql`
    query($currentDate: DateTime!) {
        opportunities(where: { startDate_lte: $currentDate, endDate_gte: $currentDate }) {
            id
            endDate
            startDate
            perpetual
            reward {
                id
                high
                low
                type
                boostToken
                tiers
                voucherTiers
                variable
                minPurchase
                percentage
            }
            detailedDescription
            oneLineDescription
            tasks {
                instructions
                verificationType
                id
                type
                description
            }
            shop {
                id
                name
                primaryImage
                logo
            }
        }
    }
`;

export const CURRENT_OPPORTUNITIES_BY_SHOP = gql`
    query($currentDate: DateTime!, $shopId: ID!) {
        opportunities(where: { endDate_gt: $currentDate, startDate_lt: $currentDate, shop: { id: $shopId } }) {
            id
            endDate
            startDate
            perpetual
            reward {
                id
                type
                high
                low
                boostToken
                tiers
                voucherTiers
                variable
                minPurchase
                percentage
            }
            detailedDescription
            oneLineDescription
            tasks {
                instructions
                verificationType
                id
                type
                description
            }
            shop {
                id
                name
                primaryImage
                logo
            }
        }
    }
`;

export const PROFILE = gql`
    query {
        profile(where: { id: "fakeid" }) {
            opportunities {
                id
            }
        }
    }
`;

export const GET_COMPLETED_OPPORTUNITY_CLAIMS = gql`
    query {
        opportunityClaims(where: { completed: true }) {
            id
            submitted
            opportunity {
                id
            }
            completed
            submitted
        }
    }
`;
export const GET_COMPLETED_CLAIMS_PER_OPPORTUNITY = gql`
    query($id: ID!) {
        opportunityClaims(where: { completed: true, opportunity: { id: $id } }) {
            id
            submitted
            completed
            submitted
        }
    }
`;

export const GET_OPPORTUNITY_CLAIM = gql`
    query($opportunityId: ID!) {
        opportunityClaims(where: { opportunity: { id: $opportunityId } }) {
            id
            submitted
            verifications {
                id
                link
                lat
                long
                purchaseAmount
                task {
                    id
                    type
                    image
                    images
                    link
                    geo
                    repeats
                    minPurchase
                    repeatLimit
                    description
                    instructions
                    survey {
                        id
                        fields {
                            id
                            maxChars
                            label
                            type
                            options
                            placeholder
                        }
                    }
                    verificationType
                }
                text
                assets {
                    id
                    uri
                    mediaType
                    mediaSubtypes
                    width
                    height
                    duration
                    exif
                    caption
                    createdAt
                    updatedAt
                }
                started
                submitted
                approved
                reviewed
                submittedAt
                startedAt
                approvedAt
                createdAt
                rejected
                rejectedReason
                surveyResponses {
                    id
                    fieldId
                    response
                }
                surveyResponses {
                    id
                    fieldId
                    response
                }
            }
            completed
            submitted
        }
    }
`;

export const GET_ACTIVITIES_BY_TAG = gql`
    query($tagId: ID!) {
        activities(where: { tag: { id: $tagId } }) {
            id
            reviewScore
            header
            createdAt
            newRank
            id
            tag {
                id
                name
                primaryImage
                logo
            }
            poster {
                about
                username
                profilePicture
            }
            images
            type
            body
        }
    }
`;

export const GET_ACTIVITIES = gql`
    query {
        activities(where: {}) {
            id
            reviewScore
            header
            createdAt
            newRank
            id
            tag {
                id
                name
                primaryImage
                logo
            }
            poster {
                about
                username
                profilePicture
            }
            images
            type
            body
        }
    }
`;

export const GET_TASK = gql`
    query($taskId: ID!) {
        task(where: { id: $taskId }) {
            id
            type
            image
            images
            video
            link
            geo
            description
            survey {
                id
                fields {
                    id
                    maxChars
                    label
                    type
                    options
                    placeholder
                }
            }
            verificationType
        }
    }
`;

export const SHOPS = gql`
    query {
        businessLocations {
            id
            description
            categories
            lat
            long
            primaryImage
            name
            logo
            address {
                city
                state
                street1
                street2
                postalCode
            }
        }
    }
`;

export const TASK_LOCATIONS = gql`
    query($location: LocationInput!, $shop_id: ID!, $first: Int!, $after: String) {
        taskLocations(
            where: { distanceStartingPoint: $location, shop: { id: $shop_id } }
            first: $first
            after: $after
        ) {
            id
            description
            name
            street1
            street2
            city
            state
            postalCode
            country
            phone
            lat
            long
            email
            meta
            website
            distance
        }
    }
`;

export const PLACES = gql`
    query PLACES($location: LocationInput!, $shop_id: ID!, $opportunity_id: ID, $first: Int!, $after: String) {
        places(
            where: {
                distanceStartingPoint: $location
                shop: { id: $shop_id }
                opportunity: { id: $opportunity_id }
            }
            first: $first
            after: $after
        ) {
            __typename
            ... on TaskLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
            ... on RedemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
        }
    }
`;

export const FAVORITE_PLACES = gql`
    query FAVORITE_PLACES($location: LocationInput!, $first: Int!, $after: String) {
        favoritePlaces(where: { distanceStartingPoint: $location }, first: $first, after: $after) {
            __typename
            ... on TaskLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
            ... on RedemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
        }
    }
`;

export const OPP_PLACES = gql`
    query PLACES($location: LocationInput!, $shop_id: ID!, $opportunity_id: ID, $first: Int!, $after: String) {
        places(
            where: {
                distanceStartingPoint: $location
                shop: { id: $shop_id }
                opportunity: { id: $opportunity_id }
            }
            first: $first
            after: $after
        ) {
            __typename
            ... on TaskLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
            ... on RedemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
                distance
            }
        }
    }
`;

export const OPPORTUNITIES_BY_TASK_LOCATION = gql`
    query($locationId: ID!) {
        opportunities(where: { opportunityLocations_some: { id: $locationId }, active: true }) {
            id
            endDate
            tasks {
                id
                description
                instructions
                verificationType
            }
            reward {
                id
                type
                type
                variable
                low
                high
                voucherTiers
                tiers
                percentage
                minPurchase
            }
            oneLineDescription
            detailedDescription
	    perpetual
        }
    }
`;
export const OPPORTUNITIES_BY_REDEMTION_LOCATION = gql`
    query($locationId: ID!) {
        opportunities(where: { reward: { redemptionLocations_some: { id: $locationId } }, active: true }) {
            id
            endDate
            tasks {
                id
                description
                instructions
                verificationType
            }
            reward {
                id
                type
                type
                variable
                low
                high
                voucherTiers
                tiers
                percentage
                minPurchase
            }
            oneLineDescription
            detailedDescription
	    perpetual
        }
    }
`;

export const VOUCHER = gql`
    query($voucherID: ID) {
        voucher(where: { id: $voucherID }) {
            id
            text
            expires
            rejected
            rejectedText
            description
            restriction
            pendingApproval
            redeemed
            redeemedAt
            amount
            currency
            createdAt
            shop {
                name
                id
                primaryImage
                logo
            }
            redemptions {
                id
                shopId
                owner {
                    id
                    username
                    firstName
                    lastName
                    rank
                }
                approvedByManual
                employee {
                    firstName
                    lastName
                    username
                }
                redemptionLocation {
                    id
                    description
                    name
                    street1
                    street2
                    city
                    state
                    postalCode
                    country
                    phone
                    lat
                    long
                    email
                }
                rewardType
                rewardId
                text
                createdAt
                updatedAt
                rejected
                approved
                canceled
                rejectionReason
                approvedAt
                rejectedAt
                canceledAt
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
            }
            prototype {
                id
                skuImage
                redemptionLocations {
                    id
                    description
                    name
                    street1
                    street2
                    city
                    state
                    postalCode
                    country
                    phone
                    lat
                    long
                    email
                    meta
                    website
                }
            }
        }
    }
`;

export const STORE_CREDIT_BY_ID = gql`
    query GetStoreCreditById($storeCreditID: ID!) {
        storeCredit(where: { id: $storeCreditID }) {
            id
            expires
            rejected
            rejectedText
            description
            restriction
            meta
            pendingApproval
            redeemed
            redeemedAt
            amount
            currency
            createdAt
            shop {
                name
                id
                primaryImage
                logo
            }
            redemptions {
                id
                shopId
                owner {
                    id
                    username
                    firstName
                    lastName
                    rank
                }
                approvedByManual
                employee {
                    firstName
                    lastName
                    username
                }
                redemptionLocation {
                    id
                    description
                    name
                    street1
                    street2
                    city
                    state
                    postalCode
                    country
                    phone
                    lat
                    long
                    email
                }
                rewardType
                rewardId
                text
                createdAt
                updatedAt
                rejected
                approved
                canceled
                rejectionReason
                approvedAt
                rejectedAt
                canceledAt
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
            }
        }
    }
`;

export const COUPON = gql`
    query($couponId: ID) {
        coupon(where: { id: $couponId }) {
            id
            expires
            rejected
            rejectedText
            description
            restriction
            pendingApproval
            redeemed
            redeemedAt
            amount
            percentage
            couponType
            createdAt
            currency
            redemptions {
                id
                shopId
                owner {
                    id
                    username
                    firstName
                    lastName
                    rank
                }
                approvedByManual
                employee {
                    firstName
                    lastName
                    username
                }
                redemptionLocation {
                    id
                    description
                    name
                    street1
                    street2
                    city
                    state
                    postalCode
                    country
                    phone
                    lat
                    long
                    email
                }
                rewardType
                rewardId
                text
                createdAt
                updatedAt
                rejected
                approved
                canceled
                rejectionReason
                approvedAt
                rejectedAt
                canceledAt
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
                meta
                website
            }
            shop {
                name
                id
                primaryImage
                logo
            }
            prototype {
                id
                skuImage
                redemptionLocations {
                    id
                    description
                    name
                    street1
                    street2
                    city
                    state
                    postalCode
                    country
                    phone
                    lat
                    long
                    email
                    meta
                    website
                }
            }
        }
    }
`;

export const GET_REDEMPTIONS = gql`
    query($rewardId: ID!) {
        redemptions(where: { rewardId: $rewardId }, orderBy: createdAt_DESC, first: 1) {
            id
            rewardType
        }
    }
`;

export const GET_RTTOKEN = gql`
    query {
        rtToken
    }
`;

export const GET_CLAIM = gql`
    query($claimId: ID!) {
        opportunityClaim(where: { id: $claimId }) {
            id
            verifications {
                id
                link
                lat
                long
                purchaseAmount
                task {
                    id
                    type
                    repeats
                    image
                    images
                    video
                    link
                    geo
                    minPurchase
                    repeatLimit
                    description
                    instructions
                    survey {
                        id
                        fields {
                            id
                            maxChars
                            label
                            type
                            options
                            placeholder
                        }
                    }
                    verificationType
                }
                text
                assets {
                    id
                    uri
                    mediaType
                    mediaSubtypes
                    width
                    height
                    duration
                    exif
                    caption
                    createdAt
                    updatedAt
                }
                started
                submitted
                approved
                reviewed
                submittedAt
                startedAt
                approvedAt
                createdAt
                rejected
                rejectedReason
                surveyResponses {
                    id
                    fieldId
                    response
                }
            }
            completed
            submitted
        }
    }
`;

export const IS_TASK_LOCATION_IN_FAVORITES = gql`
    query IS_TASK_LOCATION_IN_FAVORITES($locationId: ID!) {
        profile(where: {}) {
            id
            locations: taskLocations(where: { id: $locationId }) {
                id
            }
        }
    }
`;

export const IS_REDEMPTION_LOCATION_IN_FAVORITES = gql`
    query IS_REDEMPTION_LOCATION_IN_FAVORITES($locationId: ID!) {
        profile(where: {}) {
            id
            locations: redemptionLocations(where: { id: $locationId }) {
                id
            }
        }
    }
`;

export const GET_OPPORTUNITY_BY_TASK = gql`
    query($taskId: ID!) {
        taskVerification(where: { id: $taskId }) {
            task {
                id
                instructions
                opportunity {
                    id
                    name
                    image
                    startDate
                    endDate
                    detailedDescription
                    oneLineDescription
                    perpetual
                    shop {
                        logo
                    }
                }
            }
            opportunityClaim {
                verifications {
                    id
                    task {
                        id
                    }
                }
            }
            started
            submitted
            approved
            reviewed
            rejected
        }
    }
`;
