import React, { useState } from 'react';
import { ScrollView, View, StyleSheet, Text, ActivityIndicator, I18nManager } from 'react-native';
import { DigitalVerify } from './DigitalVerify';
import { Divider, Button } from 'react-native-elements';
import { GET_CLAIM } from '../../lib/queries';
import { START_TASK_MUTATION } from '../../lib/mutations';
import { TASK_APPROVED } from '../../lib/subs';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { StackScreenProps } from '@react-navigation/stack';
import { OpportunityStackParams } from '../../types';
import { ITaskVerification } from '../OpportunityDetail';
import i18n from '../../config/i18n';

const styles = StyleSheet.create({
    root: {
        flex: 1,
        padding: 5,
    },
    header: {
        flex: 1,
        padding: 5,
        backgroundColor: '#fff',
    },
    instructions: {
        padding: 20,
    },
    instructionHeader: {
        fontFamily: 'Lato-Medium',
        fontSize: 22,
        color: 'grey',
    },
    instructionText: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: 'grey',
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        fontFamily: 'Lato',
    },
    rejectionText: {
        color: 'red',
    },
});
type ManualDigitalProp = StackScreenProps<OpportunityStackParams, 'ManualDigital'>;

interface ISubmitButtonProps {
    taskVerification: ITaskVerification;
    onPress: () => void;
    saved: Boolean;
}

function SubmitButton({ taskVerification, onPress, saved }: ISubmitButtonProps) {
    if (taskVerification.task.link) {
        if (!taskVerification.link) {
            return null;
        }
    }
    if (taskVerification.task.image || taskVerification.task.images) {
        if (!taskVerification.assets || taskVerification.assets.length == 0 || !saved) {
            return null;
        }
    }
    if (taskVerification.task.geo) {
        if (!taskVerification.lat || !taskVerification.long) {
            return null;
        }
    }
    if (taskVerification.task.survey) {
        if (!taskVerification.surveyResponses || taskVerification.surveyResponses.length == 0) {
            return null;
        }
    }
    return <Button title="Request Verification" onPress={onPress} />;
}

const ManualDigital = (props: ManualDigitalProp) => {
    const { route, navigation } = props;
    const client = useApolloClient();
    const { opportunity, taskId, task, claim: existingClaim } = route.params;
    const [startTask, { error: startTaskError, loading: startTaskLoading }] = useMutation(START_TASK_MUTATION);
    const { data: claimData, error: claimError, loading: claimLoading, refetch } = useQuery(GET_CLAIM, {
        variables: {
            claimId: existingClaim && existingClaim.id,
        },
    });
    const subscription = client.subscribe({
        query: TASK_APPROVED,
    });
    const triggerRefetch = () => {
        saveVerification(true);
        refetch();
    };
    const [saved, saveVerification] = useState(false);

    let primaryButton: React.ComponentType<any> | React.ReactElement | null = null;
    // Get task verification

    let taskVerification: null | ITaskVerification = null;

    if (claimLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        );
    }

    if (claimData && claimData.opportunityClaim && claimData.opportunityClaim.verifications) {
        taskVerification = claimData.opportunityClaim.verifications.find((v: any) => v.task.id === taskId);
    }
    subscription.subscribe(() => {
        if (refetch) {
            refetch();
        }
    });

    const requestVerification = () => {
        startTask({
            variables: {
                opportunityId: opportunity.id,
                taskId,
            },
        })
            .then(() => refetch())
            .catch((e) => {
                console.error(e);
            });
    };
    if (taskVerification) {
        if (taskVerification.approved) {
            primaryButton = <Button disabled={true} title="Approved" onPress={() => {}} />;
        } else if (taskVerification.rejected) {
            primaryButton = <Button title="Re-submit" onPress={() => requestVerification()} />;
        } else if (taskVerification.started) {
            primaryButton = <Button disabled={true} title="Pending Verification" onPress={() => {}} />;
        } else {
            primaryButton = (
                <SubmitButton
                    taskVerification={taskVerification}
                    onPress={() => requestVerification()}
                    saved={saved}
                />
            );
        }
    }

    if (claimError) {
        return (
            <View style={styles.root}>
                <Text style={styles.errorText}>{i18n.t('unableToLoadClaimData')}</Text>
            </View>
        );
    }

    if (startTaskError) {
        return (
            <View style={styles.root}>
                <Text style={styles.errorText}>{startTaskError ? startTaskError.message : null}</Text>
            </View>
        );
    }

    if (claimLoading || startTaskLoading) {
        return (
            <View style={styles.root}>
                <ActivityIndicator size="large" color="grey" />
            </View>
        );
    }

    return (
        <ScrollView style={styles.header}>
            <View style={styles.instructions}>
                <Text style={styles.instructionHeader}>{i18n.t('instructions')}</Text>

                {taskVerification && taskVerification.rejected ? (
                    <Text style={styles.rejectionText}>
                        {i18n.t('yourSubmissionHasBeenRejectedForTheFollowingReasons')}{' '}
                        {taskVerification.rejectedReason}
                        {i18n.t('pleaseSubmitNewContentToTryAgain')}
                    </Text>
                ) : (
                    <Text style={styles.instructionText}>{task.instructions}</Text>
                )}
            </View>
            <Divider />
            {taskVerification ? (
                <DigitalVerify
                    uploadResponse={props.route.params.uploadResponse}
                    taskVerification={taskVerification}
                    opportunityId={opportunity.id}
                    navigation={navigation}
                    triggerRefetch={triggerRefetch}
                />
            ) : null}
            {primaryButton}
            <View style={{ height: 32, width: '100%' }} />
        </ScrollView>
    );
};

ManualDigital.navigationOptions = {
    title: 'Verification',

    headerStyle: {
        backgroundColor: '#814bad',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { ManualDigital };
