import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, View, Text } from 'react-native';
import Colors from '../constants/Colors';

const styles = StyleSheet.create({
    icon: {},
    root: {
        flex: 1,
        backgroundColor: Colors.dark.background,
        alignItems: 'center',
        justifyContent: 'center',
    },
    message: {
        color: Colors.dark.text,
        fontSize: 22,
        fontFamily: 'Arimo_400Regular',
    },
});

export function Empty(props: { message?: string }) {
    return (
        <View style={styles.root}>
            <Ionicons style={styles.icon} name="ios-cube-outline" size={200} color={Colors.dark.secondary} />
            <Text style={styles.message}>{props.message || 'Empty list'}</Text>
        </View>
    );
}
