import React, { useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { SurveyComponent } from './SurveyComponent';
import { Divider } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { START_TASK_MUTATION } from './mutations';
import { GET_OPPORTUNITY_CLAIM, GET_TASK } from './queries';
import { UPDATE_VERIFICATION_TASK } from '../../lib/mutations';
import { useMutation, useQuery } from '@apollo/client';

const styles = StyleSheet.create({
    scroll: {
        minHeight: '100%',
    },
    root: {
        padding: 5,
    },
    error: {
        fontFamily: 'Lato-Medium',
        fontSize: 14,
        color: 'red',
    },
    loader: {},
    containerStyle: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
    },
    actionContainerStyle: {
        flexGrow: 1,
        padding: 10,
    },
    instructions: {
        padding: 20,
    },
    instructionHeader: {
        fontFamily: 'Lato-Medium',
        fontSize: 22,
        color: 'grey',
    },
    instructionText: {
        fontFamily: 'Lato',
        fontSize: 14,
        color: 'grey',
    },
    feedbackText: {
        marginTop: 10,
        padding: 25,
        fontFamily: 'Lato-Medium',
        fontSize: 14,
    },
    selected: {
        backgroundColor: '#e9e9e9',
    },
    rejectionText: {
        color: 'red',
    },
});

const Survey = ({ route, navigation }: { route: any; navigation: any }) => {
    let task;
    const [updateVerification, { loading: updateLoading, error: updateError }] = useMutation(
        UPDATE_VERIFICATION_TASK,
        {}
    );
    const { opportunity, claim, taskId } = route.params;
    const [startTask, { loading: startTaskLoading, error: startTaskError }] = useMutation(
        START_TASK_MUTATION,
        {}
    );
    const { data: taskData, loading: taskLoading, error: taskError } = useQuery(GET_TASK, {
        variables: {
            taskId,
        },
    });

    const _getResponses = (responses: any) => {
        return Object.keys(responses).map((fieldId) => {
            return {
                fieldId,
                response: Array.isArray(responses[fieldId])
                    ? responses[fieldId].map((r: { label: String; value: Number }) => r.value).join()
                    : responses[fieldId],
            };
        });
    };
    //start the task as soon as the task is seen
    const _handleSubmit = (surveyData: any) => {
        if (!claim) {
            startTask({
                variables: {
                    opportunityId: opportunity.id,
                    taskId,
                    surveyResponses: _getResponses(surveyData),
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_OPPORTUNITY_CLAIM,
                        variables: {
                            opportunityId: opportunity.id,
                        },
                    },
                ],
            }).then(() => {
                if (Object.keys(surveyData).length !== 0) {
                    navigation.pop();
                }
            });
        } else {
            const taskVerification = claim.verifications.find((v: any) => v.task.id === taskId);
            if (!taskVerification.started) {
                startTask({
                    variables: {
                        opportunityId: opportunity.id,
                        taskId,
                        surveyResponses: _getResponses(surveyData),
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_OPPORTUNITY_CLAIM,
                            variables: {
                                opportunityId: opportunity.id,
                            },
                        },
                    ],
                }).then(() => {
                    if (Object.keys(surveyData).length !== 0) {
                        navigation.pop();
                    }
                });
            } else {
                let responses = _getResponses(surveyData);
                if (responses.length !== 0) {
                    updateVerification({
                        variables: {
                            taskVerificationId: taskVerification.id,
                            surveyResponses: _getResponses(surveyData),
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_OPPORTUNITY_CLAIM,
                                variables: {
                                    opportunityId: opportunity.id,
                                },
                            },
                        ],
                    }).then(() => {
                        if (Object.keys(surveyData).length !== 0) {
                            navigation.pop();
                        }
                    });
                }
            }
        }
    };
    //start the tasks when the user click and se them
    useEffect(() => {
        _handleSubmit({});
    }, []);
    if (updateError) {
        return (
            <View style={styles.root}>
                <Text style={styles.error}>Unable to update survey, please check your responses.</Text>
            </View>
        );
    }

    if (updateLoading) {
        return <ActivityIndicator style={styles.loader} size="large" color="#f6da6f" />;
    }

    if (taskLoading || startTaskLoading) {
        return <ActivityIndicator style={styles.loader} size="large" color="#f6da6f" />;
    }

    if (taskError) {
        return (
            <View style={styles.root}>
                <Text style={styles.error}>Error loading survey.</Text>
            </View>
        );
    }

    if (startTaskError) {
        return (
            <View style={styles.root}>
                <Text style={styles.error}>Error submitting survey, please try again</Text>
            </View>
        );
    }

    if (taskData) {
        task = taskData.task;
    }
    const taskVerification = claim.verifications.find((v: any) => v.task.id === taskId);

    return (
        <KeyboardAwareScrollView style={styles.scroll}>
            <View style={styles.root}>
                <View style={styles.instructions}>
                    {taskVerification && taskVerification.rejected ? (
                        <Text style={styles.rejectionText}>
                            Your submission has been rejected for the following reasons:{' '}
                            {taskVerification.rejectedReason}. Please submit new content to try again.
                        </Text>
                    ) : (
                        <>
                            <Text style={styles.instructionHeader}>Instructions</Text>
                            <Text style={styles.instructionText}>
                                In order to complete this task, you must complete the survey below and submit
                                for verification.
                            </Text>
                        </>
                    )}
                </View>
                <Divider />
                <SurveyComponent
                    survey={task.survey}
                    _submit={(surveyData: any) => _handleSubmit(surveyData)}
                    navigation={navigation}
                    taskVerification={taskVerification}
                />
            </View>
        </KeyboardAwareScrollView>
    );
};

Survey.navigationOptions = {
    title: 'Provide Feedback',
    headerStyle: {
        backgroundColor: '#f4511e',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { Survey };
