const translations = {
    en: {
        nutriSourceAppFor: 'NutriSource App for ',
        version: 'Version',
        copyright2021: 'Copyright 2021 ',
        viewTermsOfService: 'View Terms of Service',
        viewPrivacyPolicy: 'View Privacy Policy',
        unableToLoadClaimData: 'Unable to load claim data',
        instructions: 'Instructions',
        yourSubmissionHasBeenRejectedForTheFollowingReasons:
            'Your submission has been rejected for the following reasons: ',
        pleaseSubmitNewContentToTryAgain: '. Please submit new content to try again.',
        inPersonVerificationRequest: 'In-Person Verification Request',
        onSite:
            'This customer has indicated that they are present on-site and ready for in-person verification of this task. Confirm that this customer has followed the above instructions and approve or reject their request accordingly.',
        noPosts: 'No Posts',
        accountCreated: 'Account Created',
        createANewAccount: 'Create a New Account',
        login: 'LOGIN',
        unableToLoadProfile: 'Unable to Load Profile',
        noRewardOpportunitiesHaveBeenSaved: 'No Reward Opportunities Have Been Saved',
        bronzeLevel: 'Bronze Level',
        silverLevel: 'Silver Level',
        goldLevel: 'Gold Level',
        platinumLevel: 'Platinum Level',
        swipeLeftOrRightToViewOtherTiers: 'Swipe left or right to view other tiers',
        thisTaskIsBeingReviewed: 'This task is being reviewed',
        anErrorOccuredWhileUpdatingYourProfile: 'An Error Occured While Updating Your Profile',
        anErrorOccuredWhileLoadingProfile: 'An Error Occured While Loading Profile',
        camera: 'Camera',
        gallery: 'Gallery',
        confirmationCode: 'Confirmation Code',
        confirm: 'CONFIRM',
        phoneNumber: 'Phone Number',
        unableToLoadReward: 'Unable to Load Reward',
        unknownRewardType: 'Unknown Reward Type',
        goToHomeScreen: 'Go to home screen!',
        thisScreenDoesntExist: "This screen doesn't exist.",
        profile: 'Profile',
        info: 'Info',
        exit: 'Exit',
        address: 'Address:',
        description: 'Description:',
        rewardsAtThisShop: 'Rewards at this shop',
        didntReceiveItTapResend: "Didn't receive it? Tap Resend",
        completed: 'Completed',
        rejected: 'Rejected',
        responseNotFound: 'Response Not Found',
        surveyResponses: 'Survey Responses',
        amountToVerify: 'Amount to verify:',
        responseNotFound: 'Response Not Found',
        seeAll: 'See all',
        searchPlaceholder: 'Search',
        tutorial: 'Tutorial',
        tutorial1:
            'Welcome to the NutriSource Rewards app. Let’s go through how to earn discounts and freebies!',
        tutorial2:
            'Check out all of the activities in the NutriSource network, see who has been doing what (Main Feed), and get your Notifications.',
        tutorial3: 'Look at what other people have been earning, doing, and saying.',
        tutorial4: 'Get updates on the latest from NutriSource, your reward approvals, and more!',
        tutorial5:
            'See what opportunities are available to you (and check back often, we post new opportunities regularly).',
        tutorial6:
            'Opportunities you’ve already completed once are marked with a small reward icon in the upper right.',
        tutorial7:
            'Check out the latest fun activities NutriSource has ready for you and what you can earn for doing so. Not ready to do it right now? Just add to your favorites and you can easily find it later!',
        tutorial8:
            "Once you've selected an Opportunity, click here to complete the task, follow the instructions, and earn!",
        tutorial9:
            'Here’s what you’ll earn at your current tier. You can swipe through and see what other tiers are earning. ',
        tutorial10:
            'The status of your reward lives here. When NutriSource confirms you’ve completed all of the associated tasks (there may be more than one), you claim your reward here.',
        tutorial11: 'After claiming a reward, check your Wallet to access it.',
        tutorial12: 'Check out where you can redeem your rewards here.',
        tutorial13:
            'Make sure your location services are turned on, otherwise you will not be able to participate and earn rewards.',
        tutorial14:
            'Look at all participating locations. Add the ones you frequent the most as favorites (and make sure to leave your location services on so we can let you know where to find the closest pet store that carries NutriSource.)',
        tutorial15:
            'Learn more about the shop you’ve selected and see what rewards and opportunities are available there.',
        tutorial16: 'Keep track of your earned and redeemed rewards here.',
        tutorial17:
            'Scroll through to decide which reward to use on your next purchase! Coupons confer discounts on your next purchase and vouchers are earned freebies!',
        tutorial18:
            'How to redeem - Once you’ve selected your reward, hit “Redeem,” select your location (if appropriate), and show your phone to the cashier. They’ll either scan the QR code here or ask you to manually complete the redemption process on your own mobile device.',
        tutorial19:
            'Make sure your location services are turned on, otherwise you will not be able to redeem your rewards.',
        tutorial20:
            'Control your experience with the NutriSource app. Update your profile, view your current tier status, add a photo and more!',
        tutorial21:
            'You can access this tutorial any time by clicking the appropriate button on your Settings page.',
        transactionId: 'Reward ID',
        rewardLoadError: 'Unable to load reward details',
        rewardExpired: 'EXPIRED',
        rewardShowStaff: 'Be sure to show staff during redemption.',
        redemptionRejected: 'Your redemption was rejected.',
        redemptionRejectReason: 'Reason: ',
        tc: ' Terms and Conditions:',
        voucher: 'Voucher',
        storecredit: 'Store Credit',
        coupon: 'Coupon',
    },
};

export default translations;
