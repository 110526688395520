import * as React from 'react';
import {
    ScrollView as DefaultScrollView,
    Text as DefaultText,
    TextInput as DefaultTextInput,
    View as DefaultView,
} from 'react-native';
import { default as DefaultMarkdown, MarkdownProps } from 'react-native-markdown-display';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

export function useThemeColor(
    props: { light?: string; dark?: string },
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
    const theme = useColorScheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
}

type ThemeProps = {
    lightColor?: string;
    darkColor?: string;
};

export type ScrollViewProps = ThemeProps & DefaultScrollView['props'];
export type TextProps = ThemeProps & DefaultText['props'];
export type ThemedMarkdownProps = ThemeProps & MarkdownProps & { children: string };
export type ViewProps = ThemeProps & DefaultView['props'];

export function Markdown(props: ThemedMarkdownProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

    return <DefaultMarkdown style={{ body: { color: color } }} {...otherProps}></DefaultMarkdown>;
}

export function ScrollView(props: ScrollViewProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

    return <DefaultScrollView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function Text(props: TextProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

    return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function TextInput(props: TextInputProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

    return <DefaultTextInput style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

    return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}
