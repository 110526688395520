import React from 'react';
import { StyleSheet } from 'react-native';
import { ListItem, Text, Icon } from 'react-native-elements';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsStackParams } from '../types';
import { useDispatch } from 'react-redux';
import { CommonActions } from '@react-navigation/native';
import { clearToken } from '../store/api';
import { View } from '../components/Themed';
import i18n from '../config/i18n';

type SettingsScreenRouteProp = StackScreenProps<SettingsStackParams, 'Settings'>;

export default function Settings({ navigation }: SettingsScreenRouteProp) {
    let dispatch = useDispatch();

    return (
        <View style={styles.container}>
            <ListItem onPress={() => navigation.navigate('Profile')} style={styles.listItem}>
                <Icon name="person" style={{ flex: 0 }} />
                <Text style={{ flex: 1 }}>{i18n.t('profile')}</Text>
                <Icon name="navigate-next" style={{ flex: 0 }} />
            </ListItem>
            <ListItem onPress={() => navigation.navigate('Info')} style={styles.listItem}>
                <Icon name="info" style={{ flex: 0 }} />
                <Text style={{ flex: 1 }}>{i18n.t('info')}</Text>
                <Icon name="navigate-next" style={{ flex: 0 }} />
            </ListItem>
            <ListItem onPress={() => navigation.navigate('History')} style={styles.listItem}>
                <Icon name="history" style={{ flex: 0 }} />
                <Text style={{ flex: 1 }}>History</Text>
                <Icon name="navigate-next" style={{ flex: 0 }} />
            </ListItem>
            <ListItem
                onPress={() => {
                    // get the top navigator
                    navigation.dangerouslyGetParent()?.dangerouslyGetParent()?.navigate('Onboarding')
                }}
                style={styles.listItem}
            >
                <Icon name="school" style={{ flex: 0 }} />
                <Text style={{ flex: 1 }}>{i18n.t('tutorial')}</Text>
                <Icon name="navigate-next" style={{ flex: 0 }} />
            </ListItem>

            <ListItem
                onPress={() => {
                    dispatch(clearToken());
                }}
                style={styles.listItem}
            >
                <Icon name="logout" style={{ flex: 0 }} />
                <Text style={{ flex: 1 }}>{i18n.t('exit')}</Text>
                <Icon name="navigate-next" style={{ flex: 0 }} />
            </ListItem>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        height: '100%',
    },
    listItem: {
        borderRadius: 5,
        display: 'flex',
        elevation: 5,
        justifyContent: 'space-between',
        marginVertical: 5,
        marginHorizontal: 10,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: {
            height: 1,
            width: 0,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,
    },
    button: {
        color: 'white',
    },
});
