import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import Wallet from '../screens/Wallet';
import WalletHistory from '../screens/WalletHistory';

export default function WalletTabs() {
    const WallTabs = createMaterialTopTabNavigator();

    return (
        <WallTabs.Navigator
            tabBarOptions={{
                labelStyle: {},
                tabStyle: {},
                style: {},
            }}
            swipeEnabled={false}
        >
            <WallTabs.Screen name="Available" component={Wallet} />
            <WallTabs.Screen name="History" component={WalletHistory} />
        </WallTabs.Navigator>
    );
}
