import { IOpportunity, IOpportunityClaim } from '../types';

export function completeClaimPerOpportunity(cd: { opportunityClaims: IOpportunityClaim[] } | undefined, opportunityId: string) {
    if (cd) {
        return cd.opportunityClaims.filter((oc: IOpportunityClaim) => oc.opportunity.id === opportunityId && oc.completed);
    } else {
        return [];
    }
}

export function isClaimExhausted(opportunity: IOpportunity| null, currentCustomerCompletedClaimsForThisOpportunity: number): boolean {
    if(opportunity){
        if (opportunity.claimed && opportunity.instanceLimit && opportunity.instanceLimit > 0) {
            if (opportunity.claimed >= opportunity.instanceLimit) {
                return true;
            }
        }
        if (opportunity.reward) {
            const { claimPerCustomerLimit } = opportunity.reward;
            if (claimPerCustomerLimit && claimPerCustomerLimit > 0) {
                if (currentCustomerCompletedClaimsForThisOpportunity >= claimPerCustomerLimit) {
                    return true;
                }
            }

        }
    }

    return false;
}
