import React, { useState } from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { AssetView } from '../../components/Media/AssetView';
import PagerView, { PagerViewOnPageSelectedEvent } from 'react-native-pager-view';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import { useQuery } from '@apollo/client';
import Error from '../../components/Error';
import { get } from 'lodash';
import Colors from '../../constants/Colors';
import { IAsset } from '../../types';
import * as Queries from '../../lib/queries';
import { AssetQuery } from '../../components/Media/backend';

export interface AssetScrollScreenParams {
    editable?: boolean;
    initialPage?: number;
    query: AssetQuery<any>;
}

export interface AssetScrollScreenProps {
    route: { params: AssetScrollScreenParams };
    navigation: StackNavigationProp<any, any>;
}

export function AssetScrollScreen(props: AssetScrollScreenProps) {
    const params = props.route.params;
    const initialPage = params.initialPage !== undefined ? params.initialPage : 0;
    // @ts-ignore
    const { data, error, loading } = useQuery<any, any>(Queries[params.query.name], {
        variables: params.query.variables,
    });

    const [selectedPage, setSelectedPage] = useState(initialPage);

    if (loading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    }
    //query asset
    if (error || !data || !get(data, params.query.subPath)) {
        console.error(get(data, params.query.subPath), params.query.subPath);
        return <Error />;
    }
    const assets: IAsset[] = get(data, params.query.subPath);

    return (
        <PagerView
            style={styles.pagerView}
            initialPage={initialPage}
            orientation={'vertical'}
            onPageSelected={(e: PagerViewOnPageSelectedEvent) => setSelectedPage(e.nativeEvent.position)}
        >
            {assets.map((item, index) => (
                <View key={item.id} collapsable={false}>
                    <AssetView editable={params.editable} selected={selectedPage == index} asset_id={item.id} />
                </View>
            ))}
        </PagerView>
    );
}

const styles = StyleSheet.create({
    pagerView: {
        flex: 1,
    },
    loading: {
        backgroundColor: Colors.dark.background,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
