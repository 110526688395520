import React from 'react';
import {
    Dimensions,
    ImageBackground,
    TouchableHighlight,
    View,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { MediaAsset } from "./types";

const { width, height } = Dimensions.get('window');

interface ImageTileProps {
    item: MediaAsset,
    index: number;
    selected: boolean;
    selectImage: (index: number) => void;
    selectedItemNumber: number;
    renderSelectedComponent?: (component: number) => React.ComponentType<any> | React.ReactElement | null;
    //one-parameter (selected number) function is expected to return the component for the icon/text over the selected picture
    renderExtraComponent?: (photo: MediaAsset) => React.ComponentType<any> | React.ReactElement | null;
}

export function ImageTile(props: ImageTileProps) {
    const { item, index, selected, selectImage, selectedItemNumber } = props;
    const renderSelectedComponent = props.renderSelectedComponent ? props.renderSelectedComponent : (_: number) => {
        return <View style={{ display: "flex", alignItems: "flex-end" }}>
            <Ionicons name="ios-checkmark-circle-outline" size={24} color="#00008B"/>
        </View>
    };
    const renderExtraComponent = props.renderExtraComponent ? props.renderExtraComponent : (_: MediaAsset) => {
        return null;
    };
    if (!item) return null;
    return (
        <TouchableHighlight
            style={{ opacity: selected ? 0.5 : 1, marginBottom: 2, marginRight: 2 }}
            underlayColor='transparent'
            onPress={() => selectImage(index)}>
            <View style={{ position: 'relative' }}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ImageBackground
                        style={{ width: (width / 3 - 1), height: (width / 3 - 1) * (height / width) * 0.67 }}
                        source={{ uri: item.uri }}>
                        {selected && renderSelectedComponent(selectedItemNumber)}
                        {renderExtraComponent(item)}
                    </ImageBackground>
                </View>
            </View>
        </TouchableHighlight>
    )
}

