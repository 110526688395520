import React, { useEffect, useState } from 'react';

import { View, Image, ImageBackground, StyleSheet, Text, ActivityIndicator, Alert } from 'react-native';
import { Input, Button } from 'react-native-elements';
import { MaterialIcons } from '@expo/vector-icons';
// @ts-ignore
import { confirmLogin, login, clearToken, clearError } from '../store/api';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../store/types';
import { StackScreenProps } from '@react-navigation/stack';
import { CommonActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RootStackParamList } from '../types';
import { ErrorCode, RootStore } from '../store/types';
import { resendLogin, confirm as confirmFunc } from '../store/api';
import i18n from '../config/i18n';

const styles = StyleSheet.create({
    background: {
        width: '100%',
        height: '100%',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '30%',
        padding: 20,
    },
    loginBtn: {
        backgroundColor: '#8a84d7',
        width: '100%',
    },
    loginBtnTitle: {
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
    loader: {
        flex: 1,
        height: 300,
        transform: [{ scale: 4 }],
        width: 300,
    },
    createdMsg: {
        marginTop: 20,
        textAlign: 'center',
        color: 'orange',
        fontSize: 16,
    },
    loaderContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    },
    resendBtn: {
        backgroundColor: '#A1A1A1',
        width: '100%',
    },
});

type LoginScreenRouteProp = StackScreenProps<RootStackParamList, 'Login'>;

const Login = ({ navigation }: LoginScreenRouteProp) => {
    const [phone, setPhone] = useState('');
    const [confirm, setConfirm] = useState('');
    const dispatch = useDispatch();
    const loading = useSelector<RootStore>((state) => state.consumer.loginLoading);
    const confirmLoginLoading = useSelector<RootStore>((state) => state.consumer.confirmLoginLoading);
    const registrationComplete = useSelector<RootStore>((state) => state.consumer.registrationComplete);
    const loginStatus = useSelector<RootStore>((state) => state.consumer.loginStatus);
    const token = useSelector<RootStore>((state) => state.consumer.token);
    const error = useSelector<RootStore, string>((state) => state.consumer.error);
    let error_code = useSelector<RootStore, ErrorCode>((state) => state.consumer.error_code);
    const [newUser, setNewUser] = useState(false);

    useEffect(() => {
        const nextPage = newUser ? 'Onboarding' : 'BottomTabs';
        if (token) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: nextPage }],
                })
            );
        }
    }, [token]);
    useEffect(() => {
        AsyncStorage.getItem('@newuser').then((result) => {
            // if not set, getitem returns null
            setNewUser(!result);
        });
    }, [newUser]);

    const is_unknown_error =
        error_code !== ErrorCode.None &&
        error_code !== ErrorCode.SMSCodeDoesNotMatch &&
        error_code !== ErrorCode.SMSCodeMissing &&
        error_code !== ErrorCode.MissingPhoneNumber;
    return (
        <ImageBackground style={styles.background} source={require('../assets/images/loginbg.jpg')}>
            {(loading || confirmLoginLoading) && (
                <View style={styles.loaderContainer}>
                    <ActivityIndicator size="large" style={styles.loader} color={'#ffffff'} />
                </View>
            )}
            <View style={styles.root}>
                <Image
                    source={require('../assets/images/logo.png')}
                    style={{ height: '35%', resizeMode: 'contain', width: '50%' }}
                />
                {is_unknown_error && (
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingVertical: 16,
                            alignItems: 'center',
                        }}
                    >
                        <MaterialIcons name="error" size={28} color="#B00020" style={{ marginRight: 8 }} />
                        <Text style={{ color: '#B00020' }}>{error}</Text>
                    </View>
                )}
                <Input
                    disabled={loginStatus === types.CONFIRMED}
                    onChangeText={(text) => setPhone(text)}
                    keyboardType="phone-pad"
                    returnKeyType={'done'}
                    placeholder={`${i18n.t('phoneNumber')}`}
                    style={{ color: 'black' }}
                    placeholderTextColor="#000"
                    inputStyle={{ color: 'white', marginLeft: 10 }}
                    labelStyle={{ color: 'white' }}
                    inputContainerStyle={{
                        borderBottomColor: error_code === ErrorCode.MissingPhoneNumber ? '#B00020' : undefined,
                        borderBottomWidth: 1,
                    }}
                    rightIcon={
                        error_code === ErrorCode.MissingPhoneNumber ? (
                            <MaterialIcons name="error" size={28} color="#B00020" />
                        ) : undefined
                    }
                    errorMessage={error_code === ErrorCode.MissingPhoneNumber ? error : undefined}
                    errorStyle={{ color: '#B00020' }}
                    leftIcon={<MaterialIcons name="phone" size={28} color="#8a84d7" />}
                />
                {loginStatus === types.CONFIRMED && (
                    <>
                        <Input
                            onChangeText={(text) => setConfirm(text)}
                            inputContainerStyle={{
                                borderBottomColor:
                                    error_code === ErrorCode.SMSCodeDoesNotMatch ||
                                    error_code === ErrorCode.SMSCodeMissing
                                        ? '#B00020'
                                        : undefined,
                                borderBottomWidth: 1,
                            }}
                            containerStyle={{ marginTop: 20 }}
                            keyboardType="number-pad"
                            returnKeyType={'done'}
                            placeholder={`${i18n.t('confirmationCode')}`}
                            style={{ color: 'black' }}
                            placeholderTextColor="#000"
                            inputStyle={{ color: 'white', marginLeft: 10 }}
                            labelStyle={{ color: 'white' }}
                            errorStyle={{ color: '#B00020' }}
                            errorMessage={
                                error_code === ErrorCode.SMSCodeDoesNotMatch ||
                                error_code === ErrorCode.SMSCodeMissing
                                    ? error
                                    : undefined
                            }
                            leftIcon={<MaterialIcons name="lock-open" size={28} color="#8a84d7" />}
                            rightIcon={
                                error_code === ErrorCode.SMSCodeDoesNotMatch ||
                                error_code === ErrorCode.SMSCodeMissing ? (
                                    <MaterialIcons name="error" size={28} color="#B00020" />
                                ) : undefined
                            }
                        />
                        <Button
                            onPress={() => {
                                dispatch(clearError());
                                dispatch(confirmLogin(phone, confirm));
                            }}
                            containerStyle={{ marginTop: 40 }}
                            buttonStyle={styles.loginBtn}
                            titleStyle={styles.loginBtnTitle}
                            title={`${i18n.t('confirm')}`}
                        />
                        <Button
                            containerStyle={{ marginTop: 20 }}
                            buttonStyle={styles.resendBtn}
                            title="Resend"
                            onPress={() => dispatch(resendLogin(phone))}
                        />
                        <Button
                            type="clear"
                            onPress={() => {
                                dispatch(clearToken());
                                dispatch(clearError());
                            }}
                            containerStyle={{ marginTop: 20 }}
                            titleStyle={{ color: 'black', marginLeft: 10 }}
                            icon={<MaterialIcons name="cancel" size={25} color="#8a84d7" />}
                            title="Cancel"
                        />
                    </>
                )}
                {loginStatus !== types.CONFIRMED && (
                    <>
                        <Button
                            onPress={() => {
                                dispatch(clearError());
                                dispatch(login(phone));
                            }}
                            containerStyle={{ marginTop: 40 }}
                            buttonStyle={styles.loginBtn}
                            titleStyle={styles.loginBtnTitle}
                            title={`${i18n.t('login')}`}
                        />
                    </>
                )}
                {registrationComplete && <Text style={styles.createdMsg}>{i18n.t('accountCreated')}</Text>}

                <Button
                    type="clear"
                    onPress={() => {
                        dispatch(clearError());
                        navigation.navigate('SignUp');
                    }}
                    containerStyle={{ marginTop: 80 }}
                    titleStyle={{ color: 'black', marginLeft: 10 }}
                    icon={<MaterialIcons name="open-in-new" size={25} color="#8a84d7" />}
                    title={`${i18n.t('createANewAccount')}`}
                />
            </View>
        </ImageBackground>
    );
};

export { Login };
