import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { View, StyleSheet, RefreshControl, FlatList, Pressable, ActivityIndicator, Text } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { OpportunityCard } from '../components/opportunities/OpportunityCard';
import { IOpportunity, BottomTabParamList, IOpportunityClaim } from '../types';
import { inBookMark, IProfile } from './OpportunityDetail';
import { BOOK_MARK_LIST, GET_OPPORTUNITIES } from '../lib/backend/oportunity_tab/queries';
import { GET_COMPLETED_OPPORTUNITY_CLAIMS } from '../lib/queries';
import { completeClaimPerOpportunity, isClaimExhausted } from '../lib/opportunities';
import i18n from '../config/i18n'

type OpportunitiesScreenRouteProp = StackScreenProps<BottomTabParamList, 'Opportunities'>;

// @ts-ignore
export default function Opportunities({ navigation, filter }: OpportunitiesScreenRouteProp) {
    const {
        data: completedData,
        error: completedError, // TODO: do something with this error
    } = useQuery<{ opportunityClaims: IOpportunityClaim[] }>(GET_COMPLETED_OPPORTUNITY_CLAIMS);

    const {
        data: profileData,
        error: profileError,
        loading: profileLoading,
    } = useQuery<{ profile: IProfile }>(BOOK_MARK_LIST, { skip: filter !== 'saved' });
    const _goToDetail = (opp: any) => {
        // @ts-ignore
        navigation.navigate('OpportunityDetail', {
            opportunity: opp,
            completedClaims: completeClaimPerOpportunity(completedData, opp.id),
        });
    };

    function renderItem(el: { item: IOpportunity }) {
        const currentCustomerCompletedClaimsForThisOpportunity = completeClaimPerOpportunity(
            completedData,
            el.item.id
        ).length;
        const claimsExhausted = isClaimExhausted(el.item, currentCustomerCompletedClaimsForThisOpportunity);
        return (
            <>
                <Pressable
                    style={{
                        /*@ts-ignore */
                        cursor: 'pointer',
                    }}
                    onPress={() => _goToDetail(el.item)}
                    disabled={claimsExhausted}
                >
                    <OpportunityCard
                        cardStyle={{ margin: 8 }}
                        parentNav={navigation}
                        standalone={true}
                        opportunity={el.item}
                        disabled={claimsExhausted}
                    />
                </Pressable>
            </>
        );
    }

    const [firstLoad, setFirstLoad] = useState(true);
    const {
        data,
        loading,
        refetch: refetchOpps,
        fetchMore,
    } = useQuery<{ opportunities: IOpportunity[] }, { first: number }>(GET_OPPORTUNITIES, {
        variables: {
            first: 20,
        },
        fetchPolicy: 'no-cache',
    });

    let activeOpps: IOpportunity[] = useMemo(
        function () {
            if (filter === 'all' && data && data.opportunities) {
                const now = new Date();
                return data.opportunities.filter((opp) => {
                    const startDate = new Date(opp.startDate);
                    const endDate = new Date(opp.endDate);
                    const claimsExhausted = isClaimExhausted(
                        opp,
                        completeClaimPerOpportunity(completedData, opp.id).length
                    );
                    return opp.perpetual || (startDate <= now && endDate >= now && !claimsExhausted);
                });
            } else {
                return [];
            }
        },
        [data, completedData]
    );

    let listData = useMemo(
        function () {
            if (filter === 'saved' && profileData && data) {
                return data.opportunities.filter((opp) => inBookMark(profileData.profile, opp.id));
            } else if (filter === 'completed' && completedData && data) {
                const completedIds: string[] = completedData.opportunityClaims.map(
                    (c: any) => c.opportunity.id
                );
                return data.opportunities.filter((opp) => completedIds.includes(opp.id));
            }
            return activeOpps;
        },
        [filter, activeOpps, data, profileData, completedData]
    );

    if (filter === 'saved' && profileError) {
        return (
            <View style={styles.loading}>
                <Text>{i18n.t('unableToLoadProfile')}</Text>
            </View>
        );
    }

    if ((firstLoad && loading) || (filter === 'saved' && profileLoading)) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    } else if (filter === 'saved' && listData.length === 0) {
        return (
            <View style={styles.loading}>
                <Text>{i18n.t('noRewardOpportunitiesHaveBeenSaved')}</Text>
            </View>
        );
    } else {
        return (
            <>
                <FlatList
                    refreshControl={<RefreshControl refreshing={loading} onRefresh={refetchOpps} />}
                    data={listData}
                    renderItem={renderItem}
                    initialNumToRender={10}
                    windowSize={5}
                    extraData={completedData}
                    maxToRenderPerBatch={2}
                    removeClippedSubviews={false}
                    onEndReachedThreshold={0.1}
                    onEndReached={async () => {
                        if (data && data.opportunities && data.opportunities.length > 0) {
                            // The fetchMore method is used to load new data and add it
                            // to the original query we used to populate the list
                            if (fetchMore) {
                                if (firstLoad) setFirstLoad(false);
                                try {
                                    await fetchMore({
                                        variables: {
                                            first: 20,
                                            after: data.opportunities[data.opportunities.length - 1].id,
                                        },
                                    });
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                        }
                    }}
                    ListFooterComponent={<View style={{ height: 20 }} />}
                    keyExtractor={(item: IOpportunity) => item.id}
                />
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    list: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    OpportunityContainer: {
        marginBottom: 10,
    },
    cardBg: {
        height: 100,
        marginHorizontal: 10,
        marginVertical: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4,
        },
        shadowOpacity: 0.4,
        shadowRadius: 3.84,
        elevation: 5,
    },
    cardGradient: {
        borderRadius: 5,
        height: '100%',
    },
    opportunityDescription: {
        marginHorizontal: 10,
        marginTop: 10,
        textAlign: 'center',
    },
    opportunityText: {
        color: 'white',
        fontSize: 12,
        fontFamily: 'FjallaOne_400Regular',
    },
    opportunityTitle: {
        color: 'white',
        fontSize: 24,
        fontFamily: 'FjallaOne_400Regular',
    },
    shopName: {
        color: 'white',
        fontSize: 18,
        fontFamily: 'FjallaOne_400Regular',
    },
    storeLogo: {
        flex: 0,
        flexBasis: 75,
        marginVertical: 10,
        marginHorizontal: 10,
        width: 75,
        height: 75,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
});
