import React, { useMemo, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import cloudinary from 'cloudinary-core';
import { SHOPS, VOUCHER } from '../../lib/queries';
import { useMutation, useQuery } from '@apollo/client';
import { APPROVE_REDEMPTION, CANCEL_REDEMPTION, CREATE_REDEMPTION } from '../../lib/mutations';
import { ErrorText } from '../StyledText';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ICoordinates, IRedemption, IVoucher } from '../../store/types';
import Error from '../Error';
import { formatExpirationDate, getRankedSku, makeManualActions, redemptionOrderByDateDesc } from './utils';
import RedemptionQRCode from './RedemptionQRCode';
import { RedemptionModal } from './RedemptionModal';
import truncate from 'lodash/truncate';
import DisplayRedemptionLocation from './components/DisplayRedemptionLocation';
import RewardShopHeader from './RewardShopHeader';
import RedemptionLocation from './RedemptionLocation';
import { IRewardType } from '../../types';
import { useSelector } from 'react-redux';
import i18n from '../../config/i18n';

// @ts-ignore
const cl = new cloudinary.Cloudinary({ cloud_name: 'govi', secure: true });

// TODO: Moved shared styles to make it DRY
const styles = StyleSheet.create({
    bizBackground: {
        height: 140,
    },
    transparentfifty: {
        opacity: 0.5,
    },
    headerContainer: {
        flexDirection: 'row',
    },
    title: {
        marginTop: 35,
        textShadowColor: 'black',
        textShadowRadius: 4,
        textShadowOffset: { width: 3, height: 4.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: 'white',
    },
    redeemedTitle: {
        marginTop: 90,
        textShadowColor: 'black',
        textShadowOffset: { width: 1.0, height: 1.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: '#CECECE',
    },
    shopInfo: {
        marginTop: 10,
        marginLeft: 20,
    },
    shopName: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
    },
    shopAddress: {
        color: 'white',
        marginBottom: 5,
        marginTop: 15,
    },
    bizLogo: {
        height: 50,
        marginLeft: 20,
        marginTop: 20,
        width: 50,
    },
    btnContainer: {
        padding: 10,
    },
    buttonOutlineStyle: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    btnPending: {
        backgroundColor: '#fadf92',
    },
    btnSolid: {
        backgroundColor: '#8A84D7',
    },
    disclaimer: {
        color: 'white',
        marginTop: 10,
        padding: 20,
        textAlign: 'left',
    },
    disclaimerHeader: {
        color: 'white',
        marginTop: 10,
        textAlign: 'center',
    },
    goBack: {},
    hidden: {
        display: 'none',
    },
    idHeader: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    idText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    instructions: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        padding: 20,
    },
    loading: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    overlay: {
        textAlign: 'center',
        marginTop: -120,
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 45,
        color: '#ffffff',
    },
    expiredOverlay: {
        textAlign: 'center',
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 45,
        color: '#ffffff',
        transform: [{ translateY: 30 }, { rotate: '15deg' }],
    },
    locationText: {
        backgroundColor: '#f6c6c5',
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    manualApprove: {
        color: 'gray',
        fontFamily: 'Arimo_400Regular',
        fontSize: 16,
        textAlign: 'center',
    },
    modal: {
        backgroundColor: 'white',
        height: 300,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 100,
    },
    modalBtn: {
        backgroundColor: '#8A84D7',
    },
    modalBtnCancel: {
        backgroundColor: '#8A84D7',
    },
    modalBtnContainer: {},
    modalHeader: {
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        marginTop: 20,
        textAlign: 'center',
    },
    qrWrapper: {
        alignItems: 'center',
        flex: 1,
    },
    root: {
        backgroundColor: '#5a5e5c',
    },
    rejectedHeadline: {
        backgroundColor: '#f6c6c5',
        color: 'red',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        paddingTop: 10,
        textAlign: 'center',
    },
    rejectedText: {
        backgroundColor: '#f6c6c5',
        color: 'black',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    sku: {
        height: 100,
        width: 200,
    },
    skuContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 100,
        justifyContent: 'space-around',
        textAlign: 'center',
    },
    tosBody: {
        flex: 1,
        padding: 20,
    },
});

const VoucherDetail = (props: { voucherID: string }) => {
    const { voucherID } = props;
    let expires = '';
    let overlay = '';
    const [modalVisible, setModalVisible] = useState(false);
    const currentUser = useSelector((state: any) => {
        return state.consumer.user;
    });
    const { data: rewardData, loading: rewardLoading, error: rewardError, refetch: refetchVoucher } = useQuery<
        { voucher: IVoucher },
        { voucherID: string }
    >(VOUCHER, {
        pollInterval: 5000,
        variables: {
            voucherID,
        },
    });
    const is_expired: boolean = useMemo(() => {
        if (rewardData && rewardData.voucher && rewardData.voucher.expires) {
            return new Date(rewardData.voucher.expires) <= new Date();
        }
        return false;
    }, [rewardData]);
    //return the last redemption that is not canceled, rejected, only if there is not approved redemption
    const pendingRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.voucher) {
            const coupon = rewardData.voucher;
            if (coupon.redemptions.filter((r) => r.approved).length != 0) {
                return null;
            } else {
                return coupon.redemptions
                    .filter((r) => !(r.canceled || r.rejected))
                    .sort(redemptionOrderByDateDesc)[0];
            }
        } else {
            return null;
        }
    }, [rewardData]);

    const approvedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.voucher) {
            const coupon = rewardData.voucher;
            const approved = coupon.redemptions.filter((r) => r.approved);
            if (approved.length != 0) {
                return approved.concat().sort(redemptionOrderByDateDesc)[0];
            }
        }
        return null;
    }, [rewardData]);
    const is_approved = useMemo(() => {
        if (approvedRedemption) {
            return true;
        }
        if (rewardData && rewardData.voucher && rewardData.voucher.redeemed) {
            return rewardData.voucher.redeemed;
        }
        return false;
    }, [approvedRedemption, rewardData]);
    const is_pending = useMemo(() => {
        if (is_expired) return false;
        if (is_approved) return false;
        if (pendingRedemption) {
            return true;
        }
        return false;
    }, [pendingRedemption, rewardData, is_approved, is_expired]);
    const rejectedRedemption: IRedemption | null = useMemo(() => {
        if (rewardData && rewardData.voucher && rewardData.voucher.redemptions.length != 0) {
            const last = rewardData.voucher.redemptions.concat().sort(redemptionOrderByDateDesc)[0];
            if (last.rejected) {
                return last;
            }
        }
        return null;
    }, [rewardData]);
    const is_rejected = useMemo(() => {
        return !!rejectedRedemption;
    }, [rejectedRedemption]);
    const can_make_redemption = useMemo(() => {
        return !is_expired && !is_approved && !is_pending;
    }, [is_approved, is_expired, is_pending]);

    const { showActionSheetWithOptions } = useActionSheet();
    const { data: shopsData, loading: shopsLoading, error: shopsError } = useQuery(SHOPS);

    const [createRedemption, { loading: createRedemptionLoading, error: createRedemptionError }] = useMutation<
        { createRedemption: IRedemption },
        {
            rewardId: string;
            rewardType: IRewardType;
            locationId?: string;
        }
    >(CREATE_REDEMPTION, { onCompleted: () => refetchVoucher() });
    const [
        approveRedemption,
        { loading: approveRedemptionLoading, error: approveRedemptionError },
    ] = useMutation<
        { approveRedemption: IRedemption },
        {
            redemptionId: string;
            data?: {
                coordinates?: ICoordinates;
                locationId?: string;
            };
        }
    >(APPROVE_REDEMPTION, { onCompleted: () => refetchVoucher() });
    const [cancelRedemption, { loading: cancelRedemptionLoading, error: cancelRedemptionError }] = useMutation<
        { cancelRedemption: IRedemption },
        {
            redemptionId: string;
        }
    >(CANCEL_REDEMPTION, { onCompleted: () => refetchVoucher() });

    if (rewardError) {
        console.error(rewardError);
        return <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>;
    }

    if (rewardLoading) {
        return <ActivityIndicator color="#7a4ca9" />;
    }
    const voucher = rewardData && rewardData.voucher;

    const redeemAction = () => {
        if (voucher && can_make_redemption) {
            if (voucher?.prototype?.redemptionLocations && voucher.prototype.redemptionLocations.length > 0) {
                setModalVisible(true);
            } else {
                createRedemption({
                    variables: {
                        rewardId: voucher.id,
                        rewardType: 'Voucher',
                    },
                }).catch((e) => console.error(e));
            }
        }
    };

    const confirmRedemption = () => {
        if (pendingRedemption && !is_expired) {
            approveRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };

    const cancelRedemptionAction = () => {
        if (pendingRedemption) {
            cancelRedemption({ variables: { redemptionId: pendingRedemption.id } }).catch((e) =>
                console.error(e)
            );
        }
    };

    const manualActions = makeManualActions(
        { confirmRedemption, cancelRedemption: cancelRedemptionAction },
        showActionSheetWithOptions
    );

    const _titleStyle = () => {
        if (voucher && (voucher.redeemed || voucher.pendingApproval)) {
            return styles.redeemedTitle;
        } else if (is_expired) {
            return [styles.title, styles.transparentfifty];
        } else {
            return styles.title;
        }
    };

    let redeemTitle = 'Redeem';
    if (voucher) {
        if (voucher.expires) {
            if (is_expired) {
                expires = 'Expired ' + formatExpirationDate(voucher.expires) + '. ';
            } else {
                expires = 'Expires ' + formatExpirationDate(voucher.expires) + '. ';
            }
        }
        if (is_approved) {
            redeemTitle = 'Redeemed';
            overlay = 'Redeemed';
            expires = 'Redeemed on ' + formatExpirationDate(voucher.redeemedAt);
        } else if (is_pending) {
            redeemTitle = 'Pending';
            overlay = 'Pending';
        } else if (is_rejected) {
            redeemTitle = 'Re-Attempt Redemption';
        }
    }
    const loading =
        createRedemptionLoading || cancelRedemptionLoading || approveRedemptionLoading || rewardLoading;

    if (loading) {
        redeemTitle = 'Loading';
    }
    const _getBackground = (src: String) => {
        if (voucher) {
            if (!src) {
                return '';
            }
            const imageId = src.substr(src.indexOf('upload') + 7);
            const brightness = voucher.redeemed || voucher.pendingApproval ? '-80' : '-30';
            const tag = cl.url(imageId, {
                transformation: [{ effect: 'brightness:' + brightness }, { height: 300, crop: 'fill' }],
            });

            return tag.toString();
        }
        return null;
    };

    const getRedeemBtnStyle = () => {
        if (is_pending) {
            return styles.btnPending;
        }
        return styles.btnSolid;
    };

    if (shopsLoading) {
        return (
            <View style={styles.root}>
                <ActivityIndicator color="#7a4ca9" />
            </View>
        );
    }

    if (shopsError) {
        console.error(shopsError);
        return (
            <View style={styles.root}>
                <ErrorText>{i18n.t('rewardLoadError')}</ErrorText>
            </View>
        );
    }

    const shops = shopsData.businessLocations;

    function cancelSelectRedemptionLocation() {
        setModalVisible(false);
    }

    function redeemWithLocation(rid: string) {
        if (voucher) {
            setModalVisible(false);
            createRedemption({
                variables: {
                    rewardType: 'Voucher',
                    rewardId: voucher.id,
                    locationId: rid,
                },
            }).catch((e) => console.error(e));
        }
    }

    if (rewardLoading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    } else {
        if (voucher) {
            return (
                <ScrollView>
                    <RedemptionModal
                        locations={
                            voucher.prototype && voucher.prototype.redemptionLocations
                                ? voucher.prototype.redemptionLocations
                                : []
                        }
                        visible={modalVisible}
                        onCancel={cancelSelectRedemptionLocation}
                        onRedeem={redeemWithLocation}
                    />
                    <View style={styles.root}>
                        <RewardShopHeader reward={voucher} shops={shops} />
                        {voucher.shop && voucher.shop.primaryImage ? (
                            <ImageBackground
                                style={[styles.bizBackground]}
                                imageStyle={[is_expired && styles.transparentfifty]}
                                key={voucher.id}
                                source={{
                                    uri: _getBackground(voucher.shop.primaryImage),
                                }}
                            >
                                {is_expired && (
                                    <Text style={styles.expiredOverlay}>{i18n.t('rewardExpired')}</Text>
                                )}
                                <Text style={_titleStyle()}>{truncate(voucher.text, { length: 23 })}</Text>
                                <Text style={styles.overlay}>{overlay}</Text>
                            </ImageBackground>
                        ) : null}
                        {truncate(voucher.text, { length: 23 }) !== voucher.text && (
                            <Text style={styles.instructions}>{voucher.text}</Text>
                        )}
                        <Text style={styles.instructions}>
                            {voucher.description}
                            {!!expires && '' + expires} {i18n.t('rewardShowStaff')}
                        </Text>
                        {is_rejected && (
                            <>
                                <Text style={styles.rejectedHeadline}>{i18n.t('redemptionRejected')}</Text>
                                <Text style={styles.rejectedText}>
                                    {i18n.t('redemptionRejectReason')}
                                    {rejectedRedemption ? rejectedRedemption.rejectionReason : ''}
                                </Text>
                            </>
                        )}
                        {voucher &&
                            voucher.redeemed &&
                            voucher.prototype &&
                            (voucher.prototype.skuImage || voucher.prototype.skuImageBronze) && (
                                <View style={styles.skuContainer}>
                                    <Image
                                        style={styles.sku}
                                        resizeMode={'contain'}
                                        source={{
                                            uri: getRankedSku(voucher, currentUser),
                                        }}
                                    ></Image>
                                </View>
                            )}

                        {pendingRedemption && (
                            <RedemptionQRCode
                                loading={loading}
                                error={createRedemptionError}
                                data={{ redemptions: [pendingRedemption] }}
                            />
                        )}

                        <Button
                            containerStyle={styles.btnContainer}
                            buttonStyle={getRedeemBtnStyle()}
                            disabled={loading || is_pending || is_approved || is_expired}
                            title={redeemTitle}
                            onPress={() => redeemAction()}
                        />
                        <Button
                            containerStyle={styles.btnContainer}
                            disabled={!is_pending || loading}
                            title={'Manual Actions'}
                            onPress={() => manualActions()}
                        />
                        <Text style={styles.idHeader}>{i18n.t('transactionId')}</Text>
                        <Text style={styles.idText}>{voucher.id}</Text>
                        {voucher?.redemptionLocation && (
                            <RedemptionLocation reward={voucher}></RedemptionLocation>
                        )}
                        {approvedRedemption && approvedRedemption.redemptionLocation && (
                            <DisplayRedemptionLocation
                                approvedRedemption={approvedRedemption}
                            ></DisplayRedemptionLocation>
                        )}
                        {voucher.restriction && (
                            <Text style={styles.disclaimerHeader}>
                                {i18n.t('voucher')}
                                {i18n.t('tc')}
                            </Text>
                        )}
                        <Text style={styles.disclaimer}>{voucher.restriction || ''}</Text>
                    </View>
                </ScrollView>
            );
        } else {
            return <Error />;
        }
    }
};

VoucherDetail.navigationOptions = {
    title: 'Rewards',
    headerTintColor: '#212322',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { VoucherDetail };
