import React from 'react';
import moment from 'moment';
import { Avatar } from 'react-native-elements';
import { StyleSheet, View, Text, StyleProp, ViewStyle } from 'react-native';
import { ActivityComment } from '../../../../store/types';
import { Ionicons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        width: '100%',
    },
    left: {
        paddingLeft: 8,
        paddingRight: 16,
    },
    middle: {
        //flexGrow: 1,
        //display: 'flex',
        //flexDirection: 'column',
    },
    right: {
        justifyContent: 'center',
    },
    likesIcon: {},
    likes: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 8,
    },
    posterMeta: {
        marginBottom: 4,
    },
});
/*

 */

const timeSince = function (date: Date) {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    let intervalType;

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'y';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'mo';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'd';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = 'h';
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = 'm';
                    } else {
                        interval = seconds;
                        intervalType = 'now';
                    }
                }
            }
        }
    }

    return interval + intervalType;
};
export const CommentBubble = (props: { comment: ActivityComment; containerStyle?: StyleProp<ViewStyle> }) => {
    const { comment, containerStyle } = props;
    return (
        <View style={[styles.root, containerStyle]}>
            <View style={styles.left}>
                <Avatar rounded source={{ uri: comment.image }} />
            </View>
            <View style={{ flexShrink: 1 }}>
                <View style={styles.posterMeta}>
                    <Text style={{ color: 'gray', fontWeight: 'bold', fontSize: 10 }}>{comment.poster}</Text>
                </View>
                <View>
                    <Text style={{ textAlign: 'left' }}>
                        {comment.text}{' '}
                        <Text style={{ color: 'gray', fontSize: 10 }}>
                            {timeSince(moment(comment.createdAt).toDate())}
                        </Text>
                    </Text>
                </View>
            </View>
            <View style={styles.right}>
                <View style={styles.likes}>
                    <Ionicons style={styles.likesIcon} name="ios-heart-outline" color="blue" />
                    <Text>{comment.likes || 0}</Text>
                </View>
            </View>
        </View>
    );
};
