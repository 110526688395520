import * as React from 'react';
import { Card } from 'react-native-elements';
import { StyleSheet, View, Text, Image, Pressable } from 'react-native';
import moment from 'moment';

const HistoryCard = (props: any) => {
    const { reward,navigation} = props;
    const showRewardDetails = () => {
      navigation.push('RewardDetails', { reward, rewardType: `${reward.__typename.toLowerCase()}s`});
    };
    return (
        <Card>
          <Pressable onPress={showRewardDetails}>
            <View>
                {reward.description && <Text>{reward.description}</Text>}
                <Text>Redeemed on: {moment(reward.redeemedAt).format('lll')}</Text>
            </View>

          </Pressable>
        </Card>
    );
};

export { HistoryCard };
