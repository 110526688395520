import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
// @ts-ignore
import { CLIENT_NAME } from '@env';

const styles = StyleSheet.create({
    root: {
        shadowColor: '#00000021',
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.37,
        shadowRadius: 7.49,
        elevation: 12,

        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        backgroundColor: 'white',
        padding: 10,
        flexDirection: 'row',
        borderRadius: 30,
    },
    details: {
        marginLeft: 20,
        marginTop: 10,
    },
    hidden: {
        display: 'none',
    },
    text: {},
    image: {
        width: 90,
        height: 90,
        borderRadius: 45,
        borderWidth: 2,
        borderColor: '#ebf0f7',
    },
    locationName: {
        fontWeight: 'bold',
    },
});

const RedemptionLocation = ({ reward }: { reward: any }) => {
    return (
        <View style={styles.root}>
            <Image
                style={styles.image}
                source={require(`../../assets/images/${CLIENT_NAME}/shopIcon.png`)}
            ></Image>
            <View style={styles.details}>
                <Text style={styles.locationName}>{reward.redemptionLocation.name}</Text>
                <Text style={styles.text}>{reward.redemptionLocation.street1}</Text>
                <Text style={[styles.text, !reward.redemptionLocation.street2 && styles.hidden]}>
                    {reward.redemptionLocation.street2}
                </Text>
                <Text style={styles.text}>
                    {reward.redemptionLocation.city}, {reward.redemptionLocation.state}
                </Text>
                <Text style={styles.text}>{reward.redemptionLocation.postalCode}</Text>
            </View>
        </View>
    );
};

export default RedemptionLocation;
