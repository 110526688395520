import gql from 'graphql-tag';

export const GET_OPPORTUNITY_CLAIM = gql`
    query($opportunityId: ID!) {
        opportunityClaims(where: { opportunity: { id: $opportunityId } }) {
            id
            submitted
            verifications {
                id
                purchaseAmount
                task {
                    id
                    type
                    repeats
                    minPurchase
                    repeatLimit
                    description
                    instructions
                    survey {
                        id
                        fields {
                            id
                            maxChars
                            label
                            type
                            options
                            placeholder
                        }
                    }
                    verificationType
                }
                text
                images
                started
                submitted
                approved
                reviewed
                submittedAt
                startedAt
                approvedAt
                createdAt
                rejected
                rejectedReason
                surveyResponses {
                    id
                    fieldId
                    response
                }
                surveyResponses {
                    id
                    fieldId
                    response
                }
            }
            completed
            submitted
        }
    }
`;
export const GET_TASK = gql`
    query($taskId: ID!) {
        task(where: { id: $taskId }) {
            id
            type
            description
            survey {
                id
                fields {
                    id
                    maxChars
                    label
                    type
                    options
                    placeholder
                }
            }
            verificationType
        }
    }
`
