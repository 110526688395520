import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import Feed from '../screens/Feed';
import Notifications from '../screens/Notifications';

export default function MainTabs() {
    const ActivityFeedStack = createMaterialTopTabNavigator();

    return (
        <ActivityFeedStack.Navigator
            tabBarOptions={{
                labelStyle: {},
                tabStyle: {},
                style: {},
            }}
        >
            <ActivityFeedStack.Screen name="Main Feed" component={Feed} />
            <ActivityFeedStack.Screen name="Notifications" component={Notifications} />
        </ActivityFeedStack.Navigator>
    );
}
