import {
    ActivityIndicator,
    Image,
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    Modal,
    TextInput,
    TouchableOpacity,
    KeyboardAvoidingView
} from "react-native";
import React, { useRef, useState } from 'react';
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client";
import { IAsset, MediaTypeValue } from "../../types";
import Error from "../Error"
import Colors from "../../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import { Video, AVPlaybackStatus } from 'expo-av';
import { LinearProgress } from "react-native-elements";
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';

interface AssetViewProps {
    asset_id: string,
    editable?: boolean;
    selected?: boolean;
}

const ASSET_QUERY = gql`
    query($asset_id:ID!){
        asset(where:{id:$asset_id}){
            id
            uri
            mediaType
            mediaSubtypes
            width
            height
            duration
            exif
            caption
            createdAt
            updatedAt
        }
    }
`;

const ASSET_UPDATE_CAPTION = gql`
    mutation ($asset_id:ID!, $caption: String!){
        updateAsset(where:{id:$asset_id}, data:{caption:$caption}){
            id
            uri
            mediaType
            mediaSubtypes
            width
            height
            duration
            exif
            caption
            createdAt
            updatedAt
        }
    }
`;

function TransparentButton(props: any) {
    return <TouchableOpacity style={styles.transparentButton} onPress={props.onPress}>
        <Text style={{ alignSelf: "center" }}>
            <Ionicons name="ios-create" size={14} color="white"/>
            <Text style={{ fontSize: 12, color: "white", fontWeight: 'bold', padding: 0 }}>{props.children}</Text>
        </Text>
    </TouchableOpacity>

}

function OverAsset(props: { asset: IAsset, editable?: boolean }) {
    const editable = props.editable !== null && props.editable !== undefined ? props.editable : false;
    const textRef = useRef<any>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [updateCaption, { loading, error }] = useMutation<any, { caption: string, asset_id: string }>(ASSET_UPDATE_CAPTION);
    const [caption, setCaption] = useState<string>("");
    const saveCaption = (caption: string) => {
        if (caption.trim() != "") {
            updateCaption({ variables: { asset_id: props.asset.id, caption: caption.trim() } }).catch(e => {
                console.error(e);
                setShowModal(false);
            }).then((r) => {
                setShowModal(false);
            });
        }

    };
    const startEdit = () => {
        if (props.editable) {
            if (props.asset.caption) {
                setCaption(props.asset.caption)
            } else {
                setCaption("")
            }
            setShowModal(true);
        }
    };
    const { asset } = props;
    const hasCaption = asset.caption && asset.caption.trim() != "";


    return <View style={styles.overAsset}>
        <Modal
            animated
            animationType="slide"
            visible={showModal}
            onShow={() => textRef.current.focus()}
            onRequestClose={() => setShowModal(false)}
            transparent>
            <View style={styles.overlay}>
                <KeyboardAvoidingView behavior={"padding"}>
                    <View style={styles.modalContainer}>
                        <View style={{ marginBottom: 16, paddingHorizontal: 8 }}>
                            <TouchableOpacity onPress={() => setShowModal(false)}>
                                <Text style={{ alignSelf: 'flex-end', fontWeight: 'bold' }}>x</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'flex-end' }}>
                            <TextInput multiline={true}
                                       ref={textRef}
                                       style={{
                                           flexGrow: 1,
                                           flex: 1,
                                           marginRight: 4
                                       }}
                                       value={caption}
                                       editable={!loading}
                                       onKeyPress={(e) => {
                                           if (e.nativeEvent.key == "Enter") {
                                               e.preventDefault();
                                               saveCaption(caption);
                                           }
                                       }}
                                       onChangeText={(t) => setCaption(t.replace(/(\r\n|\n|\r)/gm, ""))}
                            />
                            <AwesomeButtonThemed
                                type="primary"
                                height={30}
                                width={80}
                                disabled={loading || caption.trim() == ""}
                                progress={false}
                                onPress={() => saveCaption(caption)}>
                                Save
                            </AwesomeButtonThemed>
                        </View>
                    </View>
                </KeyboardAvoidingView>

            </View>
        </Modal>
        {editable ? <TransparentButton onPress={startEdit}>Set Caption</TransparentButton> : null}
        {hasCaption ? <View style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
            <View style={{ width: "70%" }}>
                <TouchableOpacity onPress={startEdit}>
                    <Text style={{ color: "white" }}>{asset.caption}</Text>
                </TouchableOpacity>
            </View>
            <View style={{ width: "30%" }}/>
        </View> : null}
    </View>

}

export function AssetView(props: AssetViewProps) {
    const { data, error, loading } = useQuery<{ asset: IAsset }, { asset_id: string }>(ASSET_QUERY, { variables: { asset_id: props.asset_id } });
    const [videoProgress, setVideoProgress] = useState<number>(0);
    const selected = props.selected !== null && props.selected !== undefined ? props.selected : false;
    if (loading) {
        return <View style={styles.loading}>
            <ActivityIndicator size={'large'}/>
        </View>
    }
    //query asset
    if (error || !data || !data.asset) {
        return <Error/>
    }
    return (<SafeAreaView style={styles.root}>
        {data.asset.mediaType == MediaTypeValue.Photo ?
            <Image style={[StyleSheet.absoluteFill]} source={{ uri: data.asset.uri }}/> :
            <View style={[StyleSheet.absoluteFill, styles.videoContainer]}>
                <Video isLooping
                       useNativeControls={false}
                       isMuted={false}
                       shouldPlay={selected}
                       style={{ width: "100%", flexGrow: 1 }}
                       source={{ uri: data.asset.uri }}
                       resizeMode={'cover'}
                       onPlaybackStatusUpdate={(status: AVPlaybackStatus) => {
                           if (status.isLoaded) {
                               if (status.positionMillis) {
                                   if (status.durationMillis && status.durationMillis !== 0) {
                                       setVideoProgress(status.positionMillis / status.durationMillis)
                                   }
                               }
                           }

                       }}
                />
                <LinearProgress color="primary"
                                variant={"determinate"}
                                value={videoProgress}/>
            </View>}
        <OverAsset asset={data.asset} editable={props.editable}/>
    </SafeAreaView>);


}

const styles = StyleSheet.create({
    root: {
        height: '100%',
        width: '100%',
    },
    asset: {
        flexGrow: 1,
    },
    videoContainer: {
        display: "flex"
    },
    overAsset: {
        width: "100%",
        height: "100%",
        justifyContent: 'flex-end',
        paddingVertical: 32,
        paddingHorizontal: 8,
        backgroundColor: 'rgba(52, 52, 52, 0.3)'
    },
    transparentButton: {
        padding: 4,
        borderRadius: 4,
        elevation: 8,
        alignSelf: 'flex-start',
        backgroundColor: 'rgba(52, 52, 52, 0.7)'
    },
    loading: {
        backgroundColor: Colors.dark.background,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalContainer: {
        backgroundColor: 'white',
        paddingVertical: 12,
        paddingHorizontal: 8,
        borderTopRightRadius: 12,
        borderTopLeftRadius: 12,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },

});
