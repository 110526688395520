import React from 'react';
import { Card } from 'react-native-elements';
import { StyleSheet, View, Text, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { ActivityComment, Post } from '../../../store/types';
import { PostHeader } from './PostHeader';
import { PostActions } from './PostActions';

const styles = StyleSheet.create({
    middle: { marginTop: 4, marginBottom: 8 },
});

export const CampaignPost = (props: {
    post: Post;
    containerStyle?: StyleProp<ViewStyle>;
    OnOpenComments: (cs: ActivityComment[]) => void;
}) => {
    const { post, containerStyle, OnOpenComments } = props;
    return (
        <Card containerStyle={containerStyle}>
            <PostHeader post={post} />
            <View style={styles.middle}>
                <Hyperlink linkDefault={true}>
                    <Text>{post.body}</Text>
                </Hyperlink>
            </View>
            <Card.Divider />
        </Card>
    );
};
