import * as React from 'react';
import Svg, { SvgProps, G, Defs, Path, ClipPath, Use, LinearGradient, Stop } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
    return (
        <Svg viewBox="0 0 1600 900" {...props}>
            <G opacity={0.22}>
                <Defs>
                    <Path id="prefix__a" d="M0 0h1600v900H0z" />
                </Defs>
                <ClipPath id="prefix__b">
                    <Use xlinkHref="#prefix__a" />
                </ClipPath>
                <LinearGradient
                    id="prefix__c"
                    gradientUnits="userSpaceOnUse"
                    x1={522.352}
                    y1={1075.939}
                    x2={1235.604}
                    y2={1075.939}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__c)"
                    d="M522.35 1411.66l20.91 20.91 692.34-692.35-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__d"
                    gradientUnits="userSpaceOnUse"
                    x1={1044.425}
                    y1={659.327}
                    x2={1379.502}
                    y2={659.327}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__d)"
                    d="M1044.42 805.96l20.91 20.9L1379.5 512.7l-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__e"
                    gradientUnits="userSpaceOnUse"
                    x1={692.726}
                    y1={475.668}
                    x2={1262.225}
                    y2={475.668}
                    gradientTransform="matrix(1.1303 -.1303 -.1303 1.1303 6.107 -6.107)"
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__e)"
                    d="M692.74 739.5l20.9 20.91L1405.2 68.85l-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__f"
                    gradientUnits="userSpaceOnUse"
                    x1={409.72}
                    y1={605.882}
                    x2={950.286}
                    y2={605.882}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__f)"
                    d="M409.72 855.26l20.91 20.91 519.66-519.66-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__g"
                    gradientUnits="userSpaceOnUse"
                    x1={828.783}
                    y1={-49.876}
                    x2={1340.968}
                    y2={-49.876}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__g)"
                    d="M828.78 185.31l20.91 20.91 491.28-491.28-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__h"
                    gradientUnits="userSpaceOnUse"
                    x1={274.06}
                    y1={253.957}
                    x2={786.245}
                    y2={253.957}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__h)"
                    d="M274.06 489.14l20.91 20.91L786.25 18.77l-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__i"
                    gradientUnits="userSpaceOnUse"
                    x1={-146.681}
                    y1={617.536}
                    x2={145.314}
                    y2={617.536}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__i)"
                    d="M-146.68 742.62l20.91 20.91 271.08-271.08-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__j"
                    gradientUnits="userSpaceOnUse"
                    x1={20.576}
                    y1={757.333}
                    x2={367.493}
                    y2={757.333}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__j)"
                    d="M20.58 909.88l20.91 20.91 326-326-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__k"
                    gradientUnits="userSpaceOnUse"
                    x1={104.206}
                    y1={826.343}
                    x2={480.362}
                    y2={826.343}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__k)"
                    d="M104.21 993.51l20.9 20.91 355.25-355.25-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__l"
                    gradientUnits="userSpaceOnUse"
                    x1={313.279}
                    y1={1088.847}
                    x2={582.578}
                    y2={1088.847}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__l)"
                    d="M313.28 1202.59l20.91 20.9 248.39-248.38-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__m"
                    gradientUnits="userSpaceOnUse"
                    x1={1070.585}
                    y1={268.274}
                    x2={1633.67}
                    y2={268.274}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__m)"
                    d="M1070.58 528.91l20.91 20.91L1633.67 7.64l-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__n"
                    gradientUnits="userSpaceOnUse"
                    x1={1580.602}
                    y1={138.71}
                    x2={1717.3}
                    y2={138.71}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__n)"
                    d="M1580.6 186.15l20.91 20.91L1717.3 91.27l-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__o"
                    gradientUnits="userSpaceOnUse"
                    x1={396.908}
                    y1={1094.099}
                    x2={822.955}
                    y2={1094.099}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__o)"
                    d="M396.91 1286.21l20.91 20.91 405.14-405.14-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__p"
                    gradientUnits="userSpaceOnUse"
                    x1={688.367}
                    y1={955.718}
                    x2={1142.773}
                    y2={955.718}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__p)"
                    d="M688.37 1162.01l20.9 20.91 433.5-433.5-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__q"
                    gradientUnits="userSpaceOnUse"
                    x1={1315.874}
                    y1={444.852}
                    x2={1704.258}
                    y2={444.852}
                    gradientTransform="rotate(180 1408.888 546.03)"
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__q)"
                    d="M1501.9 473.92l-20.91-20.9-367.47 367.47 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__r"
                    gradientUnits="userSpaceOnUse"
                    x1={988.03}
                    y1={-4.056}
                    x2={1424.597}
                    y2={-4.056}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__r)"
                    d="M988.03 193.32l20.91 20.91 415.66-415.66-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__s"
                    gradientUnits="userSpaceOnUse"
                    x1={1271.83}
                    y1={26.945}
                    x2={1580.568}
                    y2={26.945}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__s)"
                    d="M1271.83 160.41l20.91 20.9 287.83-287.83-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__t"
                    gradientUnits="userSpaceOnUse"
                    x1={62.393}
                    y1={860.867}
                    x2={285.868}
                    y2={860.867}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__t)"
                    d="M62.39 951.7l20.91 20.91 202.57-202.57-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__u"
                    gradientUnits="userSpaceOnUse"
                    x1={229.651}
                    y1={974.376}
                    x2={560.624}
                    y2={974.376}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__u)"
                    d="M229.65 1118.96l20.91 20.9L560.62 829.8l-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__v"
                    gradientUnits="userSpaceOnUse"
                    x1={561.67}
                    y1={688.173}
                    x2={1135.532}
                    y2={688.173}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__v)"
                    d="M561.67 954.2l20.91 20.91 552.95-552.96-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__w"
                    gradientUnits="userSpaceOnUse"
                    x1={1530.832}
                    y1={314.713}
                    x2={1749.579}
                    y2={314.713}
                    gradientTransform="matrix(1.4092 -.4092 -.4092 1.4092 -665.325 665.325)"
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__w)"
                    d="M1326.94 607.07l20.91 20.91 359.75-359.75-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__x"
                    gradientUnits="userSpaceOnUse"
                    x1={802.048}
                    y1={792.606}
                    x2={1020.8}
                    y2={792.606}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__x)"
                    d="M802.05 881.08l20.91 20.9 197.84-197.85-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__y"
                    gradientUnits="userSpaceOnUse"
                    x1={480.538}
                    y1={1276.383}
                    x2={709.273}
                    y2={1276.383}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__y)"
                    d="M480.54 1369.84l20.9 20.91 207.83-207.83-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__z"
                    gradientUnits="userSpaceOnUse"
                    x1={892.651}
                    y1={405.515}
                    x2={1369.862}
                    y2={405.515}
                    gradientTransform="matrix(-1.2607 .2607 .2607 -1.2607 2776.722 296.822)"
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__z)"
                    d="M1813.83-256.15l-20.91-20.9-694.22 694.21 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__A"
                    gradientUnits="userSpaceOnUse"
                    x1={264.961}
                    y1={477.673}
                    x2={849.69}
                    y2={477.673}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__A)"
                    d="M264.96 749.13l20.91 20.91 563.82-563.82-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__B"
                    gradientUnits="userSpaceOnUse"
                    x1={854.847}
                    y1={-188.351}
                    x2={1257.339}
                    y2={-188.351}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__B)"
                    d="M854.85-8.01l20.9 20.9 381.59-381.58-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__C"
                    gradientUnits="userSpaceOnUse"
                    x1={203.225}
                    y1={-74.865}
                    x2={845.694}
                    y2={-74.865}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__C)"
                    d="M203.22 225.46l20.91 20.91 621.56-621.56-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__D"
                    gradientUnits="userSpaceOnUse"
                    x1={469.049}
                    y1={30.186}
                    x2={871.539}
                    y2={30.186}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__D)"
                    d="M469.05 210.52l20.91 20.91 381.58-381.58-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__E"
                    gradientUnits="userSpaceOnUse"
                    x1={-63.051}
                    y1={668.151}
                    x2={294.968}
                    y2={668.151}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__E)"
                    d="M-63.05 826.25l20.9 20.91 337.12-337.11-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__F"
                    gradientUnits="userSpaceOnUse"
                    x1={589.789}
                    y1={402.892}
                    x2={1008.938}
                    y2={402.892}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__F)"
                    d="M589.79 591.56l20.91 20.9 398.24-398.23-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__G"
                    gradientUnits="userSpaceOnUse"
                    x1={271.464}
                    y1={960.59}
                    x2={713.644}
                    y2={960.59}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__G)"
                    d="M271.46 1160.77l20.91 20.91 421.27-421.27-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__H"
                    gradientUnits="userSpaceOnUse"
                    x1={1228.015}
                    y1={1.397}
                    x2={1508.224}
                    y2={1.397}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__H)"
                    d="M1228.01 120.59l20.91 20.91 259.3-259.3-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__I"
                    gradientUnits="userSpaceOnUse"
                    x1={1436.024}
                    y1={273.738}
                    x2={1759.083}
                    y2={273.738}
                    gradientTransform="matrix(-1.1391 .1391 .1391 -1.1391 3337.25 405.333)"
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__I)"
                    d="M1759.11 133.09l-20.9-20.91-386.22 386.21 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__J"
                    gradientUnits="userSpaceOnUse"
                    x1={355.093}
                    y1={1169.365}
                    x2={546.976}
                    y2={1169.365}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__J)"
                    d="M355.09 1244.4l20.91 20.91 170.98-170.98-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__K"
                    gradientUnits="userSpaceOnUse"
                    x1={999.894}
                    y1={366.341}
                    x2={1675.485}
                    y2={366.341}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__K)"
                    d="M999.89 683.23l20.91 20.9 654.69-654.67-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__L"
                    gradientUnits="userSpaceOnUse"
                    x1={714.639}
                    y1={-55.526}
                    x2={1299.151}
                    y2={-55.526}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__L)"
                    d="M714.64 215.82l20.9 20.91 563.61-563.61-20.9-20.9z"
                />
                <LinearGradient
                    id="prefix__M"
                    gradientUnits="userSpaceOnUse"
                    x1={-21.237}
                    y1={650.361}
                    x2={455.993}
                    y2={650.361}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__M)"
                    d="M-21.24 868.07l20.91 20.91 456.32-456.33-20.9-20.9z"
                />
                <LinearGradient
                    id="prefix__N"
                    gradientUnits="userSpaceOnUse"
                    x1={459.454}
                    y1={348.593}
                    x2={1080.614}
                    y2={348.593}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__N)"
                    d="M459.45 638.27l20.91 20.9 600.25-600.25-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__O"
                    gradientUnits="userSpaceOnUse"
                    x1={926.898}
                    y1={89.233}
                    x2={1466.411}
                    y2={89.233}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__O)"
                    d="M926.9 338.08l20.9 20.91 518.61-518.61-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__P"
                    gradientUnits="userSpaceOnUse"
                    x1={187.836}
                    y1={976.654}
                    x2={430.628}
                    y2={976.654}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__P)"
                    d="M187.84 1077.14l20.9 20.91 221.89-221.88-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__Q"
                    gradientUnits="userSpaceOnUse"
                    x1={526.067}
                    y1={811.085}
                    x2={1092.563}
                    y2={811.085}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__Q)"
                    d="M526.07 1073.42l20.91 20.91 545.58-545.58-20.9-20.91z"
                />
                <LinearGradient
                    id="prefix__R"
                    gradientUnits="userSpaceOnUse"
                    x1={438.722}
                    y1={948.293}
                    x2={1240.014}
                    y2={948.293}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__R)"
                    d="M438.72 1328.03l20.91 20.91 780.38-780.39-20.9-20.9z"
                />
                <LinearGradient
                    id="prefix__S"
                    gradientUnits="userSpaceOnUse"
                    x1={539.718}
                    y1={475.195}
                    x2={1248.923}
                    y2={475.195}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__S)"
                    d="M539.72 808.89l20.9 20.91 688.3-688.3-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__T"
                    gradientUnits="userSpaceOnUse"
                    x1={146.022}
                    y1={823.898}
                    x2={610.697}
                    y2={823.898}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__T)"
                    d="M146.02 1035.33l20.91 20.91L610.7 612.47l-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__U"
                    gradientUnits="userSpaceOnUse"
                    x1={1059.706}
                    y1={-102.616}
                    x2={1382.78}
                    y2={-102.616}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__U)"
                    d="M1059.71 38.01l20.9 20.91 302.17-302.17-20.91-20.9z"
                />
                <LinearGradient
                    id="prefix__V"
                    gradientUnits="userSpaceOnUse"
                    x1={346.585}
                    y1={409.558}
                    x2={737.039}
                    y2={409.558}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__V)"
                    d="M346.58 583.88l20.91 20.91 369.55-369.55-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__W"
                    gradientUnits="userSpaceOnUse"
                    x1={1219.105}
                    y1={377.354}
                    x2={1601.511}
                    y2={377.354}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__W)"
                    d="M1219.11 547.65l20.9 20.9 361.5-361.49-20.91-20.91z"
                />
                <LinearGradient
                    id="prefix__X"
                    gradientUnits="userSpaceOnUse"
                    x1={-199.797}
                    y1={-77.612}
                    x2={417.921}
                    y2={-77.612}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__X)"
                    d="M417.92-365.56l-20.91-20.91-596.81 596.81 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__Y"
                    gradientUnits="userSpaceOnUse"
                    x1={-355.755}
                    y1={466.49}
                    x2={-179.814}
                    y2={466.49}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__Y)"
                    d="M-179.81 399.43l-20.91-20.91-155.03 155.03 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__Z"
                    gradientUnits="userSpaceOnUse"
                    x1={160.368}
                    y1={-231.946}
                    x2={700.938}
                    y2={-231.946}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__Z)"
                    d="M700.94-481.32l-20.91-20.91L160.37 17.43l20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aa"
                    gradientUnits="userSpaceOnUse"
                    x1={-230.31}
                    y1={452.683}
                    x2={224.13}
                    y2={452.683}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aa)"
                    d="M224.13 246.37l-20.91-20.91L-230.31 659l20.91 20.9z"
                />
                <LinearGradient
                    id="prefix__ab"
                    gradientUnits="userSpaceOnUse"
                    x1={743.161}
                    y1={-383.397}
                    x2={1090.079}
                    y2={-383.397}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ab)"
                    d="M1090.08-535.95l-20.91-20.9-326.01 326 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ac"
                    gradientUnits="userSpaceOnUse"
                    x1={630.294}
                    y1={-452.407}
                    x2={1006.45}
                    y2={-452.407}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ac)"
                    d="M1006.45-619.58l-20.91-20.91-355.25 355.25 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ad"
                    gradientUnits="userSpaceOnUse"
                    x1={-313.938}
                    y1={377.991}
                    x2={122.627}
                    y2={377.991}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ad)"
                    d="M122.63 180.62l-20.91-20.91-415.66 415.66 20.91 20.9z"
                />
                <LinearGradient
                    id="prefix__ae"
                    gradientUnits="userSpaceOnUse"
                    x1={824.786}
                    y1={-486.93}
                    x2={1048.265}
                    y2={-486.93}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ae)"
                    d="M1048.26-577.76l-20.9-20.91L824.79-396.1l20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__af"
                    gradientUnits="userSpaceOnUse"
                    x1={850.632}
                    y1={-311.689}
                    x2={1173.708}
                    y2={-311.689}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__af)"
                    d="M1173.71-452.32l-20.91-20.91-302.17 302.17 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ag"
                    gradientUnits="userSpaceOnUse"
                    x1={435.086}
                    y1={211.742}
                    x2={876.907}
                    y2={211.742}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ag)"
                    d="M876.91 11.74L856-9.17 435.09 411.75l20.9 20.9z"
                />
                <LinearGradient
                    id="prefix__ah"
                    gradientUnits="userSpaceOnUse"
                    x1={124.408}
                    y1={236.54}
                    x2={636.221}
                    y2={236.54}
                >
                    <Stop offset={0} stopColor="#666" />
                    <Stop offset={1} stopColor="#d9d9d9" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ah)"
                    d="M636.22 1.54l-20.91-20.91-490.9 490.91 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__ai"
                    gradientUnits="userSpaceOnUse"
                    x1={101.72}
                    y1={-28.957}
                    x2={520.868}
                    y2={-28.957}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ai)"
                    d="M520.87-217.62l-20.91-20.91-398.24 398.24 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aj"
                    gradientUnits="userSpaceOnUse"
                    x1={-397.569}
                    y1={294.363}
                    x2={38.997}
                    y2={294.363}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aj)"
                    d="M39 96.99L18.09 76.08l-415.66 415.66 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ak"
                    gradientUnits="userSpaceOnUse"
                    x1={-188.496}
                    y1={448.83}
                    x2={357.277}
                    y2={448.83}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ak)"
                    d="M357.28 196.85l-20.91-20.91-524.87 524.87 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__al"
                    gradientUnits="userSpaceOnUse"
                    x1={615.313}
                    y1={-256.752}
                    x2={1131.894}
                    y2={-256.752}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__al)"
                    d="M1131.89-494.13l-20.9-20.91L615.31-19.37l20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__am"
                    gradientUnits="userSpaceOnUse"
                    x1={30.044}
                    y1={25.342}
                    x2={651.2}
                    y2={25.342}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__am)"
                    d="M651.2-264.33l-20.91-20.91L30.04 315.01l20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__an"
                    gradientUnits="userSpaceOnUse"
                    x1={-200.72}
                    y1={207.188}
                    x2={183.761}
                    y2={207.188}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__an)"
                    d="M183.76 35.86l-20.91-20.91-363.57 363.57 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ao"
                    gradientUnits="userSpaceOnUse"
                    x1={18.091}
                    y1={-179.438}
                    x2={570.937}
                    y2={-179.438}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ao)"
                    d="M570.94-434.95l-20.91-20.91L18.09 76.08 39 96.99z"
                />
                <LinearGradient
                    id="prefix__ap"
                    gradientUnits="userSpaceOnUse"
                    x1={499.961}
                    y1={-449.961}
                    x2={964.635}
                    y2={-449.961}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ap)"
                    d="M964.63-661.39l-20.9-20.91-443.77 443.77 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aq"
                    gradientUnits="userSpaceOnUse"
                    x1={-272.124}
                    y1={476.552}
                    x2={50.952}
                    y2={476.552}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aq)"
                    d="M50.95 335.92l-20.91-20.91-302.16 302.17 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__ar"
                    gradientUnits="userSpaceOnUse"
                    x1={336.372}
                    y1={-16.998}
                    x2={764.071}
                    y2={-16.998}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ar)"
                    d="M764.07-209.94l-20.91-20.91-406.79 406.79 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__as"
                    gradientUnits="userSpaceOnUse"
                    x1={765.337}
                    y1={-206.319}
                    x2={1215.521}
                    y2={-206.319}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__as)"
                    d="M1215.52-410.5l-20.91-20.91L765.34-2.13l20.91 20.9z"
                />
                <LinearGradient
                    id="prefix__at"
                    gradientUnits="userSpaceOnUse"
                    x1={-104.868}
                    y1={507.936}
                    x2={489.957}
                    y2={507.936}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__at)"
                    d="M489.96 231.43l-20.91-20.91-573.92 573.92 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__au"
                    gradientUnits="userSpaceOnUse"
                    x1={1171.306}
                    y1={594.249}
                    x2={1884.56}
                    y2={594.249}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__au)"
                    d="M1884.56 258.53l-20.91-20.91-692.34 692.35 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__av"
                    gradientUnits="userSpaceOnUse"
                    x1={1047.652}
                    y1={939.072}
                    x2={1485.828}
                    y2={939.072}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__av)"
                    d="M1485.83 740.89l-20.91-20.91-417.27 417.27 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aw"
                    gradientUnits="userSpaceOnUse"
                    x1={1142.064}
                    y1={1068.225}
                    x2={1634.883}
                    y2={1068.225}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aw)"
                    d="M1162.97 1314.63l-20.91-20.9 471.92-471.92 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__ax"
                    gradientUnits="userSpaceOnUse"
                    x1={1136.566}
                    y1={1127.125}
                    x2={1355.321}
                    y2={1127.125}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ax)"
                    d="M1157.48 1236.5l-20.91-20.9 197.84-197.85 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__ay"
                    gradientUnits="userSpaceOnUse"
                    x1={1685.253}
                    y1={462.72}
                    x2={1968.188}
                    y2={462.72}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__ay)"
                    d="M1968.19 342.16l-20.91-20.91-262.03 262.03 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__az"
                    gradientUnits="userSpaceOnUse"
                    x1={1573.304}
                    y1={330.527}
                    x2={1842.745}
                    y2={330.527}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__az)"
                    d="M1842.75 216.71l-20.91-20.9-248.54 248.53 20.91 20.9z"
                />
                <LinearGradient
                    id="prefix__aA"
                    gradientUnits="userSpaceOnUse"
                    x1={1334.411}
                    y1={700.862}
                    x2={2010.003}
                    y2={700.862}
                >
                    <Stop offset={0} stopColor="#d9d9d9" />
                    <Stop offset={1} stopColor="#333" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aA)"
                    d="M1355.32 1038.66l-20.91-20.91 654.69-654.68 20.9 20.91z"
                />
                <LinearGradient
                    id="prefix__aB"
                    gradientUnits="userSpaceOnUse"
                    x1={1613.977}
                    y1={623.803}
                    x2={2051.817}
                    y2={623.803}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aB)"
                    d="M1634.88 842.72l-20.9-20.91 416.93-416.93 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aC"
                    gradientUnits="userSpaceOnUse"
                    x1={974.476}
                    y1={949.57}
                    x2={1705.261}
                    y2={949.57}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aC)"
                    d="M1705.26 605.09l-20.91-20.91-709.87 709.88 20.9 20.9z"
                />
                <LinearGradient
                    id="prefix__aD"
                    gradientUnits="userSpaceOnUse"
                    x1={564.168}
                    y1={1140.108}
                    x2={1232.718}
                    y2={1140.108}
                >
                    <Stop offset={0} stopColor="#ccc" />
                    <Stop offset={1} stopColor="#f2f2f2" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aD)"
                    d="M1232.72 826.74l-20.91-20.91-647.64 647.64 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aE"
                    gradientUnits="userSpaceOnUse"
                    x1={1211.809}
                    y1={635.539}
                    x2={1594.213}
                    y2={635.539}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aE)"
                    d="M1594.21 465.24l-20.91-20.9-361.49 361.49 20.91 20.91z"
                />
                <LinearGradient
                    id="prefix__aF"
                    gradientUnits="userSpaceOnUse"
                    x1={1446.652}
                    y1={519.298}
                    x2={1926.374}
                    y2={519.298}
                >
                    <Stop offset={0} stopColor="#ececec" />
                    <Stop offset={1} stopColor="#999" />
                </LinearGradient>
                <Path
                    opacity={0.65}
                    clipPath="url(#prefix__b)"
                    fill="url(#prefix__aF)"
                    d="M1926.37 300.34l-20.9-20.9-458.82 458.81 20.91 20.91z"
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
