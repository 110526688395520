import gql from 'graphql-tag';

//get current user profile
export const GET_OPPORTUNITIES = gql`
    query($first: Int!, $after: String) {
        opportunities(where: { active: true }, first: $first, after: $after, orderBy: createdAt_DESC) {
            name
            id
            image
            startDate
            endDate
            createdAt
            tasks {
                id
                type
                description
                instructions
                verificationType
            }
            reward {
                id
                claimPerCustomerLimit
                type
                variable
                low
                high
                voucherTiers
                tiers
                percentage
                minPurchase
            }
            oneLineDescription
            detailedDescription
            claimed
            instanceLimit
            perpetual
        }
    }
`;

export const GET_OPPORTUNITY = gql`
    query($id: ID!) {
        opportunity(where: { id: $id }) {
            name
            id
            image
            startDate
            endDate
            tasks {
                id
                type
                description
                instructions
                verificationType
            }
            reward {
                id
                claimPerCustomerLimit
                type
                redemptionLocations {
                    id
                }
                variable
                low
                high
                voucherTiers
                tiers
                percentage
                minPurchase
            }
            oneLineDescription
            detailedDescription
            claimed
            instanceLimit
            perpetual
        }
    }
`;
export const GET_CLAIM_BY_OPPORTUNITY = gql`
    query($opportunityId: ID!) {
        opportunityClaims(where: { opportunity: { id: $opportunityId } }) {
            id
            submitted
            rejected
            verifications {
                id
                link
                lat
                long
                task {
                    id
                    survey {
                        id
                        fields {
                            id
                        }
                    }
                }
                approved
                rejected
                assets {
                    id
                    uri
                    mediaType
                    mediaSubtypes
                    width
                    height
                    duration
                    exif
                    caption
                    createdAt
                    updatedAt
                }
                rejectedReason
                text
                purchaseAmount
                surveyResponses {
                    id
                    response
                    fieldId
                }
                approvedBy {
                    firstName
                    lastName
                }
                rejectedBy {
                    firstName
                    lastName
                }
            }
            completed
            purchaseAmount
            completedAt
            submittedAt
        }
    }
`;

export const BOOK_MARK_LIST = gql`
    query UserProfile {
        profile(where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;

export const SAVE_OPPORTUNITY = gql`
    mutation($oppId: ID!) {
        updateProfile(data: { opportunities: { connect: { id: $oppId } } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;

export const UNSAVE_OPPORTUNITY = gql`
    mutation($oppId: ID!) {
        updateProfile(data: { opportunities: { disconnect: { id: $oppId } } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;

export const SAVE_TASK_LOCATION = gql`
    mutation($locationId: ID!) {
        updateProfile(data: { taskLocations: { connect: [{ id: $locationId }] } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;

export const UNSAVE_TASK_LOCATION = gql`
    mutation($locationId: ID!) {
        updateProfile(data: { taskLocations: { disconnect: [{ id: $locationId }] } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;
export const SAVE_REDEMPTION_LOCATION = gql`
    mutation($locationId: ID!) {
        updateProfile(data: { redemptionLocations: { connect: [{ id: $locationId }] } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;

export const UNSAVE_REDEMPTION_LOCATION = gql`
    mutation($locationId: ID!) {
        updateProfile(data: { redemptionLocations: { disconnect: [{ id: $locationId }] } }, where: {}) {
            id
            opportunities {
                id
            }
        }
    }
`;
