import { gql } from '@apollo/client/core';
import * as React from 'react';
import { ImagePickerOnPermissionError, MultiImagePicker } from './MultiImagePicker';
import { MediaAsset } from './types';
import * as MediaLibrary from 'expo-media-library';
import { useMutation } from '@apollo/client';
import { ReactNativeFile } from 'apollo-upload-client';
import { AssetInfo } from 'expo-media-library';
import { useEffect, useState } from 'react';
import { IAsset, MediaTypeValue } from '../../types';
import { LinearProgress } from 'react-native-elements';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import Error from '../../components/Error';
import Colors from '../../constants/Colors';

const UPLOAD_MUTATION = gql`
    mutation(
        $mediaType: MediaTypeValue!
        $mediaSubtypes: [String!]!
        $width: Float!
        $height: Float!
        $duration: Float!
        $exif: String
        $upload: Upload!
        $bucket: AssetBucket!
    ) {
        createAsset(
            data: {
                uri: ""
                upload: $upload
                bucket: $bucket
                mediaType: $mediaType
                mediaSubtypes: { set: $mediaSubtypes }
                width: $width
                height: $height
                duration: $duration
                exif: $exif
            }
        ) {
            id
            uri
            mediaType
            mediaSubtypes
            width
            height
            duration
            exif
            caption
            createdAt
            updatedAt
        }
    }
`;

interface AssetUploaderProps {
    emptyStayComponent?: React.ComponentType<any> | React.ReactElement | null;
    preloaderComponent?: React.ComponentType<any> | React.ReactElement | null;
    renderSelectedComponent?: (component: number) => React.ComponentType<any> | React.ReactElement | null;
    renderExtraComponent?: (photo: MediaAsset) => React.ComponentType<any> | React.ReactElement | null;
    mediaType?: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue;
    max?: number | null;
    onPermissionError?: ImagePickerOnPermissionError;
    onPermissionSuccess?: () => void;
    onUploaded?: (assets: IAsset[], error?: any) => void;
    bucket: string;
}

enum UploadState {
    Selecting,
    Uploading,
    Finished,
    Error,
}

function toMediaTypeEnum(v: string): MediaTypeValue {
    switch (v) {
        case 'audio':
            return MediaTypeValue.Audio;
        case 'photo':
            return MediaTypeValue.Photo;
        case 'video':
            return MediaTypeValue.Video;
        default:
            return MediaTypeValue.Unknown;
    }
}

function getMIMEType(filename: string): string {
    if (/jpg$|jpeg$/.test(filename)) {
        return 'image/jpeg';
    }
    if (/png$/.test(filename)) {
        return 'image/png';
    }
    if (/gif$/.test(filename)) {
        return 'image/gif';
    }
    if (/webp$/.test(filename)) {
        return 'image/webp';
    }
    if (/heic$/.test(filename)) {
        return 'image/heic';
    }
    if (/heif$/.test(filename)) {
        return 'image/heif';
    }
    if (/mp4$/.test(filename)) {
        return 'video/mp4';
    }
    return 'image/jpeg';
}

export function AssetUploader(props: AssetUploaderProps) {
    const [uploadAsset] = useMutation<{ createAsset: IAsset }>(UPLOAD_MUTATION);
    const [uploaded, setUploaded] = useState<IAsset[]>([]);
    const [toUpload, setToUpload] = useState<AssetInfo[]>([]);
    const [uploadState, setUploadState] = useState(UploadState.Selecting);

    useEffect(() => {
        if (uploadState == UploadState.Uploading) {
            if (uploaded.length >= toUpload.length) {
                setUploadState(UploadState.Finished);
                if (props.onUploaded) {
                    props.onUploaded(uploaded);
                }
            } else {
                const item = toUpload[uploaded.length];
                const file = new ReactNativeFile({
                    uri: item.localUri || item.uri,
                    name: item.filename,
                    type: getMIMEType(item.filename),
                });
                uploadAsset({
                    variables: {
                        mediaType: toMediaTypeEnum(item.mediaType),
                        mediaSubtypes: item.mediaSubtypes ? item.mediaSubtypes : [],
                        width: item.width,
                        height: item.height,
                        duration: item.duration,
                        exif: item.exif ? JSON.stringify(item.exif) : null,
                        upload: file,
                        bucket: props.bucket,
                    },
                })
                    .then((result) => {
                        if (result.data) {
                            setUploaded(uploaded.concat([result.data.createAsset]));
                        } else {
                            console.error('failed to upload');
                            setUploadState(UploadState.Error);
                            if (props.onUploaded) {
                                props.onUploaded([], 'error uploading file');
                            }
                        }
                    })
                    .catch((e) => {
                        console.error('failed to upload');
                        console.error(e);
                        setUploadState(UploadState.Error);
                        if (props.onUploaded) {
                            props.onUploaded([], e);
                        }
                    });
            }
        }
    }, [uploadState, uploaded]);
    if (uploadState == UploadState.Uploading) {
        return (
            <View
                style={{
                    backgroundColor: Colors.dark.background,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                }}
            >
                <LinearProgress
                    color="white"
                    variant={'determinate'}
                    value={toUpload.length != 0 ? uploaded.length / toUpload.length : undefined}
                />
                <View style={styles.loading}>
                    <ActivityIndicator size={'large'} />
                </View>
            </View>
        );
    } else if (uploadState == UploadState.Selecting) {
        return (
            <MultiImagePicker
                loadCompleteMetadata={true}
                base64={false}
                {...props}
                onAccept={(items: AssetInfo[], error) => {
                    if (error) {
                        setUploadState(UploadState.Error);
                        if (props.onUploaded) {
                            props.onUploaded([], error);
                        }
                    } else {
                        setToUpload(items);
                        setUploadState(UploadState.Uploading);
                    }
                }}
            />
        );
    } else if (uploadState == UploadState.Error) {
        return <Error />;
    } else {
        return <View />;
    }
}

const styles = StyleSheet.create({
    loading: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
});
