import * as React from 'react';
import {
    StyleSheet,
    ScrollView,
    View,
    Text,
    TouchableOpacity,
    ActivityIndicator,
    RefreshControl,
} from 'react-native';
import RewardCard from '../components/RewardCard';
import { View as ThemedView } from '../components/Themed';
import { StackScreenProps } from '@react-navigation/stack';
import { BottomTabParamList } from '../types';
import { useQuery } from '@apollo/client';
import { IReward, IWallet } from '../store/types';
import { GET_WALLET } from '../lib/queries';
import Error from '../components/Error';
import i18n from '../config/i18n';

const rewardTypes: ('coupons' | 'vouchers')[] = ['coupons', 'vouchers'];
const headerText = {
    //storeCredits: "Store Credit",
    coupons: 'Coupons',
    vouchers: 'Vouchers',
};
type WalletScreenRouteProp = StackScreenProps<BottomTabParamList, 'Wallet'>;

export default function Wallet({ navigation }: WalletScreenRouteProp) {
    const { data, loading, error, refetch } = useQuery<{ wallet: IWallet }, null>(GET_WALLET, {pollInterval: 1000});
    const handleHelpPress = () => {};
    if (loading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'}/>
            </View>
        );
    }
    if (data && data.wallet) {
        const { wallet } = data;
        return (
            <ThemedView style={styles.container}>
                <ScrollView refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}>
                    {rewardTypes.map((rewardType) => {
                        // @ts-ignore
                        const rewards: IReward[] = wallet[rewardType];
                        return (
                            <React.Fragment key={rewardType}>
                                <View style={styles.sectionHeaderContainer}>
                                    <Text style={styles.sectionHeader}>{headerText[rewardType]}</Text>
                                    <TouchableOpacity onPress={handleHelpPress} style={styles.moreLink}>
                                        <Text style={styles.moreLinkText}>
                                            {i18n.t('seeAll')} {rewards.filter((reward) => !reward.redeemed).length}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <ScrollView style={styles.scroller} horizontal={true}>
                                    {rewards
                                        .filter((reward) => !reward.redeemed)
                                        .map((reward) => (
                                            <RewardCard
                                                nav={navigation}
                                                key={reward.id}
                                                reward={reward}
                                                rewardType={rewardType}
                                                style={styles.card}
                                            />
                                        ))}
                                </ScrollView>
                            </React.Fragment>
                        );
                    })}
                </ScrollView>
            </ThemedView>
        );
    }
    if (error) {
        console.error(error);
        return <Error message={error.toString()}/>;
    }
    return null;
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
        paddingBottom: 0,
    },
    scroller: {
        height: 170,
    },
    sectionHeaderContainer: {
        height: 20,
        marginBottom: 10,
        marginTop: 10,
        width: '100%',
    },
    sectionHeader: {
        position: 'absolute',
        left: 5,
        top: 5,
        color: 'grey',
        fontSize: 14,
        fontFamily: 'JosefinSans_700Bold',
    },
    moreLink: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    moreLinkText: {
        fontFamily: 'JosefinSans_700Bold',
        fontSize: 16,
        color: '#8A84D7',
    },
    card: {
        paddingLeft: 10,
        paddingRight: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4,
        },
        shadowOpacity: 0.4,
        shadowRadius: 3.84,
        elevation: 5,
    },
    storeLogo: {
        position: 'absolute',
        top: 10,
        left: 90,
        width: 25,
        height: 25,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    rewardCaption: {
        color: 'white',
        fontSize: 14,
        fontFamily: 'FjallaOne_400Regular',
        position: 'absolute',
        top: 50,
        left: 10,
    },
    amount: {
        color: 'white',
        fontSize: 22,
        fontFamily: 'FjallaOne_400Regular',
        position: 'absolute',
        top: 10,
        left: 10,
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4,
        },
        shadowOpacity: 0.4,
        shadowRadius: 3.84,
        elevation: 5,
    },
    cardBg: {
        borderRadius: 5,
        width: 140,
        height: 80,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
