import { ActivityIndicator, Dimensions, Image, StyleSheet } from 'react-native';
import { View, ScrollView } from '../components/Themed';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    BOOK_MARK_LIST,
    GET_OPPORTUNITY,
    SAVE_OPPORTUNITY,
    UNSAVE_OPPORTUNITY,
} from '../lib/backend/oportunity_tab/queries';
import { StackScreenProps } from '@react-navigation/stack';
import { IAsset, IOpportunity, IReward, OpportunityStackParams } from '../types';
import Error from '../components/Error';
import { OpportunityCard } from '../components/opportunities/OpportunityCard';
import { Text } from '../components/Themed';
import moment from 'moment';
import { range } from 'lodash';
import { ListItem, CheckBox, Button } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import { CREATE_CLAIM, CLAIM_REWARD } from '../lib/mutations';
import { ITask } from '../store/types';
import { useSelector } from 'react-redux';
import { RootStore, Shop } from '../store/types';
import { LocationObject } from 'expo-location';
import { GET_CLAIM, GET_COMPLETED_CLAIMS_PER_OPPORTUNITY } from '../lib/queries';
import { isClaimExhausted } from '../lib/opportunities';
import { GraphQLError } from 'graphql';
import i18n from '../config/i18n';

type IRewardType =
    | 'Voucher'
    | 'StoreCredit'
    | 'CouponFixed'
    | 'CouponPercentage'
    | 'CouponCustom'
    | 'Cash'
    | 'GoviCash';

export type VerificationType =
    | 'Automatic'
    | 'Manual'
    | 'Digital'
    | 'Signup'
    | 'ManualInPerson'
    | 'ManualDigital'
    | 'Purchase';

export interface ITaskVerification {
    link: string;
    id: string;
    assets: IAsset[];
    task: ITask;
    images: string[];
    text: string;
    lat?: number;
    long?: number;
    rejectedReason: string;
    approved: boolean;
    rejected: boolean;
    submitted: boolean;
    purchaseAmount: number;
    started: boolean;
    surveyResponses: {
        id: string;
        response: string;
        fieldId: string;
    }[];
    approvedBy: { id: string; firstName: string; lastName: string };
    rejectedBy: { id: string; firstName: string; lastName: string };
}

export interface IProfile {
    id: any;
    opportunities: { id: string }[];
}

export interface IOpportunityClaim {
    id: string;
    rewardId: string;
    submitted: boolean;
    rejected: boolean;
    verifications: ITaskVerification[];
    completed: boolean;
    purchaseAmount: number;
    submittedAt?: Date;
}

function getNumber(input: any) {
    let n = Number(input);
    if (isNaN(n)) {
        n = 0;
    }
    return n;
}

const formatTier = (rewardType: IRewardType, tier: number | string) => {
    let tier_number = getNumber(tier);
    switch (rewardType) {
        case 'Voucher':
            return tier;
        case 'Cash':
            return `Receive $${tier_number} in Cash`;
        case 'CouponCustom':
            return tier;
        case 'CouponFixed':
            return `Receive $${tier_number} Coupon`;
        case 'CouponPercentage':
            return `Receive Coupon for ${tier_number}% Off`;
        case 'StoreCredit':
            return `Receive $${tier_number} in Store Credt`;
        case 'GoviCash':
            return `Receive $${tier_number} in GoViCash`;
        default:
            return 'Coupon Unavailable';
    }
};

export const evenRound = (num: any, decimalPlaces: any) => {
    const d = decimalPlaces || 0;
    const m = Math.pow(10, d);
    const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    const i = Math.floor(n),
        f = n - i;
    const e = 1e-8; // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
};

export type OpportunityDetailedScreenRouteProp = StackScreenProps<OpportunityStackParams, 'OpportunityDetail'>;

function getRewardLevels(reward: IReward) {
    let levels = [];
    if (reward.type === 'Voucher') {
        if (reward.variable || reward.voucherTiers.length === 0) {
            let tierValues: number[] = [];
            if (reward.low != undefined && reward.high != undefined) {
                tierValues = range(reward.low, reward.high, (reward.high - reward.low) / 3);
                tierValues.push(reward.high);
            } else {
                tierValues = [0, 0, 0, 0];
            }

            levels = tierValues.map((tier) => {
                let tierText = 'Receive a ';
                if (reward.percentage) {
                    tierText = tierText + `${evenRound(getNumber(tier), 2)}% Voucher`;
                } else {
                    tierText = tierText + `$${evenRound(getNumber(tier), 2)} Voucher`;
                }
                if (reward.minPurchase) {
                    tierText = tierText + ` when you purchase $${reward.minPurchase}`;
                }
                return tierText;
            });
        } else {
            levels = reward.voucherTiers.map((tier) => formatTier(reward.type, tier));
        }
    } else if (reward.type === 'StoreCredit') {
        let tierValues = range(reward.low, reward.high, (reward.high - reward.low) / 3);
        tierValues.push(reward.high);
        levels = tierValues.map((tier) => {
            let tierText = '';
            if (reward.percentage) {
                tierText = `${evenRound(getNumber(tier), 2)}% in Store Credit`;
            } else {
                tierText = `$${evenRound(getNumber(tier), 2)} in Store Credit`;
            }
            if (reward.variable) {
                tierText = tierText + ` when you purchase $${reward.minPurchase}`;
            }
            return tierText;
        });
    } else if (reward.type === 'CouponCustom') {
        levels = reward.voucherTiers.map((tier) => formatTier(reward.type, tier));
    } else if (reward.type.indexOf('Coupon') >= 0) {
        let tierValues = range(reward.low, reward.high, (reward.high - reward.low) / 3);
        tierValues.push(reward.high);
        levels = tierValues.map((tier) => {
            let tierText = '';
            if (reward.percentage) {
                tierText = `${evenRound(getNumber(tier), 2)}% Off Coupon`;
            } else {
                tierText = `$${evenRound(getNumber(tier), 2)} Off Coupon`;
            }
            if (reward.variable) {
                tierText = tierText + ` when you purchase $${getNumber(reward.minPurchase)}`;
            }
            return tierText;
        });
    } else {
        levels = reward.tiers.map((tier) => formatTier(reward.type, tier));
    }
    return levels;
}

enum TaskState {
    Accepted,
    Rejected,
    Waiting,
    Submitted,
    Started,
}

function getTaskState(claim: IOpportunityClaim | undefined | null, task: { id: string }): TaskState {
    if (claim) {
        for (let v of claim.verifications) {
            if (v.task.id == task.id) {
                if(v.task.survey && v.task.survey.id){
                    if (v.approved) {
                        return TaskState.Accepted;
                    } else if (v.rejected) {
                        return TaskState.Rejected;
                    } else if(v.submitted && v.surveyResponses && v.surveyResponses.length !== 0) {
                        return TaskState.Submitted;
                    } else if (v.started && v.surveyResponses && v.surveyResponses.length !== 0) {
                        return TaskState.Started;
                    } else {
                        return TaskState.Waiting;
                    }
                }else{
                    if (v.approved) {
                        return TaskState.Accepted;
                    } else if (v.rejected) {
                        return TaskState.Rejected;
                    } else if(v.submitted) {
                        return TaskState.Submitted;
                    } else if (v.started) {
                        return TaskState.Started;
                    } else {
                        return TaskState.Waiting;
                    }
                }

            }
        }
    }

    return TaskState.Waiting;
}

export function inBookMark(profile: IProfile | null, opportunityId: string): boolean {
    if (profile) {
        for (const o of profile.opportunities) {
            if (o.id == opportunityId) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
}


export function OpportunityDetail({ navigation, route }: OpportunityDetailedScreenRouteProp) {
    // @ts-ignore
    const { opportunity: oldOpportunity, completedClaims: oldcompletedClaims } = route.params;
    const opportunityId = oldOpportunity.id;
    const { data: opportunityData, loading: opportunityLoading, error: opportunityError } = useQuery<
        { opportunity: IOpportunity },
        { id: string }
    >(GET_OPPORTUNITY, {
        variables: {
            id: opportunityId,
        },
        //it is preferred polling that subscriptions
        pollInterval: 1000,
    });

    let opportunity: IOpportunity = opportunityData ? opportunityData.opportunity : oldOpportunity;

    const { data: profileData, error: profileError, loading: profileLoading } = useQuery<{ profile: IProfile }>(
        BOOK_MARK_LIST
    );
    const [claimId, setClaimId] = useState(null);

    const { data: claimData, error: getClaimError, loading: getClaimLoading } = useQuery<{
        opportunityClaim: IOpportunityClaim;
    }>(GET_CLAIM, {
        variables: { claimId },
        skip: !claimId,
        pollInterval: 1000,
    });

    const [createClaim, { error: createClaimError, loading: createClaimLoading }] = useMutation(CREATE_CLAIM);
    const [saveOpportunity, { loading: saveToListLoading }] = useMutation(SAVE_OPPORTUNITY);
    const [removeOpportunity, { loading: removeFromListLoading }] = useMutation(UNSAVE_OPPORTUNITY);
    const [claimReward, { loading: claimRewardLoading, error: claimRewardError }] = useMutation(CLAIM_REWARD);
    const levels = useMemo(
        () => (opportunity && opportunity.reward ? getRewardLevels(opportunity.reward) : ['', '', '', '']),
        [opportunity]
    );
    const { data: completedData, error: completedError, loading: completedClaimsLoading } = useQuery<{
        opportunityClaims: IOpportunityClaim[];
    }>(GET_COMPLETED_CLAIMS_PER_OPPORTUNITY, {
        //it is preferred polling that subscriptions
        pollInterval: 1000,
        variables: { id: opportunityId },
    });
    const currentCustomerCompletedClaimsForThisOpportunity = useMemo(
        () => (completedData ? completedData.opportunityClaims.length : oldcompletedClaims.length),
        [completedData, oldcompletedClaims]
    );
    const claimsExhausted = useMemo(
        () => isClaimExhausted(opportunity, currentCustomerCompletedClaimsForThisOpportunity),
        [currentCustomerCompletedClaimsForThisOpportunity, opportunity]
    );
    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);
    const currentShop = useSelector<RootStore, Shop>((state) => state.shop);

    if (completedError) {
        // TODO: show something to user?
        console.error(completedError);
    }
    if (claimRewardError) {
        // TODO: show something to user?
        console.error(claimRewardError);
    }

    useEffect(() => {
        if (!createClaimLoading && !createClaimError && !claimId) {
            createClaim({ variables: { opportunityId: opportunityId } })
                .then((fr) => {
                    const { data: claimResData } = fr;
                    if (claimResData && claimResData.createOpportunityClaim) {
                        setClaimId(claimResData.createOpportunityClaim.id);
                    }
                })
                .catch((e) => {
                    if (
                        e.graphQLErrors.findIndex(
                            (e: GraphQLError) =>
                                e.extensions &&
                                (e.extensions.code === 'CLAIM_LIMIT_PER_USER_PER_OPPORTUNITY_REACHED' ||
                                    e.extensions.code === 'CLAIM_LIMIT_PER_OPPORTUNITY_REACHED')
                        ) < 0
                    ) {
                        console.trace(e);
                    }
                });
        }
    }, [claimId, opportunityId, createClaimLoading, createClaimError]);

    const save = async () => {
        try {
            await saveOpportunity({
                variables: {
                    oppId: opportunityId,
                },
            });
        } catch (e) {
            console.error('Error[save opportunity to list]: ', e);
        }
    };
    const remove = async () => {
        await removeOpportunity({
            variables: {
                oppId: opportunityId,
            },
        });
    };

    const _getExpiration = () => {
        if (opportunity.perpetual) {
            return <></>;
        }
        if (moment(opportunity.endDate) > moment()) {
            return <Text>Expires {moment(opportunity.endDate).format('lll')}</Text>;
        } else {
            return <Text>Expired</Text>;
        }
    };

    const showTaskDetail = async (task: any) => {
        if (claimData && claimData.opportunityClaim) {
            const { opportunityClaim } = claimData;
            if (task.verificationType === 'ManualDigital') {
                if (task.type === 'Intelligence') {
                    navigation.navigate('Survey', {
                        taskId: task.id,
                        claim: opportunityClaim,
                        opportunity: opportunity,
                    });
                } else {
                    navigation.navigate('ManualDigital', {
                        // @ts-ignore
                        task,
                        taskId: task.id,
                        claim: opportunityClaim,
                        opportunity: opportunity,
                    });
                }
            } else {
                // TODO: implement an error state if no data is retrieved
                navigation.navigate('Verification', {
                    task: task,
                    claim: opportunityClaim,
                    opportunity: opportunity,
                });
            }
        }
    };

    const opportunityOnBookmark = useMemo(() => {
        if (profileData) {
            if (opportunity && profileData.profile) {
                return inBookMark(profileData.profile, opportunityId);
            }
            return false;
        } else {
            return false;
        }
    }, [opportunity, profileData]);
    const claim: IOpportunityClaim | null = useMemo(() => {
        if (claimData) {
            if (claimData.opportunityClaim) {
                return claimData.opportunityClaim;
            }
        }
        return null;
    }, [claimData]);
    if (claimRewardLoading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    }

    const claimError = createClaimError || getClaimError;
    if (claimError) {
        if (
            claimError.graphQLErrors.findIndex(
                (e) =>
                    e.extensions &&
                    (e.extensions.code === 'CLAIM_LIMIT_PER_USER_PER_OPPORTUNITY_REACHED' ||
                        e.extensions.code === 'CLAIM_LIMIT_PER_OPPORTUNITY_REACHED')
            ) < 0
        ) {
            return <Error />;
        }
    }
    if (profileError || opportunityError) {
        return <Error />;
    }

    if (opportunityLoading) {
        return (
            <ScrollView style={styles.root}>
                <ActivityIndicator></ActivityIndicator>
            </ScrollView>
        );
    }

    if (opportunity) {
        return (
            <ScrollView style={styles.root}>
                <OpportunityCard standalone={false} opportunity={opportunity} disabled={false} />
                {opportunity.detailedDescription ? (
                    <View style={styles.longDescription}>
                        <Text>{opportunity.detailedDescription}</Text>
                    </View>
                ) : null}
                <View style={styles.expirationDate}>{_getExpiration()}</View>
                <View>
                    <ScrollView pagingEnabled={true} horizontal={true}>
                        <View style={styles.rewardLevel}>
                            <Image
                                style={styles.rewardLevelImages}
                                source={require('../assets/images/bronze.png')}
                            />
                            <View style={styles.rewardLevelDetail}>
                                <Text style={styles.rewardLevelDetailTextTitle}>{i18n.t('bronzeLevel')}</Text>
                                <Text style={styles.rewardLevelDetailText}>{levels[0]}</Text>
                            </View>
                        </View>
                        <View style={styles.rewardLevel}>
                            <Image
                                style={styles.rewardLevelImages}
                                source={require('../assets/images/silver.png')}
                            />
                            <View style={styles.rewardLevelDetail}>
                                <Text style={styles.rewardLevelDetailTextTitle}>{i18n.t('silverLevel')}</Text>
                                <Text style={styles.rewardLevelDetailText}>{levels[1]}</Text>
                            </View>
                        </View>

                        <View style={styles.rewardLevel}>
                            <Image
                                style={styles.rewardLevelImages}
                                source={require('../assets/images/gold.png')}
                            />
                            <View style={styles.rewardLevelDetail}>
                                <Text style={styles.rewardLevelDetailTextTitle}>{i18n.t('goldLevel')}</Text>
                                <Text style={styles.rewardLevelDetailText}>{levels[2]}</Text>
                            </View>
                        </View>

                        <View style={styles.rewardLevel}>
                            <Image
                                style={styles.rewardLevelImages}
                                source={require('../assets/images/platinum.png')}
                            />
                            <View style={styles.rewardLevelDetail}>
                                <Text style={styles.rewardLevelDetailTextTitle}>{i18n.t('platinumLevel')}</Text>
                                <Text style={styles.rewardLevelDetailText}>{levels[3]}</Text>
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ display: 'flex', alignItems: 'center' }}>
                        <Text>{i18n.t('swipeLeftOrRightToViewOtherTiers')}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 16, marginLeft: 8, marginRight: 8 }}>
                    <Button
                        disabled={profileLoading || saveToListLoading || removeFromListLoading}
                        onPress={async () => (opportunityOnBookmark ? remove() : save())}
                        title={opportunityOnBookmark ? 'Remove from Favorites' : 'Add to Favorites'}
                        type="outline"
                    />
                </View>
		{opportunity.reward.redemptionLocations?.length >0 ? (
                    <View style={{ marginTop: 16, marginLeft: 8, marginRight: 8 }}>
                        <Button
                            onPress={() => {
                                //@ts-ignore
                                navigation.push('Shops', {
                                    subtitle: 'You may redeem your reward at the following locations',
                                    mainQuery: {
                                        name: 'OPP_PLACES',
                                        variables: {
                                            opportunity_id: opportunityId,
                                            shop_id: currentShop.id,
                                            location: {
                                                lat: loc ? loc.coords.latitude : 31.51073,
                                                long: loc ? loc.coords.longitude : -96.4247,
                                            },
                                        },
                                        subPath: ['places'],
                                    },
                                });
                            }}
                            type="outline"
                            title={'Redemption Locations'}
                            icon={<Ionicons name="ios-location-sharp" size={24} color={'rgb(32, 137, 220)'} />}
                        />
                    </View>
                ) : (
                    <></>
                )}
                <View>
                    {opportunity.tasks.length > 0 ? <Text style={styles.taskTitle}>Tasks</Text> : <></>}
                </View>
                <View>
                    {opportunity.tasks.map((item: any) => {
                        let ts = getTaskState(claim, item);
                        let box = null;
                        if (createClaimLoading || getClaimLoading) {
                            box = <ActivityIndicator size={24} style={styles.taskIcon} />;
                        } else if (ts === TaskState.Rejected) {
                            box = (
                                <Ionicons
                                    name="ios-close-circle-sharp"
                                    size={24}
                                    color={'red'}
                                    style={styles.taskIcon}
                                />
                            );
                        } else if (ts === TaskState.Accepted) {
                            box = <CheckBox checked={true} />;
                        } else if (ts === TaskState.Submitted || ts === TaskState.Started) {
                            box = (
                                <Ionicons
                                    name="hourglass-outline"
                                    size={24}
                                    color={'black'}
                                    style={styles.taskIcon}
                                />
                            );
                        } else {
                            box = <CheckBox checked={false} />;
                        }
                        return (
                            <ListItem
                                key={item.id}
                                bottomDivider
                                disabled={claimsExhausted}
                                onPress={() => showTaskDetail(item)}
                                containerStyle={{}}
                            >
                                <ListItem.Content style={styles.taskContent}>
                                    {box}
                                    {ts === TaskState.Submitted ? (
                                        <ListItem.Subtitle style={styles.taskSubtitle}>
                                            {i18n.t('thisTaskIsBeingReviewed')}
                                        </ListItem.Subtitle>
                                    ) : (
                                        <ListItem.Subtitle style={styles.taskSubtitle}>
                                            {item.description}
                                        </ListItem.Subtitle>
                                    )}
                                </ListItem.Content>
                                <ListItem.Chevron />
                            </ListItem>
                        );
                    })}
                </View>
                {claim && !claim.completed && (
                    <Button
                        containerStyle={styles.claimBtn}
                        title="Claim Reward"
                        disabled={
                            claimsExhausted ||
                            (opportunity.gift
                                ? true
                                : claim
                                ? !claim.verifications.reduce(
                                      (acc: Boolean, cur) => (acc ? cur.approved : false),
                                      true
                                  )
                                : false)
                        }
                        onPress={() =>
                            claimReward({
                                variables: { claimId: claim.id },
                            })
                                .then(() => {
                                    //reset claim id
                                    setClaimId(null);
                                })
                                .catch((e) => console.error(e))
                        }
                    />
                )}
                {completedClaimsLoading && <ActivityIndicator></ActivityIndicator>}
                {claimsExhausted && <Button title="Claims Exhausted" disabled={true} onPress={() => null} />}
            </ScrollView>
        );
    } else {
        return <Error />;
    }
}

const styles = StyleSheet.create({
    expirationDate: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    root: {
        minHeight: '100%',
    },
    loading: {
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rewardLevelScroller: {
        flex: 1,
    },
    rewardLevelDetail: {
        flex: 1,
        paddingLeft: 10,
        flexDirection: 'column',
    },
    rewardLevelHeader: {
        fontFamily: 'Lato-Heavy',
        color: '#8A84D7',
        fontSize: 16,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 5,
    },
    rewardLevelFooter: {
        fontFamily: 'Lato',
        color: '#8A84D7',
        fontSize: 14,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 5,
    },
    rewardLevelDetailText: {
        fontFamily: 'Lato-Bold',
        fontSize: 18,
        marginTop: 7,
        marginLeft: 10,
        marginRight: 40,
    },
    rewardLevelDetailTextTitle: {
        fontFamily: 'Lato-Bold',
        fontSize: 20,
        marginTop: 15,
        marginLeft: 10,
        marginRight: 40,
    },
    rewardBG: {
        marginTop: 2,
        marginLeft: 5,
        marginRight: 5,
    },
    rewardLevelImages: {
        marginTop: 25,
        marginLeft: 16,
        height: 64,
        width: 64,
        resizeMode: 'stretch',
    },
    rewardLevel: {
        borderWidth: 0.5,
        borderColor: 'gray',
        height: 120,
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 8,
        width: Dimensions.get('window').width - 10,
        flexDirection: 'row',
    },
    longDescription: {
        marginTop: -50,
        marginBottom: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    taskTitle: { fontWeight: 'bold', marginTop: 16, marginLeft: 8 },
    taskSubtitle: {
        paddingRight: 60,
    },
    taskContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    taskIcon: { margin: 13, marginLeft: 18, marginRight: 18 },
    claimBtn: {
        margin: 5,
        marginTop: 15,
    },
});
