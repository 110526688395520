import { gql } from '@apollo/client';
import {
    SET_USER,
    SET_ERROR,
    SET_TOKEN,
    CLEAR_TOKEN,
    SET_SHOP,
    SET_LOGIN_LOADING,
    SET_CONFIRM_LOGIN_LOADING,
    SET_LOGIN_STATUS,
    CONFIRMED,
    SET_REGISTER_LOADING,
    SET_REGISTRATION_COMPLETE,
    SET_PENDING_CONFIRM,
    SET_LOCATION,
    UNCONFIRMED, CLEAR_ERROR, ErrorCode,
} from './types';
import { GET_RTTOKEN } from '../lib/queries';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LocationObject } from 'expo-location';

const SIGN_UP = gql`
    mutation signUp($phone: String!) {
        signup(phone: $phone) {
            phone
        }
    }
`;

const BIZ_LOCATION = gql`
    query businessLocation($shopId: ID) {
        businessLocation(where: { id: $shopId }) {
            id
            description
            categories
            lat
            long
            primaryImage
            name
            logo
            address {
                city
                state
                street1
                postalCode
            }
        }
    }
`;

const LOGIN = gql`
    mutation ($phone: String!) {
        login(phone: $phone)
    }
`;

const RESEND = gql`
    mutation resend($phone: String!) {
        resend(phone: $phone)
    }
`;

const RESEND_LOGIN = gql`
    mutation resendLogin($phone: String!) {
        resendLogin(phone: $phone)
    }
`;

const CONFIRM = gql`
    mutation confirm($phone: String!, $code: String!, $shopId: ID) {
        confirm(phone: $phone, code: $code, shopId: $shopId) {
            token
            error
            code
        }
    }
`;

const CONFIRM_LOGIN = gql`
    mutation ($phone: String!, $code: String!, $shopId: ID) {
        confirmLogin(phone: $phone, code: $code, shopId: $shopId) {
            token
            error
            code
        }
    }
`;

export const RENEW_TOKEN = gql`
    mutation ($token: String!) {
        renewToken(token: $token)
    }
`;

function sanitizeErrors(message: string) {
    return message.replace('GraphQL error: ', '');
}

export function getShop(id: string) {
    return async (dispatch: Function, getState: Function, client: any) => {
        try {
            const result = await client.query({
                query: BIZ_LOCATION,
                variables: { shopId: id },
            });
            dispatch({ type: SET_SHOP, payload: result.data.businessLocation });
        } catch (e: any) {
            const message = sanitizeErrors(e.message);
            console.error(e);
            dispatch({ type: SET_ERROR, payload: message });
            return false;
        }
    };
}

export function setToken(token: string) {
    return async (dispatch: Function, getState: Function, client: any) => {
        try {
            await AsyncStorage.setItem('@token', token);
        } catch (e) {
            console.error(e);
        }
        // Just because it fails to save, doesn't mean we shouldn't set it
        dispatch({ type: SET_TOKEN, payload: token });
    };
}

export function clearToken() {
    return async (dispatch: Function, getState: Function, client: any) => {
        try {
            await AsyncStorage.removeItem('@token');
            dispatch({ type: SET_REGISTRATION_COMPLETE, payload: false });
            dispatch({ type: SET_LOGIN_STATUS, payload: UNCONFIRMED });
            dispatch({
                type: SET_LOGIN_LOADING,
                payload: false,
            });
            dispatch({ type: CLEAR_TOKEN });
        } catch (e) {
            console.error(e);
        }
    };
}

export function reloadToken(firebase: any) {
    return async (dispatch: Function, getState: Function, client: any) => {
        try {
            const result = await AsyncStorage.getItem('@token');
            if (result) {
                dispatch(setToken(result));
            }
        } catch (e) {
            console.error(e);
        }
        let result;
        try {
            result = await client.query({
                query: GET_RTTOKEN,
            });
        } catch (e) {
            console.error('Could not log in to Firebase', e);
        }

        if (result && result.data && firebase) {
            try {
                await firebase.auth().signInWithCustomToken(result.data.rtToken);
            } catch (e) {
                console.trace(e);
            }
        }
    };
}

export function signUp(phone: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        if (!phone) {
            dispatch({ type: SET_ERROR, payload: {message: 'Missing phone number', code:ErrorCode.MissingPhoneNumber} });
            return false;
        }
        dispatch({ type: SET_REGISTER_LOADING, payload: true });
        try {
            const result = await client.mutate({
                mutation: SIGN_UP,
                variables: {
                    phone,
                },
            });
            const data = result.data.signup;
            // For now we are just pulling phone out
            if (data.phone) {
                dispatch({ type: SET_PENDING_CONFIRM, payload: true });
            } else {
                dispatch({
                    type: SET_ERROR,
                    payload: 'Unable to create account',
                });
            }
            // TODO: handle errors if phone is not here
            dispatch({ type: SET_REGISTER_LOADING, payload: false });
        } catch (e: any) {
            const message = sanitizeErrors(e.message);
            dispatch({ type: SET_ERROR, payload: message });
            dispatch({ type: SET_REGISTER_LOADING, payload: false });
        }
    };
}

export function login(phone: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        if (!phone) {
            dispatch({ type: SET_ERROR, payload: {message: 'Missing phone number', code:ErrorCode.MissingPhoneNumber} });
            return false;
        }
        dispatch({
            type: SET_LOGIN_LOADING,
            payload: true,
        });
        try {
            await client.mutate({
                mutation: LOGIN,
                variables: {
                    phone,
                },
            });
            dispatch({ type: SET_USER, payload: { phone } });
            dispatch({ type: SET_LOGIN_STATUS, payload: CONFIRMED });
            dispatch({
                type: SET_LOGIN_LOADING,
                payload: false,
            });
        } catch (e: any) {
            const message = sanitizeErrors(e.message);
            if (message === 'NoUser') {
                dispatch({
                    type: SET_ERROR,
                    payload: 'There is no user for that phone number, please create an account.',
                });
            } else {
                dispatch({ type: SET_ERROR, payload: message });
            }
            dispatch({
                type: SET_LOGIN_LOADING,
                payload: false,
            });
        }
    };
}

export function confirmLogin(phone: String, code: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        dispatch({
            type: SET_CONFIRM_LOGIN_LOADING,
            payload: true,
        });
        if (!phone) {
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });

            dispatch({ type: SET_ERROR, payload: {message: 'Missing phone number',  code: ErrorCode.MissingPhoneNumber} });

            return false;
        }

        if (!code) {
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });

            dispatch({ type: SET_ERROR, payload: {message:'Missing confirmation code', code: ErrorCode.SMSCodeMissing}});
            return false;
        }

        try {
            const result = await client.mutate({
                mutation: CONFIRM_LOGIN,
                variables: {
                    phone,
                    code,
                    shopId: getState().shop.id,
                },
            });
            if (result.data.confirmLogin.error) {
                dispatch({
                    type: SET_ERROR,
                    payload: {
                        message: result.data.confirmLogin.error,
                        code: result.data.confirmLogin.code? ErrorCode[result.data.confirmLogin.code  as keyof typeof ErrorCode] || ErrorCode.Unknown: ErrorCode.Unknown},

                });
            } else {
                const token = result.data.confirmLogin.token;
                dispatch(setToken(token));
            }
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });
        } catch (e: any) {
            console.trace(e);
            const message = sanitizeErrors(e.message);
            dispatch({ type: SET_ERROR, payload: message });
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });
        }
    };
}

export function renewToken(token: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        try {
            const result = await client.mutate({
                mutation: RENEW_TOKEN,
                variables: {
                    token,
                },
            });
            return result.data.renewToken;
        } catch (e: any) {
            const message = sanitizeErrors(e.message);
            dispatch({ type: SET_ERROR, payload: message });
            return false;
        }
    };
}

export function confirm(phone: String, code: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        dispatch({ type: SET_REGISTER_LOADING, payload: true });

        if (!phone) {
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });

            dispatch({ type: SET_ERROR, payload: {message: 'Missing phone number',  code: ErrorCode.MissingPhoneNumber} });

            return false;
        }

        if (!code) {
            dispatch({
                type: SET_CONFIRM_LOGIN_LOADING,
                payload: false,
            });

            dispatch({ type: SET_ERROR, payload: {message:'Missing confirmation code', code: ErrorCode.SMSCodeMissing}});
            return false;
        }

        try {
            const result = await client.mutate({
                mutation: CONFIRM,
                variables: {
                    phone,
                    code,
                    shopId: getState().shop.id,
                },
            });
            const token = result.data.confirm.token;
            if (token) {
                dispatch({ type: SET_REGISTRATION_COMPLETE, payload: true });
                dispatch(setToken(token));
            } else {
                dispatch({
                    type: SET_ERROR,
                    payload: {
                            message: result.data.confirm.error,
                            code: result.data.confirm.code? ErrorCode[result.data.confirm.code as keyof typeof ErrorCode] || ErrorCode.Unknown: ErrorCode.Unknown
                    },
                });
            }
            dispatch({ type: SET_REGISTER_LOADING, payload: false });
        } catch (e: any) {
            const message = sanitizeErrors(e.message);
            dispatch({ type: SET_ERROR, payload: message });
            dispatch({ type: SET_REGISTER_LOADING, payload: false });
        }
    };
}

export function resend(phone: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        return client.mutate({
            mutation: RESEND,
            variables: {
                phone,
            },
        });
    };
}

export function resendLogin(phone: String) {
    return async (dispatch: Function, getState: Function, client: any) => {
        return client.mutate({
            mutation: RESEND_LOGIN,
            variables: {
                phone,
            },
        });
    };
}

export function authFirebase(firebase: any) {
    return async function (dispatch: any, getState: any, client: any) {
        let result;
        try {
            result = await client.query({
                query: GET_RTTOKEN,
            });
        } catch (e) {
            console.error('Could not log in to Firebase', e);
        }

        if (result && result.data) {
            try {
                await firebase.auth().signInWithCustomToken(result.data.rtToken);
            } catch (e) {
                console.error(e);
                // TODO: Log it
            }
        }

        return Promise.resolve();
    };
}


export function clearError(){
    return async (dispatch: Function, getState: Function, client: any) => {
        dispatch({
            type: CLEAR_ERROR,
        });
    };
}