import gql from 'graphql-tag';

export const SHOP_MESSAGE_CREATED = gql`
    subscription ($shopId: ID!) {
        userShopMessageCreated(shopId: $shopId) {
            text
            id
            reply
        }
    }
`;

export const REWARD_NOTIFICATION = gql`
    subscription {
        rewardCreated {
            userId
            type
            rewardId
            shopName
        }
    }
`;

export const TASK_APPROVED = gql`
    subscription {
        taskApproved {
            id
        }
    }
`;

export const REDEMPTIONS = gql`
    subscription {
        redemptionUpdated {
            rewardId
            approved
        }
    }
`;

export const NOTIFICATION_CREATED = gql`
    subscription {
        notificationCreated {
            id
            message
            entityId
            type
        }
    }
`;
