import { IGetRedemptionsResult } from '../../store/types';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Error from '../Error';
import QRCode from 'react-native-qrcode-svg';
import logo from '../../lib/logo';
import { redemptionUrl } from './utils';
import React from 'react';

const styles = StyleSheet.create({
    loadingQr: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    qrView: {
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default function RedemptionQRCode(props: {
    loading: boolean;
    error: any;
    data?: IGetRedemptionsResult;
}) {
    if (props.loading) {
        return (
            <View style={styles.loadingQr}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    }
    if (props.error || props.data == undefined) {
        console.error(props.error);
        return <Error />;
    }

    const { redemptions } = props.data;
    if (redemptions.length === 1) {
        return (
            <View style={styles.qrView}>
                <QRCode logo={{ uri: logo }} size={140} value={redemptionUrl(props.data.redemptions[0].id)} />
            </View>
        );
    }

    return (
        <View style={styles.loadingQr}>
            <ActivityIndicator size={'large'} />
        </View>
    );
}
