import React, { useEffect, useMemo, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Dimensions,
    ActivityIndicator,
    Linking,
    Alert,
    Platform,
} from 'react-native';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import * as IntentLauncher from 'expo-intent-launcher';
import Constants from 'expo-constants';
import { ImageTile } from './ImageTile';
import { useDeviceOrientation } from '@react-native-community/hooks';
//@ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/blue';
import { MediaAsset } from './types';
import { useActionSheet } from '@expo/react-native-action-sheet';

const { width } = Dimensions.get('window');

interface MultiImagePickerProps {
    loadCompleteMetadata?: boolean;
    base64?: boolean;
    loadCount?: number;
    emptyStayComponent?: React.ComponentType<any> | React.ReactElement | null;
    preloaderComponent?: React.ComponentType<any> | React.ReactElement | null;
    renderSelectedComponent?: (component: number) => React.ComponentType<any> | React.ReactElement | null;
    renderExtraComponent?: (photo: MediaAsset) => React.ComponentType<any> | React.ReactElement | null;
    mediaType?: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue;
    max?: number | null;
    onAccept?: ImagePickerOnAccept;
    onPermissionError?: ImagePickerOnPermissionError;
    onPermissionSuccess?: () => void;
}

export type AssetInfoWithBase64 = MediaLibrary.AssetInfo & {
    base64?: string;
};

export type ImagePickerOnAccept = (
    items: (MediaLibrary.Asset | MediaLibrary.AssetInfo | AssetInfoWithBase64)[],
    error?: any
) => void;
export type ImagePickerOnPermissionError = (
    hasCameraPermission: boolean,
    hasCameraRollPermission: boolean
) => void;

export function MultiImagePicker(props: MultiImagePickerProps) {
    const [photos, setPhotos] = useState<MediaLibrary.Asset[]>([]);
    const [selected, setSelected] = useState<Array<number>>([]);
    const [after, setAfter] = useState<string | MediaLibrary.Asset | undefined | null>(null);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [hasCameraPermission, setHasCameraPermission] = useState<boolean>(false);
    const [hasCameraRollPermission, setHasCameraRollPermission] = useState<boolean>(false);
    const [didAskedPermission, setDidAskedPermission] = useState<boolean>(false);
    const [firstPhotoQuery, setFirstPhotoQuery] = useState<boolean>(false);
    const { showActionSheetWithOptions } = useActionSheet();
    const orientation = useDeviceOrientation();
    const [libraryPermissions, setLibraryPermissions] = useState('');
    const pkg = Constants.manifest.releaseChannel ? Constants.manifest.android.package : 'host.exp.exponent';

    const _askPermsMedia = async () => {
        MediaLibrary.requestPermissionsAsync(false).then((res) => {
            const { status } = res;
            if (status == 'granted') {
                setHasCameraRollPermission(true);
            } else {
                if (props.onPermissionError) {
                    props.onPermissionError(hasCameraPermission, hasCameraRollPermission);
                }
            }
        });
    };

    const camRollPerm = () => {
        if (!hasCameraRollPermission) {
            MediaLibrary.requestPermissionsAsync(false).then((res) => {
                if (status == 'granted') {
                    setHasCameraRollPermission(true);
                } else {
                    Alert.alert(
                        'Media Library',
                        'Access to your photo library enables you to upload pictures and earn awesome rewards for doing so.',
                        [
                            {
                                text: 'Cancel',
                                onPress: () => {},
                                style: 'cancel',
                            },
                            {
                                text: 'OK',
                                onPress: () => _askPermsMedia(),
                            },
                        ]
                    );
                }
            });
        }
    };

    const _askPermsCamera = async () => {
        Permissions.askAsync(Permissions.CAMERA).then(({ status }) => {
            if (status == 'granted') {
                setHasCameraPermission(true);
                camRollPerm();
            } else {
                if (props.onPermissionError) {
                    props.onPermissionError(hasCameraPermission, hasCameraRollPermission);
                }
            }
        });
    };

    const pickCameraImage = async () => {
        let pick = true;
        if (Platform.OS !== 'web') {
            const { status } = await ImagePicker.requestCameraPermissionsAsync();
            if (status !== 'granted') {
                pick = false;
                Alert.alert(
                    'Permissions Error',
                    'Access to your camera and microphone enables you to upload pictures and earn awesome rewards for doing so.',
                    [
                        { text: 'Cancel', style: 'cancel' },
                        {
                            text: 'Settings',
                            onPress: () => {
                                // ios
                                if (Platform.OS === 'ios') {
                                    return Linking.openURL('app-settings:');
                                } else {
                                    // android
                                    return IntentLauncher.startActivityAsync(
                                        IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
                                        { data: 'package:' + pkg }
                                    );
                                }
                            },
                        },
                    ]
                );
            }
        }
        if (pick) {
            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
                base64: true,
            });
            if (!result.cancelled) {
                if (result.uri) {
                    const photo = await MediaLibrary.createAssetAsync(result.uri);
                    setPhotos([...photos, photo]);
                }
            }
        }
    };

    useEffect(() => {
        if (!didAskedPermission) {
            setDidAskedPermission(true);
            if (!hasCameraRollPermission) {
                MediaLibrary.requestPermissionsAsync(false).then((res) => {
                    const { status } = res;
                    if (status == 'granted') {
                        setHasCameraRollPermission(true);
                        camRollPerm();
                    } else {
                        Alert.alert(
                            'Media Library',
                            'Access to your photo library enables you to upload pictures and earn awesome rewards for doing so.',
                            [
                                {
                                    text: 'Cancel',
                                    onPress: () => {},
                                    style: 'cancel',
                                },
                                {
                                    text: 'OK',
                                    onPress: () => _askPermsMedia(),
                                },
                            ]
                        );
                    }
                });
            } else {
                camRollPerm();
            }
        }
    }, []);

    useEffect(() => {
        if (hasCameraRollPermission) {
            MediaLibrary.addListener((props) => {
                getPhotos();
            });
        }
    }, [hasCameraRollPermission]);

    useEffect(() => {
        MediaLibrary.getPermissionsAsync().then(({ accessPrivileges }) => {
            if (accessPrivileges) setLibraryPermissions(accessPrivileges);
        });
    }, []);

    const numColumns = useMemo<number>(() => {
        return orientation.portrait ? 3 : 6;
    }, [orientation]);

    const preloaderComponent =
        props.preloaderComponent !== undefined && props.preloaderComponent !== null ? (
            props.preloaderComponent
        ) : (
            <ActivityIndicator size="large" />
        );
    const mediaType: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue =
        props.mediaType !== undefined && props.mediaType !== null
            ? props.mediaType
            : [MediaLibrary.MediaType.photo];
    const loadCompleteMetadata: boolean =
        props.loadCompleteMetadata !== undefined && props.loadCompleteMetadata !== null
            ? props.loadCompleteMetadata
            : false;
    const queryBase64: boolean = props.base64 !== undefined && props.base64 !== null ? props.base64 : false;

    const loadCount: number = props.loadCount != undefined ? props.loadCount : 50;
    const selectImage = (index: number) => {
        let newSelected = [...selected];
        if (newSelected.indexOf(index) === -1) {
            newSelected.push(index);
        } else {
            const deleteIndex = newSelected.indexOf(index);
            newSelected.splice(deleteIndex, 1);
        }
        if (props.max && newSelected.length > props.max) return;
        if (!newSelected) newSelected = [];
        setSelected(newSelected);
    };

    const getPhotos = () => {
        const params: MediaLibrary.AssetsOptions = {
            first: loadCount,
            mediaType,
            sortBy: [MediaLibrary.SortBy.creationTime],
        };
        if (after != null) params.after = after;
        if (!hasNextPage) return;
        MediaLibrary.getAssetsAsync(params).then(processPhotos);
    };

    useEffect(() => {
        if (didAskedPermission && (hasCameraPermission || hasCameraRollPermission)) {
            if (!firstPhotoQuery) {
                setFirstPhotoQuery(true);
                if (props.onPermissionSuccess) {
                    props.onPermissionSuccess();
                }
                getPhotos();
            }
        }
    }, [firstPhotoQuery, hasCameraPermission, hasCameraRollPermission, didAskedPermission]);

    const permsAction = () => {
        showActionSheetWithOptions(
            { options: ['Select More Photos', 'Allow Access To All Photos', 'Cancel'], cancelButtonIndex: 2 },
            (buttonIdx) => {
                switch (buttonIdx) {
                    case 0: // display limited thing
                        MediaLibrary.presentPermissionsPickerAsync();
                        break;
                    case 1: // go to settings
                        // ios
                        if (Platform.OS === 'ios') {
                            Linking.openURL('app-settings:');
                        } else {
                            // android
                            IntentLauncher.startActivityAsync(
                                IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
                                { data: 'package:' + pkg }
                            );
                        }
                        break;
                    default:
                }
            }
        );
    };

    const renderImageTile = ({ item, index }: { item: MediaLibrary.Asset; index: number }) => {
        const is_selected = selected.indexOf(index) !== -1;
        const selectedItemNumber = selected.indexOf(index) + 1;
        return (
            <ImageTile
                selectedItemNumber={selectedItemNumber}
                item={item}
                index={index}
                selected={is_selected}
                selectImage={selectImage}
                renderSelectedComponent={props.renderSelectedComponent}
                renderExtraComponent={props.renderExtraComponent}
            />
        );
    };
    const processPhotos = (data: MediaLibrary.PagedInfo<MediaLibrary.Asset>) => {
        if (data.totalCount) {
            if (after === data.endCursor) return;
            setPhotos([...photos, ...data.assets]);
            setAfter(data.endCursor);
            setHasNextPage(data.hasNextPage);
        } else {
            setIsEmpty(false);
        }
    };

    const onAccept = () => {
        if (props.onAccept) {
            const acceptCallback = props.onAccept;
            const selectedPhotos = selected.map((i) => photos[i]);
            if (!loadCompleteMetadata && !queryBase64) {
                acceptCallback(selectedPhotos);
            } else {
                Promise.all(selectedPhotos.map((i) => MediaLibrary.getAssetInfoAsync(i)))
                    .then((assets) => {
                        if (queryBase64) {
                            Promise.all(
                                assets.map((i) =>
                                    FileSystem.readAsStringAsync(
                                        (i as Required<MediaLibrary.AssetInfo>).localUri,
                                        { encoding: 'base64' }
                                    ).then((r) => ({ base64: r, ...i }))
                                )
                            )
                                .then((assets) => acceptCallback(assets))
                                .catch((e) => acceptCallback([], e));
                        } else {
                            acceptCallback(assets);
                        }
                    })
                    .catch((e) => {
                        acceptCallback([], e);
                    });
            }
        }
    };

    const getItemLayout = (_: any, index: number) => {
        const length = width / 3;
        return { length, offset: length * index, index };
    };

    return (
        <View style={styles.container}>
            <FlatList
                data={photos}
                numColumns={numColumns}
                key={numColumns}
                renderItem={renderImageTile}
                keyExtractor={(item: MediaLibrary.Asset, _: number) => item.id}
                onEndReached={() => getPhotos()}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={isEmpty ? props.emptyStayComponent : preloaderComponent}
                initialNumToRender={15}
                windowSize={4}
                ListHeaderComponent={
                    <View style={styles.pickerHeader}>
                        {libraryPermissions === 'limited' && (
                            <AwesomeButtonThemed
                                type="secondary"
                                height={30}
                                width={80}
                                progress={false}
                                onPress={permsAction}
                            >
                                Manage
                            </AwesomeButtonThemed>
                        )}

                        <View style={styles.deselect}>
                            {selected.length > 1 ? (
                                <AwesomeButtonThemed
                                    type="secondary"
                                    height={30}
                                    progress={false}
                                    onPress={() => setSelected([])}
                                >{`Deselect ${selected.length}`}</AwesomeButtonThemed>
                            ) : null}
                        </View>
                        <AwesomeButtonThemed
                            type="primary"
                            height={30}
                            width={80}
                            progress={false}
                            onPress={pickCameraImage}
                        >
                            Camera
                        </AwesomeButtonThemed>

                        <AwesomeButtonThemed
                            type="primary"
                            height={30}
                            width={80}
                            progress={false}
                            onPress={onAccept}
                        >
                            Accept
                        </AwesomeButtonThemed>
                    </View>
                }
                getItemLayout={getItemLayout}
                stickyHeaderIndices={[0]}
                //columnWrapperStyle={{ justifyContent: 'space-between' }}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
    },
    deselect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    pickerHeader: {
        height: 48,
        backgroundColor: '#beccdf',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        marginBottom: 10,
        justifyContent: 'space-between',
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
