import React, { useEffect, useMemo, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Dimensions,
    ActivityIndicator,
    Text,
} from 'react-native'
import { useDeviceOrientation } from '@react-native-community/hooks'
//@ts-ignore
import { useMutation, useQuery } from "@apollo/client";
import Error from "../Error";
import Colors from "../../constants/Colors";
import { get } from 'lodash'
import { IAsset } from "../../types";
import { GalleryTile } from "./GalleryTile";
import * as Queries from "../../lib/queries";
import * as Mutations from "../../lib/mutations";
import { AssetMutation, AssetQuery } from "./backend";
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';

const { width } = Dimensions.get('window');

interface GalleryEditorProps<Q, M> {
    query: AssetQuery<Q>,
    mutation: AssetMutation<M>,
    onAdd: () => void,
    onReplace: (itemIds: { id: string }[]) => void,

}

export function GalleryEditor<Q, M = any>(props: GalleryEditorProps<Q, M>) {
    // @ts-ignore
    const { data, error: queryError, loading: queryLoading } = useQuery<any, Q>(Queries[props.query.name], { variables: props.query.variables });
    // @ts-ignore
    const [updateAssets, { error: mutationError, loading: mutationLoading }] = useMutation<any, M>(Mutations[props.mutation.name]);

    const orientation = useDeviceOrientation();
    const [selected, setSelected] = useState<Array<number>>([]);

    const numColumns = useMemo<number>(() => {
        return orientation.portrait ? 3 : 6;
    }, [orientation]);

    const selectImage = (_: IAsset, index: number) => {
        let newSelected = [...selected];
        if (newSelected.indexOf(index) === -1) {
            newSelected.push(index);
        } else {
            const deleteIndex = newSelected.indexOf(index);
            newSelected.splice(deleteIndex, 1);
        }
        if (!newSelected) newSelected = [];
        setSelected(newSelected);
    };

    const renderImageTile = ({ item, index }: { item: IAsset, index: number }) => {
        return (
            <GalleryTile
                item={item}
                selected={selected.indexOf(index) > -1}
                onPress={selectImage}
                index={index}
                showCaption={false}
            />
        );
    };


    const getItemLayout = (_: any, index: number) => {
        const length = width / 3;
        return { length, offset: length * index, index };
    };
    if (queryLoading || mutationLoading) {
        return <View style={styles.loading}>
            <ActivityIndicator size={'large'}/>
        </View>
    }
    //query asset
    if (queryError || mutationError || !data || !(get(data, props.query.subPath))) {
        return <Error/>
    }
    const assets: IAsset[] = get(data, props.query.subPath);

    return (
        <View style={styles.container}>
            <FlatList
                data={assets}
                numColumns={numColumns}
                key={numColumns}
                renderItem={renderImageTile}
                keyExtractor={(item: IAsset, _: number) => item.id}
                onEndReachedThreshold={0.5}
                initialNumToRender={4}
                windowSize={4}
                ListHeaderComponent={<View style={styles.pickerHeader}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <AwesomeButtonThemed
                            type="anchor"
                            height={30}
                            width={80}
                            progress={false}
                            disabled={selected.length==0}
                            style={{ marginRight: 8 }}
                            onPress={() => {
                                let variables: any = props.mutation.variables != null && props.mutation.variables != undefined ? props.mutation.variables : {};
                                const toDelete = selected.map((i: number) => ({ id: assets[i].id }));
                                setSelected([]);
                                variables[props.mutation.field] = { delete: toDelete };
                                updateAssets({ variables }).catch(e => console.error(e));
                            }}>Delete</AwesomeButtonThemed>
                        <AwesomeButtonThemed
                            type="secondary"
                            height={30}
                            width={80}
                            disabled={selected.length==0}
                            onPress={() => {
                                const toDelete = selected.map((i: number) => ({ id: assets[i].id }));
                                setSelected([]);
                                props.onReplace(toDelete)
                            }}>Replace</AwesomeButtonThemed>
                    </View>
                    <View>
                        <AwesomeButtonThemed
                            type="primary"
                            height={30}
                            width={80}
                            progress={false}
                            onPress={() => {
                                setSelected([]);
                                props.onAdd()

                            }}>Add</AwesomeButtonThemed>
                    </View>
                </View>}
                stickyHeaderIndices={[0]}

                getItemLayout={getItemLayout}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    root: {
        height: '100%',
        width: '100%',
    },
    asset: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
    },
    loading: {
        backgroundColor: Colors.dark.background,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pickerHeader: {
        height: 48,
        backgroundColor: "#7A306C",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        display: "flex",
        flexDirection: 'row',
        padding: 8,
        justifyContent: 'space-between',
    },
});
