import React from 'react';
import { ActivityComment, Post } from '../../store/types';
import { StyleProp, ViewStyle } from 'react-native';
import { BusinessPost } from './components/BusinessPost';
import { CampaignPost } from './components/CampaignPost';
import { RedemptionPost } from './components/RedemptionPost';
import { VerificationPost } from './components/VerificationPost';


export const PostCard = (props: { post: Post, containerStyle?: StyleProp<ViewStyle>, OnOpenComments?: (cs: ActivityComment[]) => void }) => {
    const { post, containerStyle, OnOpenComments } = props;
    switch (post.type) {
        case 'BusinessPost':
            return <BusinessPost post={post} containerStyle={containerStyle}
                                 OnOpenComments={OnOpenComments ? OnOpenComments : () => null}/>;
        case 'Campaign':
            return <CampaignPost post={post} containerStyle={containerStyle}
                                 OnOpenComments={OnOpenComments ? OnOpenComments : () => null}/>;
        case 'Redemption':
            return <RedemptionPost post={post} containerStyle={containerStyle}
                                   OnOpenComments={OnOpenComments ? OnOpenComments : () => null}/>;
        case 'Verification':
            return <VerificationPost post={post}
                                     OnOpenComments={OnOpenComments ? OnOpenComments : () => null}/>;
        default:
            return <></>;
    }
};

