import * as React from 'react';
import { AssetUploader } from '../../components/Media/AssetUploader';
import * as MediaLibrary from 'expo-media-library';
import * as IntentLauncher from 'expo-intent-launcher';
import Constants from 'expo-constants';
import { useMutation } from '@apollo/client';
import { ActivityIndicator, StyleSheet, View, Alert, Linking } from 'react-native';
import Error from '../../components/Error';
import Colors from '../../constants/Colors';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import * as Mutations from '../../lib/mutations';
import { LinearProgress } from 'react-native-elements';
import { AssetMultipleMutation, AssetMutation } from '../../components/Media/backend';

export interface UploadAssetScreenParams {
    mediaType?: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue;
    mutation: AssetMutation<any>;
    backScreenName: string;
}

interface UploadAssetScreenProps {
    route: { params: UploadAssetScreenParams };
    navigation: StackNavigationProp<any, any>;
}

export interface UploadResponseError {
    kind: 'UploadResponseError';
    error: string;
    hasCameraPermission: boolean;
    hasCameraRollPermission: boolean;
}

export interface UploadResponseSuccess {
    kind: 'UploadResponseSuccess';
    hasCameraPermission: boolean;
    hasCameraRollPermission: boolean;
}

export type UploadResponse = UploadResponseError | UploadResponseSuccess;

export function UploadAssetScreen(props: UploadAssetScreenProps) {
    const uploadMutation = props.route.params.mutation;
    const navigation = props.navigation;
    const pkg = Constants.manifest.releaseChannel ? Constants.manifest.android.package : 'host.exp.exponent';
    let hasCameraPermission = false;
    let hasCameraRollPermission = false;
    //@ts-ignore
    let [mutation, { error, loading }] = useMutation(Mutations[uploadMutation.name]);
    const onPermissionError = () => {
        hasCameraPermission = false;
        hasCameraRollPermission = false;
        Alert.alert(
            'Permissions Error',
            'Access to your photo library enables you to upload pictures and earn awesome rewards for doing so.',
            [
                { text: 'Cancel', style: 'cancel' },
                {
                    text: 'Settings',
                    onPress: () => {
                        // ios
                        if (Platform.OS === 'ios') {
                            Linking.openURL('app-settings:');
                        } else {
                            // android
                            IntentLauncher.startActivityAsync(
                                IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
                                { data: 'package:' + pkg }
                            );
                        }
                    },
                },
            ]
        );
        navigation.navigate(props.route.params.backScreenName, {
            params: {
                uploadResponse: {
                    kind: 'UploadResponseError',
                    error: 'failed to obtain camera roll permissions',
                    hasCameraPermission: hasCameraPermission,
                    hasCameraRollPermission: hasCameraRollPermission,
                },
            },
            merge: true,
        });
    };
    const onPermissionSuccess = () => {
        hasCameraPermission = true;
        hasCameraRollPermission = true;
    };

    if (loading) {
        return (
            <View
                style={{
                    backgroundColor: Colors.dark.background,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                }}
            >
                <LinearProgress color="white" variant={'determinate'} value={1} />
                <View style={styles.loading}>
                    <ActivityIndicator size={'large'} />
                </View>
            </View>
        );
    }
    //query asset
    if (error) {
        return <Error />;
    }

    if (uploadMutation.kind == 'AssetSingleMutation') {
        return (
            <AssetUploader
                max={1}
                onPermissionSuccess={onPermissionSuccess}
                onPermissionError={onPermissionError}
                mediaType={props.route.params.mediaType}
                bucket={props.route.params.mutation.bucket}
                onUploaded={(assets, error) => {
                    if (error) {
                        console.error(error);
                        navigation.navigate(props.route.params.backScreenName, {
                            params: {
                                uploadResponse: {
                                    kind: 'UploadResponseError',
                                    error: JSON.stringify(error),
                                    hasCameraPermission: hasCameraPermission,
                                    hasCameraRollPermission: hasCameraRollPermission,
                                },
                            },
                            merge: true,
                        });
                    } else {
                        let variables =
                            uploadMutation.variables !== null && uploadMutation.variables !== undefined
                                ? uploadMutation.variables
                                : {};
                        variables[uploadMutation.field] = assets[0];
                        mutation({ variables })
                            .then(() => {
                                navigation.navigate(props.route.params.backScreenName, {
                                    params: {
                                        uploadResponse: {
                                            kind: 'UploadResponseSuccess',
                                            hasCameraPermission: hasCameraPermission,
                                            hasCameraRollPermission: hasCameraRollPermission,
                                        },
                                    },
                                    merge: true,
                                });
                            })
                            .catch((e) => {
                                console.error(e);
                                navigation.navigate(props.route.params.backScreenName, {
                                    params: {
                                        uploadResponse: {
                                            kind: 'UploadResponseError',
                                            error: JSON.stringify(e),
                                            hasCameraPermission: hasCameraPermission,
                                            hasCameraRollPermission: hasCameraRollPermission,
                                        },
                                    },
                                    merge: true,
                                });
                            });
                    }
                }}
            />
        );
    } else {
        return (
            <AssetUploader
                onPermissionSuccess={onPermissionSuccess}
                onPermissionError={onPermissionError}
                max={(uploadMutation as AssetMultipleMutation<any>).max}
                mediaType={props.route.params.mediaType}
                bucket={props.route.params.mutation.bucket}
                onUploaded={(assets, error) => {
                    if (error) {
                        console.error(error);
                        navigation.navigate(props.route.params.backScreenName, {
                            params: {
                                uploadResponse: {
                                    kind: 'UploadResponseError',
                                    error: JSON.stringify(error),
                                    hasCameraPermission: hasCameraPermission,
                                    hasCameraRollPermission: hasCameraRollPermission,
                                },
                            },
                            merge: true,
                        });
                    } else {
                        let variables =
                            uploadMutation.variables !== null && uploadMutation.variables !== undefined
                                ? uploadMutation.variables
                                : {};
                        variables[uploadMutation.field] =
                            variables[uploadMutation.field] !== null &&
                            variables[uploadMutation.field] !== undefined
                                ? variables[uploadMutation.field]
                                : {};
                        let connect =
                            variables[uploadMutation.field].connect !== null &&
                            variables[uploadMutation.field].connect !== undefined &&
                            Array.isArray(variables[uploadMutation.field].connect)
                                ? variables[uploadMutation.field].connect
                                : [];
                        for (const asset of assets) {
                            connect.push({ id: asset.id });
                        }
                        variables[uploadMutation.field].connect = connect;
                        mutation({ variables })
                            .then(() => {
                                navigation.navigate(props.route.params.backScreenName, {
                                    params: {
                                        uploadResponse: {
                                            kind: 'UploadResponseSuccess',
                                            hasCameraPermission: hasCameraPermission,
                                            hasCameraRollPermission: hasCameraRollPermission,
                                        },
                                    },
                                    merge: true,
                                });
                            })
                            .catch((e) => {
                                console.error(e);
                                navigation.navigate(props.route.params.backScreenName, {
                                    params: {
                                        uploadResponse: {
                                            kind: 'UploadResponseError',
                                            error: JSON.stringify(e),
                                            hasCameraPermission: hasCameraPermission,
                                            hasCameraRollPermission: hasCameraRollPermission,
                                        },
                                    },
                                    merge: true,
                                });
                            });
                    }
                }}
            />
        );
    }
}

const styles = StyleSheet.create({
    loading: {
        backgroundColor: Colors.dark.background,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
