import { LocationObject } from 'expo-location';
import { IRewardType } from '../types';

export const SET_USER = 'SET_USER';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const SET_SHOP_MESSAGES = 'SET_SHOP_MESSAGES';
export const SET_SHOP = 'SET_SHOP';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
export const SET_CONFIRM_LOGIN_LOADING = 'SET_CONFIRM_LOGIN_LOADING';
export const SET_REGISTER_LOADING = 'SET_REGISTER_LOADING';
export const SET_REGISTRATION_COMPLETE = 'SET_REGISTRATION_COMPLETE';
export const SET_PENDING_CONFIRM = 'SET_PENDING_CONFIRM';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const UNCONFIRMED = 'UNCONFIRMED';
export const CONFIRMED = 'CONFIRMED';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_FIREBASE_CONTEXT = 'SET_FIREBASE_CONTEXT';

export type LoginStatus = 'CONFIRMED' | 'UNCONFIRMED';

export enum ErrorCode{
    SMSCodeDoesNotMatch = "SMSCodeDoesNotMatch",
    SMSCodeMissing = "SMSCodeMissing",
    MissingPhoneNumber = "MissingPhoneNumber",
    Unknown = "Unknown",
    None = "None"
}

export interface Consumer {
    token?: string | null;
    user?: null | undefined | User;
    error: string;
    error_code: ErrorCode;
    firebaseContext?: any; // Figure it out nerds :-p
    loginStatus: LoginStatus;
    loginLoading: boolean;
    registerLoading: boolean;
    registrationComplete: boolean;
    pendingConfirm: boolean;
    confirmLoginLoading: boolean;
    location: LocationObject | null;
}

export interface Shop {
    id: string;
    name: string;
}

export interface RootStore {
    consumer: Consumer;
    shop: Shop;
}

export interface User {
    id: String;
}

type Currency = 'USD' | 'GoviCash';

export interface IReward {
    reward: boolean | IReward | undefined;
    id: string;
    expires?: Date;
    amount?: number;
    restriction?: String;
    currency?: Currency;
    rejected?: boolean;
    rejectedText?: string;
    pendingApproval?: boolean;
    redeemed?: boolean;
    redeemedAt?: Date;
    skuImage?: string;
    skuImageBronze?: string;
    skuImageSilver?: string;
    skuImageGold?: string;
    skuImagePlatinum?: string;
    global: boolean;
    external: boolean;
    meta?: string;
    redemptions: IRedemption[];
    redemptionLocations: (IRedemptionLocation | ITaskLocation)[];
    createdAt: Date;
    updatedAt: Date;
}

export interface IStoreCredit extends IReward {
    id: string;
    shop?: BusinessLocation;
    expires: Date;
    description?: string;
    pendingApproval?: boolean;
    meta?: string;
    restriction?: string;
    rejected?: boolean;
    rejectedText?: string;
    amount: number;
    currency?: Currency;
    redeemed?: boolean;
    redeemedAt?: Date;
    global: boolean;
    external: boolean;
    createdAt: Date;
    prototype?: IReward;
    updatedAt: Date;
    redemptions: IRedemption[];
}

export type CouponType = 'Fixed' | 'Percentage' | 'Custom';

export interface ICoupon extends IReward {
    id: string;
    shop?: BusinessLocation;
    expires: Date;
    description?: string;
    amount?: number;
    restriction: String;
    percentage?: number;
    redemptionLocation: any;
    couponType: CouponType;
    currency?: Currency;
    rejected?: boolean;
    rejectedText?: string;
    pendingApproval?: boolean;
    redeemed?: boolean;
    redeemedAt?: Date;
    global: boolean;
    prototype?: IReward;
    external: boolean;
    meta?: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IUser {
    id: string;
    rank: any;
    username: string;
    firstName: string;
    lastName: string;
}

export interface IBusinessUser {
    firstName: string;
    lastName: string;
    username: string;
}

export interface ICoordinates {
    lat: number;
    long: number;
}

export interface IRedemption {
    id: string;
    shopId: string;
    owner: IUser;
    approvedByManual?: string;
    employee?: IBusinessUser;
    redemptionLocation?: IRedemptionLocation;
    rewardType: IRewardType;
    rewardId: string;
    text?: string;
    createdAt: string;
    updatedAt: string;
    rejected?: boolean;
    approved?: boolean;
    canceled?: boolean;
    rejectionReason?: string;
    approvedAt?: string;
    rejectedAt?: string;
    canceledAt?: string;
}

export interface IVoucher extends IReward {
    id: string;
    shop?: BusinessLocation;
    expires?: Date;
    text: string;
    restriction?: string;
    description?: string;
    amount?: number;
    rejected?: boolean;
    rejectedText?: string;
    pendingApproval?: boolean;
    redemptionLocation?: IRedemptionLocation;
    currency?: Currency;
    meta?: string;
    redeemed: boolean;
    redeemedAt?: Date;
    global: boolean;
    external: boolean;
    prototype?: IReward;
    createdAt: Date;
    updatedAt: Date;
}

export interface IWallet {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    goviCash: number;
    coupons: ICoupon[];
    vouchers: IStoreCredit[];
    storeCredits: IStoreCredit[];
}

export interface IGetRedemptionsResult {
    redemptions: { id: string; rewardType: IRewardType }[];
}

export interface PublicAddress {
    id: string;
    owner: BusinessLocation;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface Survey {
    id: string;
    fields: Field[];
    fieldOrder: string[];
    createdAt: Date;
    updatedAt: Date;
}

export type Language = 'EN_US';
export type FieldType = 'TextArea' | 'Input' | 'Image' | 'MultiChoice' | 'Sentiment';

export interface Field {
    id: string;
    global: boolean;
    maxChars: number;
    label: string;
    language: Language;
    type: FieldType;
    options: string[];
    placeholder: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface BusinessLocation {
    id: string;
    name: string;
    url: string;
    phone: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
    providers: string[];
    hours: string[];
    address: PublicAddress;
    primaryImage: string;
    survey: Survey;
    categories: string[];
    keywords: string[];
    logo: string;
    description: String;
    yelpId: string;
    yelpPhotos: string[];
    createdAt: Date;
    updatedAt: Date;
    lat: number;
    long: number;
}

export type ReportType =
    | 'Harassment'
    | 'Violence'
    | 'Nudity'
    | 'SelfHarm'
    | 'PlatformAbuse'
    | 'Spam'
    | 'HateSpeech'
    | 'Other';

export interface ActivityComment {
    id: string;
    poster: string;
    text: string;
    image: string;
    likes: number;
    media: string[];
    createdAt: Date;
}

export type Tier = 'Gold' | 'Silver' | 'Platinum' | 'Bronze';

export interface Post {
    id: string;
    poster: string;
    businessName: string;
    businessId: string;
    businessImage: string;
    body: string;
    previewImage: string;
    profileImage: string;
    resource: string;
    resourceId: string;
    images: string[];
    tag: BusinessLocation;
    long: number;
    lat: number;
    anonymous: boolean;
    sentiment: 'Negative' | 'Positive' | 'Neutral';
    header: string;
    userRank: number;
    userTier: Tier;
    upvotes: number;
    downvotes: number;
    type: 'BusinessPost' | 'Redemption' | 'Verification' | 'Campaign';
    comments: ActivityComment[];
    createdAt: Date;
    updatedAt: Date;
}

export type BusinessUserType = 'Employee' | 'Owner' | 'Keeper' | 'Anchor' | 'Other';

export interface Role {
    id: string;
    name: string;
    location: BusinessLocation;
    permissions: Permission[];
}

export interface Permission {
    id: string;
    name: string;
    resource: string;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface BusinessUser {
    id: string;
    email: string;
    password: string;
    profilePicture: string;
    username: string;
    firstName: string;
    middleName: string;
    notes: string;
    lastName: string;
    phone: string;
    owner: boolean;
    employee: boolean;
    type: BusinessUserType;
    admin: boolean;
    tfaMethods: string[];
    createdAt: Date;
    updatedAt: Date;
    confirmed: boolean;
    active: boolean;
    shops: BusinessLocation[];
    roles: Role[];
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
    locale: string;
    metaSignup: string;
    pendingSetup: boolean;
}

export interface ITaskLocation {
    id: string;
    __typename: 'TaskLocation';
    shop: BusinessLocation;
    description: string;
    name: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
    lat: number;
    long: number;
    email: string;
    meta: string;
    website: string;
    createdAt: string;
    updatedAt: string;
    distance: number;
}

export interface IRedemptionLocation {
    id: string;
    __typename: 'RedemptionLocation';
    shop: BusinessLocation;
    description: string;
    name: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
    lat: number;
    long: number;
    email: string;
    meta: string;
    website: string;
    createdAt: string;
    updatedAt: string;
    distance: number;
}

export enum TaskType {
    ContentGeneration = 'ContentGeneration',
    Appraisal = 'Appraisal',
    Purchase = 'Purchase',
    Advocacy = 'Advocacy',
    Intelligence = 'Intelligence',
    Custom = 'Custom',
}

export enum VerificationType {
    Automatic = 'Automatic',
    Manual = 'Manual',
    Digital = 'Digital',
    Signup = 'Signup',
    ManualInPerson = 'ManualInPerson',
    ManualDigital = 'ManualDigital',
    Purchase = 'Purchase',
}

export interface ITask {
    id: string;
    type: TaskType;
    description: string;
    instructions?: string;
    geo: boolean;
    link: boolean;
    image: boolean;
    images: boolean;
    video: boolean;
    repeats: boolean;
    repeatLimit?: number;
    survey?: Survey;
    verificationType?: VerificationType;
}

export type IPlace = ITaskLocation | IRedemptionLocation;
