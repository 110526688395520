import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import Onboarding from 'react-native-onboarding-swiper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from '../config/i18n';
import { RootStackParamList } from '../types';

type OnboardingScreenRouteProp = StackScreenProps<RootStackParamList, 'Onboarding'>;

const styles = StyleSheet.create({
    image: {
        width: 168,
        height: 300,
    },
});

const OnboardingScreen = (props: OnboardingScreenRouteProp) => {
    const { navigation } = props;

    const dismissOnboarding = async () => {
        await AsyncStorage.setItem('@newuser','false')
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'BottomTabs' }],
            })
        );
    };
    return (
        <Onboarding
            pages={[
                {
                    backgroundColor: '#000',
                    title: 'Welcome',
                    subtitle: i18n.t('tutorial1'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1645843033/onboarding/IMG_0001_vdxtby.png',
                            }}
                        />
                    ),
                    title: 'Home Screen',
                    subtitle: i18n.t('tutorial2'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1645843033/onboarding/IMG_0003_ks1jfi.png',
                            }}
                        />
                    ),
                    title: 'Earn',
                    subtitle: i18n.t('tutorial5'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1645843032/onboarding/IMG_0006_tdm4o6.png',
                            }}
                        />
                    ),
                    title: 'Complete The Task',
                    subtitle: i18n.t('tutorial8'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1645843032/onboarding/IMG_0005_geqn9p.png',
                            }}
                        />
                    ),
                    title: 'See Reward Tiers',
                    subtitle: i18n.t('tutorial9'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1649533590/onboarding/claimreward_rfl2hn.jpg',
                            }}
                        />
                    ),
                    title: 'Awaiting Your Claim',
                    subtitle: i18n.t('tutorial10'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777986/onboarding/shaded_ewlsyb.jpg',
                            }}
                        />
                    ),
                    title: 'Check Your Wallet',
                    subtitle: i18n.t('tutorial11'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777654/onboarding/photo_2022-03-31_19-47-01_hnzkuw.jpg',
                            }}
                        />
                    ),
                    title: 'Where to Redeem',
                    subtitle: i18n.t('tutorial12'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777654/onboarding/photo_2022-03-31_19-47-01_hnzkuw.jpg',
                            }}
                        />
                    ),
                    title: 'Location Permissions',
                    subtitle: i18n.t('tutorial13'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1645843032/onboarding/IMG_0007_juil3q.png',
                            }}
                        />
                    ),
                    title: 'Shops',
                    subtitle: i18n.t('tutorial14'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777654/onboarding/photo_2022-03-31_19-47-03_xx0qqr.jpg',
                            }}
                        />
                    ),
                    title: 'Wallet',
                    subtitle: i18n.t('tutorial16'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777654/onboarding/photo_2022-03-31_19-46-59_lzyuui.jpg',
                            }}
                        />
                    ),
                    title: 'Wallet',
                    subtitle: i18n.t('tutorial18'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1648777654/onboarding/photo_2022-03-31_19-47-01_hnzkuw.jpg',
                            }}
                        />
                    ),
                    title: 'Location Permissions',
                    subtitle: i18n.t('tutorial19'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1649533590/onboarding/photo_2022-04-09_13-43-26_xqxo90.jpg',
                            }}
                        />
                    ),
                    title: 'Settings',
                    subtitle: i18n.t('tutorial20'),
                },
                {
                    backgroundColor: '#000',
                    image: (
                        <Image
                            style={styles.image}
                            source={{
                                uri: 'https://res.cloudinary.com/govi/image/upload/v1649533590/onboarding/tutorialshaded_u7gveu.jpg',
                            }}
                        />
                    ),
                    title: 'Settings',
                    subtitle: i18n.t('tutorial21'),
                },
            ]}
            onDone={dismissOnboarding}
            onSkip={dismissOnboarding}
            showSkip={true}
            showNext={false}
        />
    );
};

export { OnboardingScreen };
