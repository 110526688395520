import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Card } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    locationText: {
        backgroundColor: '#f6c6c5',
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    root: {},
    shopAddress: {
        color: '#8f8f8f',
    },
    shopName: {
        flex: 1,
        color: 'grey',
        fontWeight: 'bold',
    },
    cardContent: {
        alignItems: 'center',
        display: 'flex',
    },
    hidden: {},
});

export default function DisplayRedemptionLocation({ approvedRedemption }: { approvedRedemption: any }) {
    return (
        <Card>
            <Card.Title>
                <Ionicons name="ios-location-sharp" size={24} color="#df7d87" />
            </Card.Title>
            <View style={styles.cardContent}>
                <Text style={styles.shopName}>{approvedRedemption.redemptionLocation.name}</Text>
                <Text style={styles.shopAddress}>
                    {approvedRedemption.redemptionLocation.street1}{' '}
                    {approvedRedemption.redemptionLocation.street2}{' '}
                </Text>

                <Text style={styles.shopAddress}>
                    {approvedRedemption.redemptionLocation.city}, {approvedRedemption.redemptionLocation.state},{' '}
                    {approvedRedemption.redemptionLocation.postalCode}
                </Text>
            </View>
        </Card>
    );
}
