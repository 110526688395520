import * as types from './types';
import { ErrorCode } from './types';

let hydration: types.RootStore = {
    consumer: {
        user: null,
        error_code: ErrorCode.None,
        firebaseContext: null,
        error: '',
        loginLoading: false,
        loginStatus: types.UNCONFIRMED,
        confirmLoginLoading: false,
        registerLoading: false,
        registrationComplete: false,
        pendingConfirm: false,
        location: null
    },
    shop:{
        id: "ckl7n5mwkr2p40762ngxv1hdl",
        name: "nutrisource"
    }
};

export { hydration };
