import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, FlatList, Dimensions, ActivityIndicator, Text, Linking } from 'react-native';
import { useDeviceOrientation } from '@react-native-community/hooks';
//@ts-ignore
import { useQuery } from '@apollo/client';
import Error from '../Error';
import Colors from '../../constants/Colors';
import { get } from 'lodash';
import { IAsset } from '../../types';
import { GalleryTile } from './GalleryTile';
import * as Queries from '../../lib/queries';
import { AssetQuery } from './backend';
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as MediaLibrary from 'expo-media-library';
import * as IntentLauncher from 'expo-intent-launcher';
import Constants from 'expo-constants';

const { width } = Dimensions.get('window');

interface GalleryProps<V> {
    query: AssetQuery<any>;
    editable: boolean;
    onSelect: (item: IAsset, index: number) => void;
    onEdit: () => void;
}

export function Gallery<V>(props: GalleryProps<V>) {
    // @ts-ignore
    const { data, error, loading } = useQuery<any, V>(Queries[props.query.name], {
        variables: props.query.variables,
    });
    const orientation = useDeviceOrientation();
    const { showActionSheetWithOptions } = useActionSheet();
    const [libraryPermissions, setLibraryPermissions] = useState('');
    const numColumns = useMemo<number>(() => {
        return orientation.portrait ? 3 : 6;
    }, [orientation]);
    const pkg = Constants.manifest.releaseChannel ? Constants.manifest.android.package : 'host.exp.exponent';

    const renderImageTile = ({ item, index }: { item: IAsset; index: number }) => {
        return <GalleryTile item={item} index={index} showCaption={false} onPress={props.onSelect} />;
    };

    const getItemLayout = (_: any, index: number) => {
        const length = width / 3;
        return { length, offset: length * index, index };
    };

    useEffect(() => {
        MediaLibrary.getPermissionsAsync().then(({ accessPrivileges }) => {
            setLibraryPermissions(accessPrivileges || '');
        });
    }, []);

    if (loading) {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size={'large'} />
            </View>
        );
    }
    //query asset
    if (error || !data || !get(data, props.query.subPath)) {
        return <Error />;
    }
    const assets: IAsset[] = get(data, props.query.subPath);

    const permsAction = () => {
        showActionSheetWithOptions(
            { options: ['Select More Photos', 'Allow Access To All Photos', 'Cancel'], cancelButtonIndex: 2 },
            (buttonIdx) => {
                switch (buttonIdx) {
                    case 0: // display limited thing
                        MediaLibrary.presentPermissionsPickerAsync();
                        break;
                    case 1: // go to settings
                        // ios
                        if (Platform.OS === 'ios') {
                            Linking.openURL('app-settings:');
                        } else {
                            // android
                            IntentLauncher.startActivityAsync(
                                IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
                                { data: 'package:' + pkg }
                            );
                        }
                        break;
                    default:
                }
            }
        );
    };

    return (
        <View style={styles.container}>
            <FlatList
                data={assets}
                numColumns={numColumns}
                key={numColumns}
                renderItem={renderImageTile}
                keyExtractor={(item: IAsset, _: number) => item.id}
                onEndReachedThreshold={0.5}
                initialNumToRender={4}
                windowSize={4}
                ListHeaderComponent={
                    props.editable ? (
                        <View style={styles.pickerHeader}>
                            <View>
                                <AwesomeButtonThemed
                                    type="secondary"
                                    height={30}
                                    width={80}
                                    progress={false}
                                    onPress={props.onEdit}
                                >
                                    Edit
                                </AwesomeButtonThemed>
                            </View>
                            {libraryPermissions === 'limited' && (
                                <View>
                                    <AwesomeButtonThemed
                                        type="secondary"
                                        height={30}
                                        width={80}
                                        progress={false}
                                        onPress={permsAction}
                                    >
                                        Manage
                                    </AwesomeButtonThemed>
                                </View>
                            )}
                        </View>
                    ) : null
                }
                stickyHeaderIndices={props.editable ? [0] : []}
                getItemLayout={getItemLayout}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    root: {
        height: '100%',
        width: '100%',
    },
    asset: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
    },
    loading: {
        backgroundColor: Colors.dark.background,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pickerHeader: {
        height: 48,
        backgroundColor: '#7A306C',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        justifyContent: 'space-between',
    },
});
