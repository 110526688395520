import gql from "graphql-tag";

//get current user profile
export const USER_PROFILE = gql`
query UserProfile{
 user(where:{}){
    id
    email
    username
    firstName
    middleName
    lastName
    rank
    phone
    profilePicture
  }
}`;
