import * as React from 'react';
import { Modal, StyleSheet, Text, View, FlatList, TouchableOpacity, SafeAreaView, TextInput } from 'react-native';
import { IRedemptionLocation, ITaskLocation, RootStore } from '../../store/types';
import { useSelector } from 'react-redux';
import { LocationObject } from 'expo-location';
// @ts-ignore
import haversine from 'haversine';
import { Ionicons } from '@expo/vector-icons';
import { CheckBox } from 'react-native-elements';
import { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';
import i18n from '../../config/i18n';

type ISelectedLocation = {
    location_id: string | null;
};
type ILocationListProps = {
    locations: Array<IRedemptionLocation | ITaskLocation>;
    selectedLocation: ISelectedLocation;
    onSelect: (id: string) => void;
};

function distanceFormat(d: number): string {
    if (d / 1609.344 > 1) {
        return `${(d / 1609.344).toFixed(1)} mi`;
    }
    if (d / 0.9144 > 1) {
        return `${(d / 0.9144).toFixed(1)} yd`;
    }

    return `${(d / 0.3048).toFixed(1)} ft`;
}

const LocationList = (props: ILocationListProps) => {
    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);
    const { selectedLocation } = props;
    const renderItem = ({ item }: { item: IRedemptionLocation | ITaskLocation }) => {
        let distance: number | null = null;
        if (loc) {
            distance = getDistance(item.lat, item.long, loc);
        }
        let picked = item.id == selectedLocation.location_id;
        return (
            <View key={item.id} style={[styles.locationContainer, picked ? styles.pickedItem : null]}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => props.onSelect(item.id)}>
                        <View style={{ paddingTop: 5, display: 'flex', alignItems: 'center' }}>
                            <Ionicons name="ios-location-sharp" size={24} color={picked ? 'white' : 'black'} />
                            <CheckBox
                                checkedColor={'white'}
                                disabled={true}
                                containerStyle={{ margin: 0, padding: 0 }}
                                checked={picked}
                            />
                        </View>
                    </TouchableOpacity>
                    <View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    paddingVertical: 5,
                                    color: picked ? 'white' : 'black',
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                        <Text style={{ paddingVertical: 5, color: picked ? '#151854' : 'black' }}>
                            {item.street1} {item.street2}
                        </Text>

                        {distance && distance > 0 && (
                            <Text style={{ paddingVertical: 5, color: picked ? '#151854' : 'black' }}>
                                {distanceFormat(distance)}
                            </Text>
                        )}
                    </View>
                </View>
            </View>
        );
    };
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                style={{ margin: 0, padding: 0 }}
                showsHorizontalScrollIndicator={false}
                data={props.locations}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
            />
        </View>
    );
};

function getDistance(lat: number, long: number, loc: LocationObject): number {
    return haversine(
        { latitude: loc.coords.latitude, longitude: loc.coords.longitude },
        { latitude: lat, longitude: long },
        { unit: 'meter' }
    );
}

export interface IRedemptionModalProps {
    visible: boolean;
    locations: (IRedemptionLocation | ITaskLocation)[];
    onCancel: () => void;
    onRedeem: (id: string) => void;
}

export function RedemptionModal(props: IRedemptionModalProps) {
    const loc = useSelector<RootStore, LocationObject | null>((state) => state.consumer.location);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation>({ location_id: null });
    const [searchRedemptionLocation, setSearchRedemptionLocation] = useState('');

    let sortedLocations = useMemo(() => {
        if(searchRedemptionLocation){
            let search = searchRedemptionLocation.toLocaleLowerCase();
            return props.locations.filter((p) => p.name.toLowerCase().indexOf(search) > -1 || p.street1.toLowerCase().indexOf(search) > -1 || p.postalCode.toLowerCase().indexOf(search) > -1);
        }
        if (loc && loc.coords) {
            return [...props.locations].sort((a, b) => {
                const a_distance = getDistance(a.lat, a.long, loc);
                const b_distance = getDistance(b.lat, b.long, loc);

                if (a_distance == b_distance) {
                    return 0;
                } else if (a_distance > b_distance) {
                    return 1;
                } else {
                    return -1;
                }
            });
        } else {
            return props.locations;
        }
    }, [loc, props.locations, searchRedemptionLocation]);

    useEffect(() => {
        if (loc && loc.coords) {
            if (sortedLocations.length > 0 && selectedLocation.location_id == null) {   
                setSelectedLocation({ location_id: sortedLocations[0].id });   
            }
        }
    }, [sortedLocations, loc]);

    /// sort redeem location by close
    return (
        <Modal
            animated
            animationType="slide"
            visible={props.visible}
            transparent
            onRequestClose={() => props.onCancel()}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 16,
                            paddingHorizontal: 8,
                        }}
                    >
                        <AwesomeButtonThemed
                            type="secondary"
                            height={30}
                            width={80}
                            progress={false}
                            onPress={() => {
                                props.onCancel();
                            }}
                        >
                            Cancel
                        </AwesomeButtonThemed>

                        <AwesomeButtonThemed
                            type="primary"
                            height={30}
                            width={80}
                            disabled={selectedLocation.location_id == null}
                            progress={false}
                            onPress={() => {
                                if (selectedLocation.location_id) props.onRedeem(selectedLocation.location_id);
                            }}
                        >
                            Redeem
                        </AwesomeButtonThemed>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <Text
                            style={{ fontWeight: 'bold', paddingVertical: 5, marginTop: 8, marginBottom: 16 }}
                        >
                            Redemption Locations
                        </Text>
                        <TextInputWithLabel
                            onChangeText={setSearchRedemptionLocation}
                            value={searchRedemptionLocation}
                            label={i18n.t('searchPlaceholder')}
                        />
                    </View>

                    <LocationList
                        locations={sortedLocations}
                        selectedLocation={selectedLocation}
                        onSelect={(id: string) => setSelectedLocation({ location_id: id })}
                    />
                </View>
            </View>
        </Modal>
    );
}

const TextInputWithLabel = (props: { [key: string]: any }) => {
	let label = props.label;
	let style = props.style;

	let newProps = Object.assign({}, props, { label: undefined, style: undefined });
	return (
		<View style={{ width: '100%', padding: 10 }}>
			<Text>{label}</Text>
			<TextInput {...newProps} style={[styles.textInput, style]} />
		</View>
	);
};
const styles = StyleSheet.create({
	textInput: { height: 40, borderColor: 'gray', borderWidth: 1, padding: 8, color: 'black', },
    
    locationContainer: { marginBottom: 8, paddingHorizontal: 8 },
    pickedItem: {
        backgroundColor: '#7A306C',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 128,
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalView: {
        marginTop: 0,
        display: 'flex',
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
});
