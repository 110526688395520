import gql from "graphql-tag";

export const UPDATE_USER_PROFILE = gql`mutation UpdateProfile($email: String, $firstName:String, $middleName:String, $lastName:String, $phone:String, $profilePicture:String){
    updateUser(where:{}, data:{email: $email, firstName:$firstName, middleName:$middleName, lastName:$lastName, phone:$phone, profilePicture:$profilePicture}){
        id
        email
        username
        firstName
        middleName
        lastName
        phone
        profilePicture
  }
}`;
