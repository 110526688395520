import { Image, StyleProp, Text, View, ViewStyle, StyleSheet } from 'react-native';
import React from 'react';
import { Icon } from 'react-native-elements';
// @ts-ignore
import AwesomeButtonThemed from 'react-native-really-awesome-button/src/themes/bojack';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { IOpportunity, IOpportunityClaim } from '../../types';
import { GET_CLAIM_BY_OPPORTUNITY } from '../../lib/backend/oportunity_tab/queries';
// @ts-ignore
import { OPPORTUNITY_CARD_LOGO } from '@env';

let company_background =
    'https://res.cloudinary.com/govi/image/upload/v1613457672/biz/Screen_Shot_2021-02-15_at_11.40.46_PM_uuistl.png';

let company_logo = OPPORTUNITY_CARD_LOGO;

function limitWords(textToLimit: string, wordLimit: number): string {
    if (textToLimit) {
        if (textToLimit.length > wordLimit) {
            return textToLimit.substr(0, wordLimit) + '...';
        } else {
            return textToLimit;
        }
    } else return 'Your Opportunity';
}

export function OpportunityCard(props: {
    cardStyle?: StyleProp<ViewStyle>;
    opportunity: IOpportunity;
    standalone: boolean;
    parentNav?: any;
    disabled: boolean;
}) {
    const { opportunity, standalone, parentNav, cardStyle, disabled } = props;
    let containerStyle = StyleSheet.compose(styles.container, cardStyle);
    if (disabled) {
        containerStyle = StyleSheet.compose(containerStyle, styles.disabled);
    }

    // Remove loading until we move the query to the main opp list
    const { data, error } = useQuery<{ opportunityClaims: IOpportunityClaim[] }, { opportunityId: string }>(
        GET_CLAIM_BY_OPPORTUNITY,
        {
            variables: { opportunityId: opportunity.id },
        }
    );

    if (error) {
        return <Text>Error!</Text>;
    }

    let rewardReady = false;

    if (data && data.opportunityClaims && data.opportunityClaims.length > 0) {
        if (data.opportunityClaims[0].verifications) {
            rewardReady = data.opportunityClaims[0].verifications.every((v: any) => v.approved);
        }
    }

    return (
        <>
            {/** @ts-ignore */}
            <View style={containerStyle}>
                <Image
                    source={{ uri: opportunity.image || company_background }}
                    style={{ width: '100%', height: 116 }}
                />
                {rewardReady && <Icon color="#f8de7e" containerStyle={styles.trophy} name="emoji-events" />}
                <View style={styles.infoPane}>
                    <View>
                        <Image source={{ uri: company_logo }} style={{ width: 104, height: 96, margin: 8 }} />
                    </View>
                    <View style={styles.textPane}>
                        <Text style={styles.rewardDescription}>{limitWords(opportunity.name, 38)}</Text>
                        <Text style={styles.description}>
                            {limitWords(opportunity.oneLineDescription, 114)}
                        </Text>
                        {!opportunity.perpetual ? (
                            <Text style={styles.expirationDate}>
                                Expires {moment(opportunity.endDate).format('lll')}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </View>
                </View>
                <View style={styles.actionPane}>
                    {standalone && (
                        <AwesomeButtonThemed
                            type="primary"
                            height={30}
                            width={80}
                            progress={false}
                            onPress={() =>
                                parentNav.navigate('OpportunityDetail', {
                                    opportunity: opportunity,
                                    completedClaims: data
                                        ? data.opportunityClaims.filter((o) => o.completed)
                                        : [],
                                })
                            }
                        >
                            View
                        </AwesomeButtonThemed>
                    )}
                </View>
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 5,
        height: 270,
        margin: 10,
        overflow: 'hidden',
        shadowColor: 'black',
        shadowOffset: {
            width: 2,
            height: -2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
    },
    expirationDate: {
        fontSize: 12,
        marginTop: 10,
    },
    rewardDescription: {
        color: '#767378',
        fontSize: 16,
        marginTop: 10,
        fontFamily: 'FjallaOne_400Regular',
    },
    description: {
        fontFamily: 'FjallaOne_400Regular',
        fontSize: 14,
        marginTop: 10,
    },
    actionPane: {
        display: 'flex',
        flexDirection: 'row',
        height: 100,
        justifyContent: 'flex-start',
        paddingLeft: 15,
        width: '100%',
    },
    infoPane: {
        display: 'flex',
        flexDirection: 'row',
        height: 110,
        justifyContent: 'flex-start',
        width: '100%',
    },
    textPane: {
        color: '#918e93',
        flexGrow: 1,
        padding: 8,
        flex: 1,
    },
    trophy: {
        position: 'absolute',
        right: 5,
        shadowColor: 'black',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 1,
        shadowRadius: 1,
        top: 5,
    },
    disabled: {
        opacity: 0.5,
    },
});
