import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';

const styles = StyleSheet.create({
    tosBody: {
        flex: 1,
        backgroundColor: '#938FD6',
        justifyContent: 'center',
        alignItems: 'center',
    },
    goBack: {},
    rewardImage: {
        marginTop: 20,
        textAlign: 'center',
    },
    navHeader: {
        textAlign: 'center',
    },
    subheader: {
        fontFamily: 'Lato',
        marginTop: 20,
        color: 'white',
        textAlign: 'center',
    },
    header: {
        fontFamily: 'Lato-Heavy',
        marginTop: 20,
        color: 'white',
        fontSize: 30,
        textAlign: 'center',
    },
    byline: {
        borderRadius: 10,
        marginTop: 20,
        marginBottom: 60,
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: 'white',
        textAlign: 'center',
        height: 80,
        marginRight: 20,
        marginLeft: 20,
    },
    bylineHeader: {
        fontFamily: 'Lato-Heavy',
        fontSize: 30,
        marginTop: 10,
        textAlign: 'center',
    },
    bylineText: {
        marginTop: 10,
        fontFamily: 'Lato',
        fontSize: 12,
        textAlign: 'center',
    },
    backButton: {
        color: '#5e5e5e',
        left: 15,
        top: 20,
    },
    navbar: {
        flex: 1,
        flexDirection: 'row',
    },
});

// @ts-ignore
const Notification = ({ navigation }) => {
    const { notification } = navigation.state.params;
    let subheader = '';
    let rewardImage = null;
    let byline = '';
    switch (notification.type) {
        case 'CouponFixed':
        case 'CouponPercentage':
            subheader = 'You received a new coupon!';
            rewardImage = (
                <Image style={styles.rewardImage} source={require('../../assets/images/coupon.png')} />
            );
            byline = 'Coupon from';
            break;
        case 'Voucher':
            subheader = 'You received a new voucher';
            rewardImage = (
                <Image style={styles.rewardImage} source={require('../../assets/images/voucher.png')} />
            );
            byline = 'Voucher from';
            break;
        case 'Cash':
            rewardImage = (
                <Image style={styles.rewardImage} source={require('../../assets/images/transaction.png')} />
            );
            subheader = 'You received a cash reward';
            byline = 'Cash from';
            break;
        case 'GoviCash':
            subheader = 'You received a GoVi Cash reward';
            rewardImage = (
                <Image style={styles.rewardImage} source={require('../../assets/images/govicash.png')} />
            );
            byline = 'GoVi Cash from';
            break;
        case 'StoreCredit':
            subheader = 'You received new Store Credit';
            rewardImage = (
                <Image style={styles.rewardImage} source={require('../../assets/images/storecredit.png')} />
            );
            byline = 'Store Credit from';
            break;
    }

    const goBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.tosBody}>
            <Text style={styles.header}>Congrats!</Text>
            <Text style={styles.subheader}>{subheader}</Text>
            {rewardImage}
            <View style={styles.byline}>
                <Text style={styles.bylineText}>{byline}</Text>
                <Text style={styles.bylineHeader}>{notification.shopName}</Text>
            </View>
        </View>
    );
};

export default Notification;
