import * as React from "react";
import { StyleSheet, View } from 'react-native';
import { IAsset } from "../../types";
import { GalleryTile } from "./GalleryTile";

export interface MiniGallery {
    assets: IAsset[]
    editable: boolean
    onSelect: (item: IAsset, index: number) => void
}

export function MiniGallery(props: MiniGallery) {
    const maxLen = props.assets.length >= 3;
    if (maxLen) {
        return (
            <View style={styles.root}>
                {props.assets.slice(0, 3).map((item, index) => <GalleryTile showCaption={true} key={item.id} item={item} index={index}
                                                                            onPress={props.onSelect}/>)}
            </View>
        )
    } else {
        return (
            <View style={styles.root}>
                {props.assets.map((item, index) => <GalleryTile showCaption={true}  key={item.id} item={item} index={index}
                                                                onPress={props.onSelect}/>)}
            </View>
        )
    }

}

const styles = StyleSheet.create({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 8,
    },

});
