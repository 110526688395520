import gql from 'graphql-tag';

export const UPDATE_PUSH_TOKEN = gql`
    mutation ($token: String!, $shopId: ID!, $metadata: String, $machineId: String, $machineName: String) {
        addPushToken(
            pushToken: {
                userId: "fake"
                shopId: $shopId
                token: $token
                metadata: $metadata
                machineId: $machineId
                machineName: $machineName
            }
        )
    }
`;

export const APPROVE_REDEMPTION = gql`
    mutation ($redemptionId: ID!, $data: ApproveRedemptionInput) {
        approveRedemption(where: { id: $redemptionId }, data: $data) {
            id
            shopId
            owner {
                id
                username
                firstName
                lastName
                rank
            }
            approvedByManual
            employee {
                firstName
                lastName
                username
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
            }
            rewardType
            rewardId
            text
            createdAt
            updatedAt
            rejected
            approved
            canceled
            rejectionReason
            approvedAt
            rejectedAt
            canceledAt
        }
    }
`;

export const CANCEL_REDEMPTION = gql`
    mutation ($redemptionId: ID!) {
        cancelRedemption(where: { id: $redemptionId }) {
            id
            shopId
            owner {
                id
                username
                firstName
                lastName
                rank
            }
            approvedByManual
            employee {
                firstName
                lastName
                username
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
            }
            rewardType
            rewardId
            text
            createdAt
            updatedAt
            rejected
            approved
            canceled
            rejectionReason
            approvedAt
            rejectedAt
            canceledAt
        }
    }
`;

export const CREATE_REDEMPTION = gql`
    mutation ($rewardId: ID!, $rewardType: RewardType!, $locationId: ID) {
        createRedemption(
            data: {
                shopId: "CURRENT_SHOP"
                rewardId: $rewardId
                rewardType: $rewardType
                redemptionLocation: { connect: { id: $locationId } }
                owner: { connect: { id: "CURRENT_USER" } }
            }
        ) {
            id
            shopId
            owner {
                id
                username
                firstName
                lastName
                rank
            }
            approvedByManual
            employee {
                firstName
                lastName
                username
            }
            redemptionLocation {
                id
                description
                name
                street1
                street2
                city
                state
                postalCode
                country
                phone
                lat
                long
                email
            }
            rewardType
            rewardId
            text
            createdAt
            updatedAt
            rejected
            approved
            canceled
            rejectionReason
            approvedAt
            rejectedAt
            canceledAt
        }
    }
`;

export const CREATE_TASK_MUTATION = gql`
    mutation ($claimId: ID!, $taskId: ID!, $surveyResponses: [String], $text: String) {
        createVerification(claimId: $claimId, taskId: $taskId, surveyResponses: $surveyResponses, text: $text) {
            id
        }
    }
`;

export const CREATE_CLAIM = gql`
    mutation ($opportunityId: ID!) {
        createOpportunityClaim(
            data: { opportunity: { connect: { id: $opportunityId } }, user: { connect: { id: "fakeid" } } }
        ) {
            id
            completed
            verifications {
                id
                surveyResponses {
                    id
                    fieldId
                    response
                }
                task {
                    id
                    minPurchase
                    type
                    repeats
                    repeatLimit
                    description
                    instructions
                    survey {
                        id
                        fieldOrder
                        fields {
                            id
                            global
                            maxChars
                            label
                            type
                            options
                            placeholder
                        }
                    }
                    verificationType
                }
                link
                lat
                long
                started
                submitted
                purchaseAmount
                approved
                reviewed
                rejected
                rejectedReason
                rejectedBy {
                    firstName
                    lastName
                }
                rejectedAt
            }
        }
    }
`;

export const SAVE_OPPORTUNITY = gql`
    mutation ($oppId: ID!) {
        updateProfile(data: { opportunities: { connect: { id: $oppId } } }, where: { id: "fake-id" }) {
            opportunities {
                id
            }
        }
    }
`;

export const UNSAVE_OPPORTUNITY = gql`
    mutation ($oppId: ID!) {
        updateProfile(data: { opportunities: { disconnect: { id: $oppId } } }, where: { id: "fake-id" }) {
            opportunities {
                id
            }
        }
    }
`;

export const UPDATE_VERIFICATION_TASK = gql`
    mutation updateTaskVerification(
        $taskVerificationId: ID!
        $assets: AssetUpdateManyInput
        $surveyResponses: [SurveyResponseCreateInput!]
        $text: String
        $link: String
        $latitude: Float
        $longitude: Float
    ) {
        updateTaskVerification(
            data: {
                assets: $assets
                text: $text
                link: $link
                lat: $latitude
                long: $longitude
                surveyResponses: { create: $surveyResponses }
            }
            where: { id: $taskVerificationId }
        ) {
            id
            assets {
                id
                uri
                mediaType
                mediaSubtypes
                width
                height
                duration
                exif
                caption
                createdAt
                updatedAt
            }
            text
            surveyResponses {
                fieldId
                id
                response
            }
            link
            started
            submitted
            approved
            reviewed
            lat
            long
        }
    }
`;

export const CLAIM_REWARD = gql`
    mutation ($claimId: ID!) {
        updateOpportunityClaim(data: { submitted: true }, where: { id: $claimId }) {
            completed
            submitted
        }
    }
`;

export const GEO_CODE_LOCATION = gql`
    mutation ($searchTerm: String!) {
        geoCode(location: $searchTerm) {
            lat
            long
        }
    }
`;

export const START_TASK_MUTATION = gql`
    mutation (
        $taskId: ID!
        $opportunityId: ID!
        $surveyResponses: [SurveyResponseCreateInput]
        $purchaseAmount: Float
        $text: String
    ) {
        startTask(
            taskId: $taskId
            text: $text
            opportunityId: $opportunityId
            surveyResponses: $surveyResponses
            purchaseAmount: $purchaseAmount
        )
    }
`;
