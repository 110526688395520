import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, ScrollView } from 'react-native';
import { ActivityComment, Post } from '../store/types';
import { Text, View } from '../components/Themed';
import { PostCard } from '../components/PostCard/PostCard';
import { StackScreenProps } from '@react-navigation/stack';
import { ActivityFeedStackParams } from '../types';
import i18n from '../config/i18n';
import {
    CHANNEL_NAME
} from '@env';
const styles = StyleSheet.create({
    root: {
        padding: 12,
        minHeight: '100%',
    },
    newPost: {
        marginTop: 12,
    },
    posts: {
        marginTop: 12,
    },
    post: {
        backgroundColor: '#F1F1F1',
        marginBottom: 12,
    },
    loading: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

type ActivityFeedRouteProp = StackScreenProps<ActivityFeedStackParams, 'ActivityFeed'>;

export default function ActivityFeed({ navigation }: ActivityFeedRouteProp) {
    const FirebaseContext = useSelector((state: any) => {
        return state.consumer.firebaseContext;
    });
    const firebase: any = React.useContext(FirebaseContext);
    // usually risky to not check if they has loaded, let's TODO a check and graceful failure
    const [database, setDatabase] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        if (FirebaseContext && firebase) {
            setDatabase(firebase.database());
        }
    }, [firebase]);

    useEffect(() => {
        if (database) {
            const channel = `channels/${CHANNEL_NAME}`;
            //const channel = `channels/all`;
            // @ts-ignore obviously it can't be null because we check in the line before -_-
            database.ref(channel).on('value', (snapshot: any) => {
                if (snapshot.val()) {
                    const data: any = Object.values(snapshot.val());
                    setPosts(data.reverse());
                }
            });
        }
    }, [database]);

    function goToComments(comments: ActivityComment[]) {
        navigation.navigate('Comments', { comments: comments });
    }

    return (
        <View style={styles.root}>
            <ScrollView style={styles.posts}>
                {posts.map((post: any) => (
                    <PostCard
                        containerStyle={styles.post}
                        key={post.id}
                        post={post}
                        OnOpenComments={(comments) => goToComments(comments)}
                    />
                ))}
                {posts.length === 0 && <Text>{i18n.t('noPosts')}</Text>}
            </ScrollView>
        </View>
    );
}
