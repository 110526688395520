import * as React from 'react';
import { useMemo } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import CardBackground from '../components/CardBackground';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableOpacity } from 'react-native-gesture-handler';

const gradients = {
    storeCredits: ['#4c669f', '#3b5998', '#192f6a'],
    vouchers: ['#5b2f89', '#9f63dc', '#915ec4'],
    coupons: ['#2b78a9', '#46a1db', '#499ac5'],
};

const rewardTextAll = {
    color: 'white',
    fontSize: 35,
    fontFamily: 'FjallaOne_400Regular',
    position: 'absolute',
    top: 20,
    left: 10,
};

const rewardTextVoucher = {
    color: 'white',
    fontSize: 15,
    width: 100,
    fontFamily: 'FjallaOne_400Regular',
    position: 'absolute',
    top: 20,
    left: 10,
};

const rewardTextCoupon = {
    color: 'white',
    fontSize: 20,
    width: 100,
    fontFamily: 'FjallaOne_400Regular',
    position: 'absolute',
    top: 20,
    left: 10,
};

export default function RewardCard(props: any) {
    const rewardType: 'storeCredits' | 'vouchers' | 'coupons' = props.rewardType;
    const { style, nav, reward, data, ...otherProps } = props;

    const is_expired: boolean = useMemo(() => {
        if (data && data.coupon && data.coupon.expires) {
            return new Date(data.coupon.expires) <= new Date();
        }
        return false;
    }, [data]);

    const rewardTextStyle = useMemo(() => {
        switch (rewardType) {
            case 'storeCredits':
                return StyleSheet.flatten([rewardTextAll]);
            case 'vouchers':
                return StyleSheet.flatten([rewardTextVoucher]);
            case 'coupons':
                return StyleSheet.flatten([rewardTextCoupon]);
            default:
                return {};
        }
    }, [rewardType]);

    const caption = useMemo(() => {
        switch (rewardType) {
            case 'storeCredits':
                return 'STORE CREDIT';
            case 'vouchers':
                return 'VOUCHER';
            case 'coupons':
                return 'COUPON';
        }
    }, [rewardType]);

    const rewardText = useMemo(() => {
        switch (rewardType) {
            case 'storeCredits':
                return `$${reward.amount}`;
            case 'vouchers':
                return reward.text;
            case 'coupons':
                if (reward.couponType === 'Percentage' && reward.percentage != null) {
                    return `${reward.percentage}% Off`;
                }
                if (reward.couponType === 'Fixed' && reward.amount != null) {
                    return `$${reward.amount}`;
                }
                if (reward.couponType === 'Custom' && reward.description != null) {
                    return reward.description;
                }
                return 'No Value';
        }
    }, [rewardType]);

    const showRewardDetails = () => {
        nav.push('RewardDetails', { reward, rewardType });
    };
    return (
        <View {...otherProps} style={style}>
            <TouchableOpacity onPress={() => showRewardDetails()}>
                <LinearGradient colors={gradients[rewardType]} style={styles.cardBg}>
                    <Text style={rewardTextStyle}>{rewardText}</Text>
                    {is_expired && <Text style={styles.rewardCaptionExpired}>EXPIRED</Text>}
                    {!reward.redeemed ? (
                        <Text style={styles.rewardCaption}>{caption}</Text>
                    ) : (
                        <Text style={styles.rewardCaptionRedeemed}>{caption}</Text>
                    )}
                    {reward.shop && reward.shop.logo ? (
                        <Image style={styles.storeLogo} source={{ uri: reward.shop.logo }} />
                    ) : null}
                    <CardBackground />
                </LinearGradient>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rewardCaption: {
        color: 'white',
        fontSize: 12,
        fontFamily: 'FjallaOne_400Regular',
        position: 'absolute',
        bottom: 5,
        right: 10,
    },
    rewardCaptionExpired: {
        color: 'red',
        fontSize: 16,
        fontFamily: 'FjallaOne_400Regular',
        position: 'absolute',
        bottom: 5,
        left: 10,
    },
    rewardCaptionRedeemed: {
        color: 'red',
        fontSize: 12,
        fontFamily: 'FjallaOne_400Regular',
        position: 'absolute',
        bottom: 5,
        right: 10,
    },
    storeLogo: {
        position: 'absolute',
        top: 10,
        right: 10,
        width: 25,
        height: 25,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    cardBg: {
        borderRadius: 5,
        width: 155,
        height: 90,
    },
});
