import React from 'react';
import { Image, Text, View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    bizBackground: {
        height: 140,
    },
    headerContainer: {
        flexDirection: 'row',
    },
    title: {
        marginTop: 35,
        textShadowColor: 'black',
        textShadowRadius: 4,
        textShadowOffset: { width: 3, height: 4.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: 'white',
    },
    redeemedTitle: {
        marginTop: 90,
        textShadowColor: 'black',
        textShadowOffset: { width: 1.0, height: 1.0 },
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 30,
        textAlign: 'center',
        color: '#CECECE',
    },
    shopInfo: {
        marginTop: 10,
        marginLeft: 20,
    },
    shopInfoDigital: {
        marginTop: 10,
        marginLeft: 20,
        marginBottom: 20,
    },
    shopName: {
        color: 'white',
        fontFamily: 'Arimo_700Bold',
        fontSize: 16,
        marginBottom: 5,
    },
    shopAddress: {
        color: 'white',
        marginBottom: 5,
        marginTop: 0,
    },
    bizLogo: {
        height: 50,
        marginLeft: 20,
        marginTop: 20,
        width: 50,
    },
    btnPending: {
        backgroundColor: '#fadf92',
    },
    btnSolid: {
        backgroundColor: '#8A84D7',
    },
    btnContainer: {
        padding: 10,
    },
    buttonOutlineStyle: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    disclaimer: {
        color: 'white',
        marginTop: 10,
        padding: 20,
        textAlign: 'left',
    },
    disclaimerHeader: {
        color: 'white',
        marginTop: 10,
        textAlign: 'center',
    },
    goBack: {},
    hidden: {
        display: 'none',
    },
    idHeader: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    idText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
    },
    instructions: {
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        padding: 20,
    },
    loading: {
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    overlay: {
        textAlign: 'center',
        marginTop: -120,
        fontFamily: 'EncodeSans_400Regular',
        fontSize: 45,
        color: '#ffffff',
    },
    locationText: {
        backgroundColor: '#f6c6c5',
        color: 'white',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    manualApprove: {
        color: 'gray',
        fontFamily: 'Arimo_400Regular',
        fontSize: 16,
        textAlign: 'center',
    },
    modal: {
        backgroundColor: 'white',
        height: 300,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 100,
    },
    modalBtn: {
        backgroundColor: '#8A84D7',
    },
    modalBtnCancel: {
        backgroundColor: '#8A84D7',
    },
    modalBtnContainer: {},
    modalHeader: {
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        marginTop: 20,
        textAlign: 'center',
    },
    qrWrapper: {
        alignItems: 'center',
        flex: 1,
    },
    rejectedHeadline: {
        backgroundColor: '#f6c6c5',
        color: 'red',
        fontFamily: 'Arimo_400Regular',
        fontSize: 18,
        paddingTop: 10,
        textAlign: 'center',
    },
    rejectedText: {
        backgroundColor: '#f6c6c5',
        color: 'black',
        fontFamily: 'Arimo_400Regular',
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    root: {
        backgroundColor: '#5a5e5c',
    },
    sku: {
        height: 100,
        width: 200,
    },
    skuContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 100,
        margin: 10,
        justifyContent: 'space-around',
        textAlign: 'center',
    },
    tosBody: {
        flex: 1,
        padding: 20,
    },
    shopNameDigital: {
        fontFamily: 'Arimo_700Bold',
        fontSize: 20,
        color: 'white',
        marginTop: 22,
        marginBottom: 10,
    },
});

const RewardShopHeader = ({ shops, reward }: { shops: any; reward: any }) => {
    const _getAddress = (id: any) => {
        if (shops) {
            const currShop = shops.find(
                //TODO Add type to businessLocations.
                (s: any) => s.id === id
            );
            if (currShop && currShop.address) {
                return currShop.address;
            }
        }
        return {
            street1: '',
            street2: '',
            city: '',
            state: '',
            postalCode: '',
        };
    };

    return reward.shop ? (
        <View style={styles.headerContainer}>
            <Image style={styles.bizLogo} source={{ uri: reward.shop.logo }} />
            {_getAddress(reward.shop.id).street1 ? (
                <View style={styles.shopInfo}>
                    <Text style={styles.shopName}>{reward.shop.name}</Text>
                    <Text style={styles.shopAddress}>{_getAddress(reward.shop.id).street1}</Text>
                    <Text style={[styles.shopAddress, !_getAddress(reward.shop.id).street2 && styles.hidden]}>
                        {_getAddress(reward.shop.id).street2}
                    </Text>
                    <Text style={styles.shopAddress}>{_getAddress(reward.shop.id).city}</Text>
                    <Text style={styles.shopAddress}>
                        {_getAddress(reward.shop.id).state} {_getAddress(reward.shop.id).postalCode}
                    </Text>
                </View>
            ) : (
                <View style={styles.shopInfoDigital}>
                    <Text style={styles.shopNameDigital}>{reward.shop.name}</Text>
                </View>
            )}
        </View>
    ) : (
        <></>
    );
};

export default RewardShopHeader;
