import React from 'react';
import { Text } from '../components/Themed';
import { CouponDetail } from '../components/Rewards/CouponDetail';
import { StoreCreditDetail } from '../components/Rewards/StoreCreditDetail';
import { VoucherDetail } from '../components/Rewards/VoucherDetail';
import i18n from '../config/i18n';

const RewardDetail = ({ route }: any) => {
    if (!route || !route.params || !route.params.reward || !route.params.rewardType) {
        // TODO: Style this up
        return <Text>{i18n.t('unableToLoadReward')}</Text>;
    }
    const reward = route.params.reward;
    const rewardType = route.params.rewardType;
    switch (rewardType) {
        case 'storeCredits':
            return <StoreCreditDetail storeCreditID={reward.id} />;
        case 'coupons':
            return <CouponDetail couponId={reward.id} />;
        case 'vouchers':
            return <VoucherDetail voucherID={reward.id} />;
        default:
            return <Text>{i18n.t('unknownRewardType')}</Text>;
    }
};

RewardDetail.navigationOptions = {
    title: 'Rewards',
    headerTintColor: '#212322',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
};

export { RewardDetail };
