import * as React from 'react';
import { Gallery } from '../../components/Media/Gallery';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import { AssetMutation, AssetQuery } from '../../components/Media/backend';
import { UploadResponse } from './UploadAssetScreen';
import * as MediaLibrary from 'expo-media-library';
import { useEffect } from 'react';

export interface GalleryScreenParams {
    query: AssetQuery<any>;
    mutation: AssetMutation<any>;
    editable?: boolean;
    uploadResponse?: UploadResponse;
    mediaType?: MediaLibrary.MediaTypeValue[] | MediaLibrary.MediaTypeValue;
    backScreenName: string;
}

export interface GalleryScreenProps {
    route: { params: GalleryScreenParams };
    navigation: StackNavigationProp<any, any>;
}

export function GalleryScreen(props: GalleryScreenProps) {
    const { navigation } = props;
    const { params } = props.route;
    useEffect(() => {
        if (params.uploadResponse) {
            if (params.uploadResponse.kind == 'UploadResponseError') {
                navigation.navigate(params.backScreenName, {
                    params: {
                        uploadResponse: params.uploadResponse,
                    },
                    merge: true,
                });
            }
        }
    });
    const editable = params.editable != null && params.editable != undefined ? params.editable : false;
    return (
        <Gallery
            editable={editable}
            {...props.route.params}
            onSelect={(item, index) => {
                navigation.navigate('AssetPager', {
                    initialPage: index,
                    editable: editable,
                    query: params.query,
                });
            }}
            onEdit={() => {
                navigation.navigate('GalleryEditor', {
                    query: params.query,
                    mutation: params.mutation,
                    backScreenName: 'Gallery',
                    mediaType: params.mediaType,
                });
            }}
        />
    );
}
