import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, ImageBackground, View, Text, ActivityIndicator, I18nManager } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { signUp, confirm as confirmFunc, clearError } from '../store/api';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'react-native-elements';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import { ErrorCode, RootStore } from '../store/types';
import i18n from '../config/i18n';

const styles = StyleSheet.create({
    background: {
        width: '100%',
        height: '100%',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10%',
        padding: 20,
    },
    signUpBtn: {
        backgroundColor: '#8a84d7',
        width: '100%',
    },
    resendBtn: {
        backgroundColor: '#A1A1A1',
        width: '100%',
    },
    signUpBtnTitle: {
        fontWeight: 'bold',
        fontFamily: 'Lato',
    },
    loaderContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    },
    loader: {
        flex: 1,
        transform: [{ scale: 4 }],
    },
    helperText: {
        marginTop: 30,
        marginBottom: 0,
        fontSize: 12,
        color: '#5e5e5e',
    },
});

type SignUpScreenRouteProp = StackScreenProps<RootStackParamList, 'SignUp'>;

export const SignUp = ({ navigation }: SignUpScreenRouteProp) => {
    const [phone, setPhone] = useState('');
    const dispatch = useDispatch();
    const registrationComplete = useSelector<RootStore>((state) => state.consumer.registrationComplete);
    const registerLoading = useSelector<RootStore>((state) => state.consumer.registerLoading);
    const [confirm, setConfirm] = useState('');
    const pendingConfirm = useSelector<RootStore>((state) => state.consumer.pendingConfirm);
    const token = useSelector<RootStore>((state) => state.consumer.token);
    const error = useSelector<RootStore, string>((state) => state.consumer.error);
    const error_code = useSelector<RootStore, ErrorCode>((state) => state.consumer.error_code);
    const is_unknown_error = (error_code!==ErrorCode.None && error_code !== ErrorCode.SMSCodeDoesNotMatch && error_code !== ErrorCode.SMSCodeMissing && error_code !== ErrorCode.MissingPhoneNumber);

    useEffect(() => {
        if (token && registrationComplete) {
            navigation.navigate('BottomTabs');
        }
    }, [token]);

    return (
        <ImageBackground style={styles.background} source={require('../assets/images/loginbg.jpg')}>
            {registerLoading && (
                <View style={styles.loaderContainer}>
                    <ActivityIndicator size="large" style={styles.loader} color={'#ffffff'} />
                </View>
            )}
            <View style={styles.root}>
                <Image
                    source={require('../assets/images/logo.png')}
                    style={{ height: '35%', resizeMode: 'contain', width: '50%' }}
                />
                {(is_unknown_error) &&<View style={{display:"flex", flexDirection:"row", paddingVertical: 16, alignItems: "center"}}>
                    <MaterialIcons name="error" size={28} color="#B00020" style={{marginRight: 8}}/>
                    <Text style={{color:"#B00020"}}>{error}</Text>
                </View>}
                <Input
                    onChangeText={(text) => setPhone(text)}
                    keyboardType="phone-pad"
                    returnKeyType={'done'}
                    placeholder="Phone Number"
                    style={{ color: 'white' }}
                    placeholderTextColor="#000000"
                    inputStyle={{ color: 'white', marginLeft: 10 }}
                    labelStyle={{ color: 'white' }}
                    rightIcon={(error_code===ErrorCode.MissingPhoneNumber)?<MaterialIcons name="error" size={28} color="#B00020" />:undefined}
                    errorStyle={{color:"#B00020"}}
                    errorMessage={(error_code===ErrorCode.MissingPhoneNumber)?error:undefined}
                    inputContainerStyle={{ borderBottomColor: (error_code===ErrorCode.MissingPhoneNumber)?"#B00020":undefined, borderBottomWidth: 1}}
                    leftIcon={<MaterialIcons name="phone" size={28} color="#8a84d7" />}
                />

                {pendingConfirm && (
                    <>
                        <Input
                            onChangeText={(text) => setConfirm(text)}
                            containerStyle={{ marginTop: 20 }}
                            keyboardType="number-pad"
                            returnKeyType={'done'}
                            placeholder="Confirmation Code"
                            style={{ color: 'white' }}
                            placeholderTextColor="#000000"
                            inputStyle={{ color: 'white', marginLeft: 10 }}
                            labelStyle={{ color: 'white' }}
                            errorStyle={{color:"#B00020"}}
                            errorMessage={(error_code===ErrorCode.SMSCodeDoesNotMatch || error_code===ErrorCode.SMSCodeMissing)?error:undefined}
                            inputContainerStyle={{ borderBottomColor: (error_code===ErrorCode.SMSCodeDoesNotMatch || error_code===ErrorCode.SMSCodeMissing)?"#B00020":undefined, borderBottomWidth: 1}}
                            rightIcon={(error_code===ErrorCode.SMSCodeDoesNotMatch || error_code===ErrorCode.SMSCodeMissing)?<MaterialIcons name="error" size={28} color="#B00020" />:undefined}
                            leftIcon={<MaterialIcons name="lock-open" size={28} color="#8a84d7" />}
                        />
                        <Button
                            onPress={() => {dispatch(clearError()); dispatch(confirmFunc(phone, confirm))}}
                            containerStyle={{ marginTop: 40 }}
                            buttonStyle={styles.signUpBtn}
                            titleStyle={styles.signUpBtnTitle}
                            title="CONFIRM"
                        />
                    </>
                )}

                {!pendingConfirm && (
                    <Button
                        onPress={() => {dispatch(clearError()); dispatch(signUp(phone))}}
                        containerStyle={{ marginTop: 40 }}
                        buttonStyle={styles.signUpBtn}
                        titleStyle={styles.signUpBtnTitle}
                        title="REGISTER"
                    />
                )}

                {pendingConfirm && (
                    <>
                        <Text style={styles.helperText}>{i18n.t('didntReceiveItTapResend')}</Text>
                        <Button
                            containerStyle={{ marginTop: 20 }}
                            buttonStyle={styles.resendBtn}
                            title="Resend"
                            onPress={() => {dispatch(signUp(phone))}}
                        />
                    </>
                )}

                <Button
                    type="clear"
                    onPress={() => {dispatch(clearError()); navigation.navigate('Login')}}
                    containerStyle={{ marginTop: 80 }}
                    titleStyle={{ color: 'black', marginLeft: 10 }}
                    icon={<MaterialIcons name="cancel" size={25} color="#8a84d7" />}
                    title="Cancel"
                />
            </View>
        </ImageBackground>
    );
};
