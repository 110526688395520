import React, { useEffect } from 'react';
import { SET_USER } from '../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { USER_PROFILE } from '../lib/backend/settings/profile/queries';
import { RootStore } from '../store/types';

export default function ProfileContext() {
    const dispatch = useDispatch();
    const { error, data } = useQuery(USER_PROFILE);
    const token = useSelector<RootStore>((state) => state.consumer.token);

    if (error) {
        console.error(error);
    }

    useEffect(() => {
        if (data && token) {
            dispatch({ type: SET_USER, payload: data.user });
        }
    }, [data]);

    return <></>;
}
