import React from 'react';
import { ScrollView, StyleSheet, Text, View, Image, I18nManager } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { OpportunityStackParams } from '../types';
import { OpportunityCard } from '../components/opportunities/OpportunityCard';
import { IOpportunityClaim, ITaskVerification } from './OpportunityDetail';
import i18n from '../config/i18n';

type OpportunityDetailRouteProp = StackScreenProps<OpportunityStackParams, 'Verification'>;

function getVerification(claim: IOpportunityClaim | null, taskId: string): ITaskVerification | null {
    if (claim && claim.verifications) {
        for (let v of claim.verifications) {
            if (v.task.id == taskId) {
                return v;
            }
        }
    }
    return null;
}

export function TaskVerificationDetail({ route }: OpportunityDetailRouteProp) {
    const { opportunity, task, claim } = route.params;
    const verification: ITaskVerification | null = getVerification(claim, task.id);
    let verificationBlock = <></>;
    let purchaseAmount;
    if (verification) {
        if (isNaN(verification.purchaseAmount)) {
            purchaseAmount = 0;
        } else {
            const pAmount = verification.purchaseAmount;
            if (isNaN(pAmount)) {
                purchaseAmount = 0;
            } else {
                purchaseAmount = pAmount;
            }
        }

        switch (task.verificationType) {
            case 'ManualInPerson':
                if (verification.approved) {
                    verificationBlock = (
                        <Text style={{ ...styles.verificationStatus, ...styles.verificationAccepted }}>
                            {i18n.t('completed')}
                        </Text>
                    );
                } else if (verification.rejected) {
                    verificationBlock = (
                        <Text style={{ ...styles.verificationStatus, ...styles.verificationRejected }}>
                            {i18n.t('rejected')}
                        </Text>
                    );
                } else {
                    verificationBlock = (
                        <View style={{ ...styles.verificationInstructions, ...styles.manualInPerson }}>
                            <Text>{i18n.t('inPersonVerificationRequest')}</Text>
                            <Text style={styles.verificationInstructionsText}>
                                {i18n.t('onSite')}
                            </Text>
                        </View>
                    );
                }
                break;
            case 'ManualDigital':
                if (verification.surveyResponses.length > 0) {
                    const answerBlock = verification.surveyResponses.map((response) => {
                        let fieldDetails: any;
                        try {
                            fieldDetails = verification?.task?.survey?.fields.find(
                                (f) => f.id === response.fieldId
                            );
                        } catch (err) {
                            console.error(err);
                        }
                        if (!fieldDetails) {
                            return (
                                <View style={styles.surveyResponseWrapper}>
                                    <Text>{i18n.t('responseNotFound')}</Text>
                                </View>
                            );
                        }
                        return (
                            <View style={styles.surveyResponseWrapper}>
                                <Text>{fieldDetails.label}</Text>
                                <Text style={styles.surveyResponseText}>{response.response}</Text>
                            </View>
                        );
                    });
                    verificationBlock = (
                        <View>
                            <Text style={styles.surveyHeader}>{i18n.t('surveyResponses')}</Text>
                            {answerBlock}
                        </View>
                    );
                } else {
                    verificationBlock = (
                        <View style={styles.verificationInstructions}>
                            {verification && verification.images && verification.images.length > 0 ? (
                                <Image
                                    style={styles.verificationImg}
                                    source={{ uri: verification && verification.images[0] }}
                                />
                            ) : null}
                            <Text style={styles.verificationUserText}>{verification && verification.text}</Text>
                        </View>
                    );
                }
                break;
        }
    }

    return (
        <ScrollView style={styles.root}>
            <OpportunityCard disabled={false} standalone={false} opportunity={opportunity} />
            <View>
                <Text style={styles.instructionsTitle}>{i18n.t('instructions')}</Text>
                <Text style={styles.instructions}>{task.instructions}</Text>
            </View>
            {verificationBlock}
            {task.type === 'Purchase' && (
                <View style={styles.purchaseVerifyPane}>
                    <View>
                        <Text style={styles.purchaseVerifyDetails}>{i18n.t('amountToVerify')}</Text>
                    </View>
                    <View>
                        <Text style={{ ...styles.purchaseAmount, ...styles.purchaseVerifyDetails }}>
                            {' '}
                            ${purchaseAmount}
                        </Text>
                    </View>
                </View>
            )}
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: 'white',
        minHeight: '100%',
    },
    instructionsTitle: {
        marginTop: 16,
        marginBottom: 16,
        marginLeft: 8,
        marginRight: 8,
        fontWeight: 'bold',
    },
    instructions: {
        marginTop: 16,
        marginBottom: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    purchaseVerifyDetails: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    purchaseVerifyPane: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    surveyResponseWrapper: {
        width: 260,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 20,
    },
    purchaseAmount: {},
    surveyResponseText: {
        fontSize: 16,
        marginTop: 5,
        fontWeight: 'bold',
        color: '#FFC000',
    },
    surveyResponseTitle: {
        fontSize: 14,
        color: '#3F536E',
    },
    verificationUserText: {
        color: '#3F536E',
        fontSize: 14,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
    },
    verificationInstructions: {
        display: 'flex',
        paddingLeft: 10,
        paddingRight: 10,
        flexDirection: 'column',
        width: 300,
    },
    verificationInstructionsText: {
        fontSize: 12,
    },
    verificationStatus: {
        fontWeight: 'bold',
        marginHorizontal: 'auto',
        textAlign: 'center',
        width: '100%',
    },
    verificationAccepted: {
        color: 'green',
    },
    verificationRejected: {
        color: 'red',
    },
    manualInPerson: {
        backgroundColor: '#FBE08E',
        color: '#8B572A',
    },
    verificationImg: {
        width: 300,
    },
    surveyHeader: {
        color: '#7a4ca9',
        textAlign: 'center',
        marginBottom: 0,
    },
});
